const itemExists = (cartItems, newItem) => {
  return cartItems?.some((item) => item.item_id === newItem.item_id);
};

export const cartReducer = (state, action) => {
  switch (action.type) {
    case 'get_cart':
      return {
        ...state,
        cart: action?.payload,
        cartItems: action?.payload?.items,
      };
    case 'add_bundle':
      const items = action.payload;
      const updatedCartItems = state.cartItems?.map((item) => {
        if (itemExists(state.cartItems, item)) {
          // Item exists, update quantity
          const newItem = items?.find(
            (newItem) => newItem.item_id === item.item_id
          );
          return { ...item, quantity: (item.qty = newItem?.qty) };
        }
        return item;
      });
      // Items that don't exist, add them to cartItems
      const newItems = items?.filter(
        (newItem) => !itemExists(state.cartItems, newItem)
      );
      const mergedCartItems = [...updatedCartItems, ...newItems];
      return { ...state, cartItems: mergedCartItems };
    case 'add_item':
      const newItem = action?.payload;
      console.log({ newItemssssssssss: newItem });
      const itemIndex = state?.cartItems?.findIndex(
        (item) => item.item_id === newItem.item_id
      );
      let newCartItems = [];
      if (itemIndex >= 0) {
        newCartItems = state.cartItems.map((item, index) => {
          if (index === itemIndex) {
            return { ...item, qty: newItem?.qty };
          }
          return item;
        });
      } else {
        newCartItems = [...state.cartItems, newItem];
      }
      return { ...state, cartItems: newCartItems };
    case 'update':
      // Your logic to update an item in the cart
      const { item_id, qty } = action.payload;
      return {
        ...state,
        cartItems: state?.cartItems?.map((item) =>
          item.item_id === item_id ? { ...item, qty: qty } : item
        ),
      };
    case 'remove':
      return {
        ...state,
        cartItems: state?.cartItems?.filter(
          (item) => item.item_id !== action.payload.item_id
        ),
      };
    case 'clear':
      return {
        cart: {},
        cartItems: [],
      };
    default:
      return state;
  }
};
