import ArrowIcon from '@/components/icons/ArrowIcon';
import GridEditIcon from '@/components/icons/GridEditIcon';
import Link from 'next/link';

import { useRouter } from 'next/router';

const LocaleSwitcher = ({ fromSideMenu, t, language }) => {
  const { asPath, locale, query, pathname } = useRouter();
  return !fromSideMenu ? (
    <Link
      href={{ pathname, query }}
      as={asPath}
      locale={locale === 'ar' ? 'en' : 'ar'}
    >
      {locale === 'en' ? 'العربية' : 'English'}
    </Link>
  ) : (
    <>
      <Link
        href={{ pathname, query }}
        as={asPath}
        locale={locale === 'ar' ? 'en' : 'ar'}
        className='-mt-0.5 flex items-center justify-between'
      >
        <div className='flex items-center gap-3'>
          <GridEditIcon className='size-4' />
          <div className='flex flex-col gap-0.5'>
            <span className='text-sm'>{t('Change Language')}</span>
            <span className='text-[10px] text-secondary'>
              {locale === 'en' ? 'العربية' : 'English'}
            </span>
          </div>
        </div>
        <ArrowIcon
          className={`${language === 'en' ? '-rotate-90' : 'rotate-90'} text-[#858585]`}
        />
      </Link>
    </>
  );
};

export default LocaleSwitcher;
