const HeartIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.304 4.75C5.65217 4.75 3.75 7.27613 3.75 9.755C3.75 12.3451 5.37381 14.7196 7.33666 16.4969C8.30654 17.3752 9.32777 18.0778 10.2037 18.557C10.6417 18.7967 11.0341 18.9754 11.3577 19.0924C11.6963 19.2147 11.9071 19.25 12 19.25C12.0929 19.25 12.3037 19.2147 12.6423 19.0924C12.9659 18.9754 13.3583 18.7967 13.7963 18.557C14.6722 18.0778 15.6935 17.3752 16.6633 16.4969C18.6262 14.7196 20.25 12.3451 20.25 9.755C20.25 7.27613 18.3478 4.75 15.696 4.75C14.1617 4.75 13.162 5.5059 12.5701 6.19834C12.4276 6.36502 12.2193 6.461 12 6.461C11.7807 6.461 11.5724 6.36502 11.4299 6.19834C10.838 5.5059 9.83832 4.75 8.304 4.75ZM2.25 9.755C2.25 6.68387 4.60583 3.25 8.304 3.25C9.96317 3.25 11.1765 3.91071 12 4.64228C12.8235 3.91071 14.0368 3.25 15.696 3.25C19.3942 3.25 21.75 6.68387 21.75 9.755C21.75 12.9659 19.7628 15.7139 17.6702 17.6088C16.6123 18.5667 15.4946 19.3377 14.5163 19.8729C14.0273 20.1405 13.5638 20.3543 13.1521 20.5031C12.7554 20.6464 12.3516 20.75 12 20.75C11.6484 20.75 11.2446 20.6464 10.8479 20.5031C10.4362 20.3543 9.97273 20.1405 9.48365 19.8729C8.50535 19.3377 7.38771 18.5667 6.32984 17.6088C4.23719 15.7139 2.25 12.9659 2.25 9.755Z'
      fill='currentColor'
    />
  </svg>
);
export default HeartIcon;
