// const FooterTwitter = (props) => (
//   <svg
//     width={35}
//     height={36}
//     viewBox='0 0 35 36'
//     fill='none'
//     xmlns='http://www.w3.org/2000/svg'
//     {...props}
//   >
//     <path
//       opacity={0.25}
//       d='M33.9412 18.106C33.9412 27.0232 26.6886 34.2575 17.7353 34.2575C8.78205 34.2575 1.52942 27.0232 1.52942 18.106C1.52942 9.18882 8.78205 1.95459 17.7353 1.95459C26.6886 1.95459 33.9412 9.18882 33.9412 18.106Z'
//       stroke='currentColor'
//       strokeWidth={2}
//     />
//     <g transform="translate(17.7353, 18.106)">
//       <path
//         fillRule='evenodd'
//         clipRule='evenodd'
//         d='M3.23199 2.73436C3.40426 2.7454 3.55865 2.84452 3.64039 2.99656C4.23329 4.09934 5.26043 5.10436 6.37121 5.56684C6.50581 4.85484 6.82297 4.22252 7.3014 3.73363C7.92377 3.09764 8.79129 2.73333 9.8 2.73333C10.4662 2.73333 11.0284 2.86073 11.5034 3.11422C11.8933 3.3223 12.2036 3.60422 12.4573 3.93333H14C14.1844 3.93333 14.3538 4.03483 14.4408 4.1974C14.5279 4.35998 14.5183 4.55726 14.416 4.71068L13.299 6.38626C13.2704 8.51495 12.6447 10.385 11.4499 11.7391C10.2197 13.1334 8.42714 13.9333 6.2 13.9333C4.90362 13.9333 3.96073 13.592 3.22544 13.0717C2.50542 12.5622 2.01286 11.8986 1.59623 11.3282C1.49421 11.1886 1.47163 11.0061 1.53653 10.8457C1.59444 10.7027 1.71465 10.5954 1.86097 10.553L1.86979 10.5502L1.92658 10.5317C1.97639 10.5153 2.04695 10.4919 2.13166 10.4637C2.30094 10.4074 2.52503 10.3326 2.74877 10.2579C2.80942 10.2376 2.87002 10.2173 2.92948 10.1975C1.25076 8.02899 1.24354 5.10711 2.79346 2.94227C2.89395 2.80191 3.05973 2.72331 3.23199 2.73436ZM3.95884 10.9074L3.68021 11.0007C3.51292 11.0568 3.28964 11.1315 3.06574 11.2063C2.98274 11.234 2.89963 11.2618 2.81924 11.2886C3.10932 11.6523 3.4193 11.9839 3.80306 12.2554C4.35827 12.6483 5.09639 12.9333 6.2 12.9333C8.17287 12.9333 9.68029 12.2333 10.7001 11.0775C11.7267 9.91399 12.3 8.24131 12.3 6.23333C12.3 6.13462 12.3292 6.03812 12.384 5.95598L13.0657 4.93333H12.2C12.0319 4.93333 11.8751 4.84886 11.7825 4.70851C11.5745 4.3929 11.3335 4.15705 11.0326 3.99645C10.7306 3.83527 10.3371 3.73333 9.8 3.73333C9.04139 3.73333 8.43757 4.00236 8.01611 4.43304C7.59198 4.86645 7.32478 5.49254 7.29973 6.24986C7.29476 6.40027 7.2223 6.54044 7.10246 6.63148C6.98263 6.72251 6.82816 6.75473 6.68192 6.71919C5.29988 6.3833 4.04045 5.364 3.20432 4.18525C2.23828 6.07904 2.5225 8.43081 4.15548 10.0817C4.2769 10.2045 4.3264 10.3812 4.2864 10.5492C4.24641 10.7171 4.12256 10.8526 3.95884 10.9074Z'
//         fill='currentColor'
//         transform="translate(-6.86765, -9.053)"
//       />
//     </g>
//   </svg>
// );

// export default FooterTwitter;

const FooterTwitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Layer_1"
    width={35}
    height={36}
    xmlSpace="preserve"
    {...props}
  >
    <path
      opacity={0.25}
      d='M33.9412 18.106C33.9412 27.0232 26.6886 34.2575 17.7353 34.2575C8.78205 34.2575 1.52942 27.0232 1.52942 18.106C1.52942 9.18882 8.78205 1.95459 17.7353 1.95459C26.6886 1.95459 33.9412 9.18882 33.9412 18.106Z'
      stroke='currentColor'
      strokeWidth={2}
    />
    <g transform="translate(12, 12) scale(0.5, 0.5)">
    <path 
      d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z"
      fill='currentColor'  
    />
    </g>
  </svg>
);
export default FooterTwitter;
