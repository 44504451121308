const Index = ({ children, show, isCenter }) => {
  if (show)
    return (
      <section
        className={`fixed left-0 top-0 z-[500] h-screen w-screen bg-black bg-opacity-60 ${
          isCenter && 'flex items-center justify-center'
        }`}
      >
        <div className="z-[501]">{children}</div>
      </section>
    );
  else return null;
};

export default Index;
