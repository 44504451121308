import CallIcon from '@/components/icons/CallIcon';
import FooterEmail from '@/components/icons/FooterEmail';
import LocationIcon from '@/components/icons/LocationIcon';
import FooterFaceBook from '@/components/icons/FooterFaceBook';
import FooterInstagram from '@/components/icons/FooterInstagram';
import Link from 'next/link';
import FooterSnapchat from '@/components/icons/FooterSnapchat';
import FooterTwitter from '@/components/icons/FooterTwitter';
import FooterTiktok from '@/components/icons/FooterTiktok';
import EmailSubscribe from '@/components/forms/subscribe';

const NewsLetter = ({ t }) => {
  return (
    <div className='lg:col-span-3'>
      <EmailSubscribe t={t} />
      <ul className='mt-4 flex flex-col gap-4'>
        <li className='flex items-center gap-2'>
          <LocationIcon />
          <span className='font-[400] text-[#D6D6D6] max-sm:text-sm'>
            {t('King Abdullah Branch Rd, An Nuzhah, Riyadh')}
          </span>
        </li>
        <li className='flex items-center gap-2'>
          <CallIcon className='text-white' />
          <span className='font-[400] text-[#68D237] max-sm:text-sm'>
            <Link href={'tel:920000333'}>{t('920000333')}</Link>
          </span>
        </li>
        <li className='flex items-center gap-2'>
          <FooterEmail className='text-white' />
          <span className='font-[400] text-[#68D237] max-sm:text-sm'>
            <Link href={'mailto:order@alomar.com.sa'}>order@alomar.com.sa</Link>
          </span>
        </li>
        <li className='flex items-center gap-4'>
          <Link href='https://twitter.com/alomar_furnitur' target='_blank'>
            <FooterTwitter />
          </Link>
          <Link
            href='https://ar-ar.facebook.com/Alomar.Furniture.KSA'
            target='_blank'
          >
            <FooterFaceBook />
          </Link>
          <Link
            href='https://www.snapchat.com/add/alomar_furnitur'
            target='_blank'
          >
            <FooterSnapchat />
          </Link>
          <Link
            href='https://www.instagram.com/alomar_furnitur'
            target='_blank'
          >
            <FooterInstagram />
          </Link>
          <Link href='https://vt.tiktok.com/ZSdxvRPye/' target='_blank'>
            <FooterTiktok />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NewsLetter;
