import React, { useRef } from 'react';
import Modal from '@/components/modals';
import CloseIcon from '../icons/CloseIcon';
import useHandleOutSide from '@/hooks/useHandleOutSide';

const TamaraModal = ({
  show,
  setShow,
  locale,
  total,
  numberOfInstallments,
}) => {
  const ref = useRef();
  useHandleOutSide(ref, () => setShow(false));
  console.log(`https://cdn.tamara.co/widget/tamara-introduction.html?lang=${locale}&price=${total}&currency=SAR&countryCode=SA&colorType=default&showBorder=true&paymentType=installment&numberOfInstallments=${numberOfInstallments}&disableInstallment=false&disablePaylater=true&widgetType=product-widget`);
  return (
    <Modal show={show} isCenter>
      <div
        ref={ref}
        className='h-[80vh] fixed left-1/2 top-1/2 z-50 w-[90vw] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-3xl bg-white shadow-lg md:h-[614px] md:w-8/12'
      >
        <CloseIcon
          onClick={() => setShow(false)}
          className='absolute start-4 top-4'
        />
        <iframe
          width='100%'
          height='100%'
          loading="lazy"
          src={`https://cdn.tamara.co/widget/tamara-introduction.html?lang=${locale}&price=${total}&currency=SAR&countryCode=SA&colorType=default&showBorder=true&paymentType=installment&numberOfInstallments=${numberOfInstallments}&disableInstallment=false&disablePaylater=true&widgetType=product-widget`}
        ></iframe>
      </div>
    </Modal>
  );
};

export default TamaraModal;
