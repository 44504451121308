const LinkedInIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 2.5C4.33696 2.5 3.70107 2.76339 3.23223 3.23223C2.76339 3.70107 2.5 4.33696 2.5 5V11.0027C2.5 11.665 2.76311 12.3002 3.23145 12.7685C3.69979 13.2369 4.335 13.5 4.99733 13.5H11.0027C11.6649 13.5 12.3 13.237 12.7684 12.7687C13.2367 12.3006 13.4998 11.6655 13.5 11.0033C13.5 11.0033 13.5 11.0034 13.5 11.0033V4.99733C13.5 4.66938 13.4354 4.34464 13.3099 4.04165C13.1844 3.73866 13.0004 3.46335 12.7685 3.23145C12.5366 2.99955 12.2613 2.8156 11.9584 2.6901C11.6554 2.5646 11.3306 2.5 11.0027 2.5H5ZM5 1.5C4.07174 1.5 3.1815 1.86875 2.52513 2.52513C1.86875 3.1815 1.5 4.07174 1.5 5V11.0027C1.5 11.9302 1.86847 12.8198 2.52435 13.4757C3.18022 14.1315 4.06978 14.5 4.99733 14.5H11.0027C11.9301 14.5 12.8195 14.1316 13.4754 13.4759C14.1312 12.8202 14.4998 11.9309 14.5 11.0034V4.99733C14.5 4.53806 14.4095 4.08328 14.2338 3.65896C14.058 3.23465 13.8004 2.8491 13.4757 2.52435C13.1509 2.19959 12.7654 1.94198 12.341 1.76622C11.9167 1.59046 11.4619 1.5 11.0027 1.5H5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.41333 6.9C5.68947 6.9 5.91333 7.12386 5.91333 7.4V11C5.91333 11.2761 5.68947 11.5 5.41333 11.5C5.13719 11.5 4.91333 11.2761 4.91333 11V7.4C4.91333 7.12386 5.13719 6.9 5.41333 6.9Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.31274 7.9C9.04753 7.9 8.79317 8.00536 8.60564 8.19289C8.4181 8.38043 8.31274 8.63478 8.31274 8.9V11C8.31274 11.2761 8.08889 11.5 7.81274 11.5C7.5366 11.5 7.31274 11.2761 7.31274 11V8.9C7.31274 8.36957 7.52346 7.86086 7.89853 7.48579C8.2736 7.11071 8.78231 6.9 9.31274 6.9C9.84318 6.9 10.3519 7.11071 10.727 7.48579C11.102 7.86086 11.3127 8.36957 11.3127 8.9V11C11.3127 11.2761 11.0889 11.5 10.8127 11.5C10.5366 11.5 10.3127 11.2761 10.3127 11V8.9C10.3127 8.63478 10.2074 8.38043 10.0199 8.19289C9.83231 8.00536 9.57796 7.9 9.31274 7.9Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.40981 4.72534C5.49541 4.72496 5.58025 4.74149 5.65944 4.77399C5.73863 4.80648 5.81063 4.85429 5.87129 4.91469C5.93196 4.97509 5.9801 5.04688 6.01294 5.12592C6.04579 5.20497 6.0627 5.28973 6.0627 5.37534C6.06269 5.50377 6.02464 5.62933 5.95335 5.73616C5.88205 5.84298 5.78071 5.9263 5.6621 5.97558C5.54349 6.02486 5.41295 6.0379 5.28695 6.01305C5.16094 5.9882 5.04512 5.92659 4.9541 5.83597C4.86308 5.74535 4.80094 5.6298 4.77554 5.50391C4.75013 5.37801 4.76259 5.24741 4.81135 5.12859C4.8601 5.00977 4.94297 4.90805 5.04948 4.83628C5.15599 4.76452 5.28137 4.72591 5.40981 4.72534Z'
      fill='currentColor'
    />
  </svg>
);
export default LinkedInIcon;
