import React from 'react';

const useBodyScrollLock = () => {
  const observerRef = React.useRef(null);

  React.useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';

    const observer = new ResizeObserver(() => {
      if (observer) {
        observer.disconnect();
      }
    });

    observerRef.current = observer;
    observer.observe(document.body);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      document.body.style.overflow = originalStyle;
    };
  }, []);
};

export default useBodyScrollLock;
