import Link from "next/link";

const FooterLinks = ({ title, data = [] }) => {
  return (
    <div className='lg:col-span-2'>
      <h3 className='font-bold uppercase'>{title}</h3>
      <ul className='mt-4 flex flex-col gap-4'>
        {data?.map((item, index) => (
          <li key={index} className='opacity-70 max-sm:text-sm'>
            <Link href={item?.url_key}>{item.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterLinks;