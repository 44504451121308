import CartIcon from '@/components/icons/CartIcon';
import Image from '@/components/Image';
import toast from 'react-hot-toast';
import Link from 'next/link';

import { useEffect, useState } from 'react';
import { useCart } from '@/context/cart';
import { getCookies, setCookie } from 'cookies-next';
import { calcCartTotalHandler } from '@/helpers/cart';
import { priceFormattedObject, validatePrice } from '@/helpers/product';
import { deleteCartItem, updateCartItem } from '@/services/cart';
import Remove from '@/components/icons/Remove';
import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import useClient from '@/hooks/global/useClient';

const CartHeader = ({ locale }) => {
  const cart = useCart();
  const [qty, setQty] = useState(null);
  const [dropDown, setDropDown] = useState(false);
  const { handshake, mage, quote } = getCookies();
  const [delLoading, setDelLoading] = useState(false);
  const [updLoading, setUpdLoading] = useState(false);
  const cartTotal = calcCartTotalHandler(cart?.cart?.cartItems || []);
  const handleDeleteCartItem = async (itemData) => {
    setDelLoading(true);
    const res = await deleteCartItem(handshake, mage, quote, itemData?.item_id);

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie('quote', res?.data?.quoteId);
      cart?.dispatch({ type: 'clear' });
    }

    if (res?.status === 200) {
      toast.success(
        locale === 'en' ? 'Item Deleted From Cart!' : 'تم مسح المنتج من العربة'
      );
      cart?.dispatch({
        type: 'remove',
        payload: { item_id: itemData?.item_id || itemData?.data?.item_id },
      });
      setDelLoading(false);
    } else {
      toast.error(
        locale === 'en'
          ? 'Error Occured, please try again'
          : 'حدث خطأ, برجاء المحاولة مره اخرى'
      );
      setDelLoading(false);
    }
  };
  const handleUpdateItem = async (itemData) => {
    setUpdLoading(true);
    const res = await updateCartItem(
      handshake,
      mage,
      quote,
      itemData?.item_id,
      qty
    );

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie('quote', res?.data?.quoteId);
      cart?.dispatch({ type: 'clear' });
    }

    if (res?.status === 200) {
      toast.success(
        locale === 'en'
          ? 'Item Updated Successfully'
          : 'تم تعديل كمية المنتج بنجاح'
      );
      cart?.dispatch({
        type: 'update',
        payload: { item_id: itemData?.item_id || itemData?.data?.item_id, qty },
      });
      setUpdLoading(false);
    } else {
      toast.error(
        locale === 'en'
          ? 'Error Occured, please try again'
          : 'حدث خطأ, برجاء المحاولة مره اخرى'
      );
      setUpdLoading(false);
    }
  };
  useEffect(() => {
    if (cart?.cart?.cartItems?.length === 0) {
      setDropDown(false);
    }
  }, [cart?.cart?.cartItems]);
  
  const { isClient } = useClient()
  
  return isClient && (
    <div className='relative'>
      <button
        className='mt-1 h-fit outline-none'
        onClick={() =>
          cart?.cart?.cartItems?.length > 0
            ? setDropDown(true)
            : setDropDown(false)
        }
      >
        <CartIcon className='size-7' />
        {cart?.cart?.cartItems?.length > 0 && (
          <span className='absolute -right-2 -top-1 z-30 flex size-5 items-center justify-center rounded-full bg-[#58B18E] text-xs text-white'>
            {cart?.cart?.cartItems?.length}
          </span>
        )}
      </button>
      {dropDown && cart?.cart?.cartItems?.length > 0 ? (
        <>
          <div
            onClick={() => setDropDown(false)}
            className='fixed start-0 top-0 z-[600] h-screen w-screen bg-black bg-opacity-60'
          ></div>
          <div className='after:shadow-3xl after:absolute after:start-0 after:top-[50%] after:z-[800] after:size-4 after:border-[10px] after:border-b-white after:border-e-transparent after:border-s-transparent after:border-t-transparent'></div>
          <div className=' absolute end-0 top-full z-[800] w-96 rounded-sm border bg-white p-3 pb-0 shadow-xl'>
            <div className='gray-scroll max-h-[250px] overflow-y-auto'>
              {cart?.cart?.cartItems?.map((item, i) => {
                const price = validatePrice(priceFormattedObject(item));

                return (
                  <div
                    key={i}
                    className='flex gap-2 border-b py-2 first:pt-0 last:border-b-0'
                  >
                    <Image
                      src={
                        item?._media_?.image?.[0]?.image ||
                        `${process.env.NEXT_PUBLIC_IMG_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`
                      }
                      className='h-full w-32 rounded-md border object-cover'
                      alt='Cart Image'
                    />
                    <div className='flex flex-1 flex-col gap-2'>
                      <div className='flex justify-between'>
                        <Link href={handleLinkByType(item?.extension_attributes?.rewrite_url || item?.rewrite_url, 'product')} className='line-clamp-2 flex-1 text-sm'>
                         {item?.name || ''}
                        </Link>
                        <button
                          onClick={() => handleDeleteCartItem(item)}
                          disabled={delLoading}
                          className='h-fit disabled:cursor-not-allowed'
                        >
                          <Remove className='size-4 cursor-pointer' />
                        </button>
                      </div>
                      <p className='text-lg font-bold'>
                        {Number(
                          price?.specialPrice || price?.originalPrice
                        ).toFixed(1)}{' '}
                        {locale === 'en' ? 'SAR' : 'ر.س'}
                      </p>
                      <div className='flex items-center justify-between'>
                        <span className='text-sm'>
                          {locale === 'en' ? 'Qty' : 'الكمية'}
                        </span>
                        <div className='flex items-center gap-2'>
                          <select
                            onChange={(e) => setQty(Number(e.target.value))}
                            className='w-12 cursor-pointer rounded-md border outline-none'
                          >
                            {[...Array(10).keys()].map((i) => (
                              <option
                                key={i}
                                selected={i === Number(item?.qty || 2) - 1}
                                value={i + 1}
                              >
                                {i + 1}
                              </option>
                            ))}
                          </select>
                          <button
                            disabled={updLoading}
                            onClick={() => handleUpdateItem(item)}
                            className='w-24 rounded-md bg-primary p-1.5 text-sm text-white disabled:cursor-not-allowed'
                          >
                            {locale === 'en' ? 'Update' : 'تعديل'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className=' z-50 flex flex-col gap-2 bg-white py-2'>
              <div className='flex items-center justify-center gap-3'>
                <span className='font-semibold'>
                  {locale === 'en' ? 'Sub Total' : 'الإجمالى'} :
                </span>
                <span className='text-lg font-bold text-primary'>
                  {cartTotal ? cartTotal.toFixed(2) : 0}{' '}
                  {locale === 'en' ? 'SAR' : 'ر.س'}
                </span>
              </div>
              <Link
                href='/cart'
                onClick={() => setDropDown(false)}
                className='block w-full rounded-md border border-primary p-2 text-center text-sm font-semibold text-primary'
              >
                {locale === 'en' ? 'View My Cart' : 'الذهاب لعربة التسوق'}
              </Link>
              <Link
                href='/checkout'
                onClick={() => setDropDown(false)}
                className='block w-full rounded-md border border-primary bg-primary p-2 text-center text-sm font-semibold text-white'
              >
                {locale === 'en' ? 'Checkout' : 'الدفع'}
              </Link>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CartHeader;
