import * as React from 'react';
const BurgerList = (props) => (
  <svg
    width={18}
    height={14}
    viewBox='0 0 18 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.24805 1.43677C5.24805 1.02255 5.58383 0.686768 5.99805 0.686768H17.0026C17.4168 0.686768 17.7526 1.02255 17.7526 1.43677C17.7526 1.85098 17.4168 2.18677 17.0026 2.18677H5.99805C5.58383 2.18677 5.24805 1.85098 5.24805 1.43677Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.24805 7C5.24805 6.58579 5.58383 6.25 5.99805 6.25H17.0026C17.4168 6.25 17.7526 6.58579 17.7526 7C17.7526 7.41421 17.4168 7.75 17.0026 7.75H5.99805C5.58383 7.75 5.24805 7.41421 5.24805 7Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.24805 12.5635C5.24805 12.1493 5.58383 11.8135 5.99805 11.8135H17.0026C17.4168 11.8135 17.7526 12.1493 17.7526 12.5635C17.7526 12.9777 17.4168 13.3135 17.0026 13.3135H5.99805C5.58383 13.3135 5.24805 12.9777 5.24805 12.5635Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.246094 1.43673C0.246094 0.746261 0.805831 0.186523 1.4963 0.186523C2.18677 0.186523 2.74651 0.746261 2.74651 1.43673C2.74651 2.1272 2.18677 2.68694 1.4963 2.68694C0.805831 2.68694 0.246094 2.1272 0.246094 1.43673Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.246094 6.99996C0.246094 6.30949 0.805831 5.74976 1.4963 5.74976C2.18677 5.74976 2.74651 6.30949 2.74651 6.99996C2.74651 7.69044 2.18677 8.25017 1.4963 8.25017C0.805831 8.25017 0.246094 7.69044 0.246094 6.99996Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.246094 12.5634C0.246094 11.873 0.805831 11.3132 1.4963 11.3132C2.18677 11.3132 2.74651 11.873 2.74651 12.5634C2.74651 13.2539 2.18677 13.8136 1.4963 13.8136C0.805831 13.8136 0.246094 13.2539 0.246094 12.5634Z'
      fill='currentColor'
    />
  </svg>
);
export default BurgerList;
