import Image from '@/components/Image';

const FooterPayments = ({ isResponsive }) => {
  return (
    <div
      className={`${
        isResponsive ? 'flex md:hidden' : 'hidden md:flex'
      } flex-wrap items-center justify-center gap-2`}
    >
      <Image
        loading='lazy'
        src='/imgs/payments/mada.png'
        className='h-7 w-16 object-contain'
        alt='Footer Payment Img!'
      />
      <Image
        loading='lazy'
        src='/imgs/payments/tasaheel.png'
        className='h-7 w-16 object-contain'
        alt='Footer Payment Img!'
      />
      <Image
        loading='lazy'
        src='/imgs/payments/tabby.png'
        className='h-7 w-16 object-contain'
        alt='Footer Payment Img!'
      />
      <Image
        loading='lazy'
        src='/imgs/payments/tamara.png'
        className='h-7 w-16 object-contain'
        alt='Footer Payment Img!'
      />
      <Image
        loading='lazy'
        src='/imgs/payments/visa.png'
        className='h-7 w-16 object-contain'
        alt='Footer Payment Img!'
      />
      <Image
        loading='lazy'
        src='/imgs/payments/installments.png'
        className='h-7 w-16 object-contain'
        alt='Footer Payment Img!'
      />
      <Image
        loading='lazy'
        src='/imgs/payments/cash-on-delivery.png'
        className='h-7 w-16 object-contain'
        alt='Footer Payment Img!'
      />
    </div>
  );
};

export default FooterPayments;
