const FooterInstagram = (props) => (
  <svg
    width={35}
    height={36}
    viewBox='0 0 35 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      opacity={0.25}
      d='M33.4706 18.106C33.4706 27.0232 26.218 34.2575 17.2647 34.2575C8.31147 34.2575 1.05884 27.0232 1.05884 18.106C1.05884 9.18882 8.31147 1.95459 17.2647 1.95459C26.218 1.95459 33.4706 9.18882 33.4706 18.106Z'
      stroke='white'
      strokeWidth={2}
    />
    <path
      d='M19.8401 16.156C20.1695 16.156 20.4366 15.8898 20.4366 15.5614C20.4366 15.233 20.1695 14.9668 19.8401 14.9668C19.5107 14.9668 19.2437 15.233 19.2437 15.5614C19.2437 15.8898 19.5107 16.156 19.8401 16.156Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.7795 18.1058C14.7795 19.4721 15.8942 20.5833 17.2648 20.5833C18.6355 20.5833 19.7501 19.4721 19.7501 18.1058C19.7501 16.7395 18.6355 15.6284 17.2648 15.6284C15.8942 15.6284 14.7795 16.7395 14.7795 18.1058ZM16.0222 18.1059C16.0222 17.4228 16.5795 16.8672 17.2648 16.8672C17.9501 16.8672 18.5075 17.4228 18.5075 18.1059C18.5075 18.7891 17.9501 19.3446 17.2648 19.3446C16.5795 19.3446 16.0222 18.7891 16.0222 18.1059Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.7795 23.0606H19.7501C21.0275 23.0606 22.2354 21.8566 22.2354 20.5832V15.6283C22.2354 14.3549 21.0275 13.1509 19.7501 13.1509H14.7795C13.502 13.1509 12.2942 14.3549 12.2942 15.6283V20.5832C12.2942 21.8566 13.502 23.0606 14.7795 23.0606ZM13.5368 15.6284C13.5368 15.0493 14.1986 14.3897 14.7795 14.3897H19.7501C20.331 14.3897 20.9927 15.0493 20.9927 15.6284V20.5833C20.9927 21.1624 20.331 21.822 19.7501 21.822H14.7795C14.1874 21.822 13.5368 21.1735 13.5368 20.5833V15.6284Z'
      fill='white'
    />
  </svg>
);
export default FooterInstagram;
