import { useContext } from 'react';
import { AppDataContext } from './provider';

const useGlobalData = () => {
  const context = useContext(AppDataContext);
  if (!context) {
    throw new Error('useGlobalData must be used within a CartProvider');
  }
  return context;
};

export { useGlobalData };
