import { ErrorMessage, Field } from 'formik';

export default function FormField({
  name,
  id,
  type = 'text',
  label,
  labelClassName,
  inputClassName,
  errorClassName,
  icon,
  t,
  errors,
  touched,
  ...props
}) {
  return (
    <>
      <label htmlFor={id} className={labelClassName || 'hidden'}>
        {label}
      </label>
      <div className='relative'>
        <Field
          id={id}
          name={name}
          type={type}
          className={inputClassName}
          {...props}
        />
        {icon}
      </div>
      {errors[name] && touched[name] ? (
                <div className={errorClassName}>{t(errors[name])}</div>
      ) : null}
      {/* <ErrorMessage name={name} component='p' className={errorClassName} /> */}
    </>
  );
}
