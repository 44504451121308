const FaceBookIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 2.5C4.33696 2.5 3.70107 2.76339 3.23223 3.23223C2.76339 3.70107 2.5 4.33696 2.5 5V11.0027C2.5 11.665 2.76311 12.3002 3.23145 12.7685C3.69979 13.2369 4.335 13.5 4.99733 13.5H11.0027C11.6649 13.5 12.3 13.237 12.7684 12.7687C13.2367 12.3006 13.4998 11.6655 13.5 11.0033C13.5 11.0033 13.5 11.0034 13.5 11.0033V4.99733C13.5 4.66938 13.4354 4.34464 13.3099 4.04165C13.1844 3.73866 13.0004 3.46335 12.7685 3.23145C12.5366 2.99955 12.2613 2.8156 11.9584 2.6901C11.6554 2.5646 11.3306 2.5 11.0027 2.5H5ZM5 1.5C4.07174 1.5 3.1815 1.86875 2.52513 2.52513C1.86875 3.1815 1.5 4.07174 1.5 5V11.0027C1.5 11.9302 1.86847 12.8198 2.52435 13.4757C3.18022 14.1315 4.06978 14.5 4.99733 14.5H11.0027C11.9301 14.5 12.8195 14.1316 13.4754 13.4759C14.1312 12.8202 14.4998 11.9309 14.5 11.0034V4.99733C14.5 4.53806 14.4095 4.08328 14.2338 3.65896C14.058 3.23465 13.8004 2.8491 13.4757 2.52435C13.1509 2.19959 12.7654 1.94198 12.341 1.76622C11.9167 1.59046 11.4619 1.5 11.0027 1.5H5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.90002 8.6C6.90002 8.32386 7.12388 8.1 7.40002 8.1H11C11.2762 8.1 11.5 8.32386 11.5 8.6C11.5 8.87614 11.2762 9.1 11 9.1H7.40002C7.12388 9.1 6.90002 8.87614 6.90002 8.6Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.76484 5.76487C9.19055 5.33916 9.76793 5.1 10.37 5.1H11C11.2761 5.1 11.5 5.32386 11.5 5.6C11.5 5.87614 11.2761 6.1 11 6.1H10.37C10.0332 6.1 9.71012 6.2338 9.47195 6.47197C9.23378 6.71015 9.09998 7.03318 9.09998 7.37V14C9.09998 14.2761 8.87612 14.5 8.59998 14.5C8.32383 14.5 8.09998 14.2761 8.09998 14V7.37C8.09998 6.76796 8.33914 6.19058 8.76484 5.76487Z'
      fill='currentColor'
    />
  </svg>
);
export default FaceBookIcon;
