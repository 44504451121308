import AddtoWishlist from '@/components/global/buttons/AddtoWishlist';
import AddToCart from '@/components/global/buttons/AddToCart';
import MinusIcon from '@/components/icons/MinusIcon';
import PlusIcon from '@/components/icons/PlusIcon';

import { useRouter } from 'next/router';
import { useState } from 'react';

const QtyWishlist = ({ product, onCloseQuickView }) => {
  const { locale } = useRouter();
  const [qty, setQty] = useState(1);

  return (
    <div className='mt-6 flex flex-col gap-4'>
      <p className='text-sm font-medium'>
        {locale === 'en' ? 'Qty' : 'الكمية'}:
      </p>
      <div className='flex flex-wrap items-center justify-between gap-4 sm:gap-7'>
        <div className='flex items-center gap-3'>
          <button
            onClick={() => setQty((qty) => qty - 1)}
            disabled={qty === 1}
            className='h-9 grid place-items-center disabled:text-dark-gray disabled:cursor-not-allowed text-black'
          >
            <MinusIcon />
          </button>
          <span className='grid h-9 w-12 place-items-center rounded-md border border-secondary/35 text-sm'>
            {qty}
          </span>
          <button
            onClick={() => setQty((qty) => qty + 1)}
            disabled={qty === product?.stock?.qty}
            className='h-9 grid place-items-center disabled:text-dark-gray  disabled:cursor-not-allowed text-black'
          >
            <PlusIcon />
          </button>
        </div>
        <div className='flex items-center gap-3 max-sm:w-full sm:flex-1'>
          <AddToCart
            fromPDP
            onCloseQuickView={onCloseQuickView}
            product={product}
            qty={qty}
          />
          <AddtoWishlist fromPDP product={product} />
        </div>
      </div>
    </div>
  );
};

export default QtyWishlist;
