const CartIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.05505 4.70375C4.24492 4.46944 4.5304 4.33334 4.83198 4.33334H21.6666C23.1856 4.33334 24.2985 5.75979 23.9301 7.2332L22.1328 14.4225C21.7571 15.9233 20.4812 17.0276 18.9409 17.1817C18.9409 17.1817 18.9409 17.1817 18.9409 17.1817L9.85287 18.091C7.99161 18.2761 6.28754 17.0322 5.90036 15.2019C5.90033 15.2018 5.90038 15.202 5.90036 15.2019L3.85369 5.54058C3.79119 5.24554 3.86518 4.93806 4.05505 4.70375ZM6.06601 6.33334L7.85694 14.7874C8.03254 15.618 8.80606 16.1849 9.65375 16.101L18.7418 15.1916C19.4413 15.1217 20.0214 14.6205 20.1925 13.9375C20.1925 13.9376 20.1925 13.9374 20.1925 13.9375L21.9898 6.74813C22.0428 6.53622 21.8837 6.33334 21.6666 6.33334H6.06601Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.666656 1.33334C0.666656 0.781051 1.11437 0.333336 1.66666 0.333336H3.96532C4.43601 0.333336 4.84298 0.661572 4.94265 1.12158L5.80931 5.12158C5.92626 5.66134 5.5835 6.19371 5.04374 6.31066C4.50398 6.42761 3.97162 6.08485 3.85467 5.54509L3.15879 2.33334H1.66666C1.11437 2.33334 0.666656 1.88562 0.666656 1.33334Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.59599 20.6893C7.77955 20.6893 7.1078 21.3507 7.11064 22.1774C7.10784 23.007 7.78265 23.6667 8.59732 23.6667C9.41894 23.6667 10.0853 23.0003 10.0853 22.1787C10.0853 21.3557 9.41894 20.6893 8.59599 20.6893ZM9.11061 22.1773L9.1106 22.178V22.1766L9.11061 22.1773ZM19.8187 20.6893C19.0022 20.6893 18.3305 21.3507 18.3333 22.1774C18.3305 23.007 19.0053 23.6667 19.82 23.6667C20.6416 23.6667 21.308 23.0003 21.308 22.1787C21.308 21.3557 20.6416 20.6893 19.8187 20.6893ZM20.3333 22.1773L20.3333 22.178V22.1766L20.3333 22.1773Z'
      fill='currentColor'
    />
  </svg>
);
export default CartIcon;
