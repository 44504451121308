export const sortKeys = [
  {
    key: "sortBy=position&sortDir=ASC",
    label: "Newest First",
  },
  {
    key: "sortBy=price&sortDir=ASC",
    label: "Price: from Low to High",
  },
  {
    key: "sortBy=price&sortDir=DESC",
    label: "Price: from High to Low",
  },
  // {
  // 	key: 'sortBy=created_at&sortDir=DESC',
  // 	label: 'latest',
  // },
];

// Our Picks
// Newest First
// Price: from High to Low
// Price: from Low to High
