const SortByIcon = (props) => (
  <svg
    width={12}
    height={12}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11 3.625H8C7.795 3.625 7.625 3.455 7.625 3.25C7.625 3.045 7.795 2.875 8 2.875H11C11.205 2.875 11.375 3.045 11.375 3.25C11.375 3.455 11.205 3.625 11 3.625Z'
      fill='currentColor'
    />
    <path
      d='M3 3.625H1C0.795 3.625 0.625 3.455 0.625 3.25C0.625 3.045 0.795 2.875 1 2.875H3C3.205 2.875 3.375 3.045 3.375 3.25C3.375 3.455 3.205 3.625 3 3.625Z'
      fill='currentColor'
    />
    <path
      d='M5 5.375C3.83 5.375 2.875 4.42 2.875 3.25C2.875 2.08 3.83 1.125 5 1.125C6.17 1.125 7.125 2.08 7.125 3.25C7.125 4.42 6.17 5.375 5 5.375ZM5 1.875C4.24 1.875 3.625 2.49 3.625 3.25C3.625 4.01 4.24 4.625 5 4.625C5.76 4.625 6.375 4.01 6.375 3.25C6.375 2.49 5.76 1.875 5 1.875Z'
      fill='currentColor'
    />
    <path
      d='M11 9.125H9C8.795 9.125 8.625 8.955 8.625 8.75C8.625 8.545 8.795 8.375 9 8.375H11C11.205 8.375 11.375 8.545 11.375 8.75C11.375 8.955 11.205 9.125 11 9.125Z'
      fill='currentColor'
    />
    <path
      d='M4 9.125H1C0.795 9.125 0.625 8.955 0.625 8.75C0.625 8.545 0.795 8.375 1 8.375H4C4.205 8.375 4.375 8.545 4.375 8.75C4.375 8.955 4.205 9.125 4 9.125Z'
      fill='currentColor'
    />
    <path
      d='M7 10.875C5.83 10.875 4.875 9.92 4.875 8.75C4.875 7.58 5.83 6.625 7 6.625C8.17 6.625 9.125 7.58 9.125 8.75C9.125 9.92 8.17 10.875 7 10.875ZM7 7.375C6.24 7.375 5.625 7.99 5.625 8.75C5.625 9.51 6.24 10.125 7 10.125C7.76 10.125 8.375 9.51 8.375 8.75C8.375 7.99 7.76 7.375 7 7.375Z'
      fill='currentColor'
    />
  </svg>
);
export default SortByIcon;
