import * as React from "react";
const EmptyCartIcon = ({ width = 437, height = 276, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 437 276"
    fill="none"
    {...props}
  >
    <path
      d="M402.092 265.725c0 5.64-32.959 10.213-73.616 10.213s-73.616-4.573-73.616-10.213c0-5.641 32.959-10.213 73.616-10.213s73.616 4.572 73.616 10.213"
      fill="#DFDFEB"
    />
    <path
      d="M307.169 270.653c0 6.255-59.543 11.326-132.991 11.326s-132.991-5.071-132.991-11.326 59.542-11.326 132.991-11.326c73.448 0 132.991 5.071 132.991 11.326"
      fill="#DFDFEB"
    />
    <path
      d="M199.602 69.92s-2.461 14.284-20.393 32.67c0 0-1.982-2.452 1.447-12.614 3.428-10.162 17.924-23.517 18.946-20.056"
      fill="#7E9E6E"
    />
    <path
      d="M199.603 69.92s-16.136 11.542-20.393 32.671c0 0-.826 7.246 1.658 13.524"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M156.231 81.459s4.527-3.43-3.262-11.998c-7.398-8.139-15.231-15.436-30.54-18.11 0 0 4.621 20.736 14.596 25.679 9.976 4.943 19.206 4.429 19.206 4.429m-37.158 15.247s15.972 3.484 43.061-5.556c0 0-1.679-2.8-13.784-3.742-12.104-.942-32.431 6.971-29.277 9.298"
      fill="#7E9E6E"
    />
    <path
      d="M119.072 96.706s19.163-10.145 43.062-5.556c0 0 2.458.615 4.961 3.959"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M132.311 111.177s17.092 11.251 44.181 2.211c0 0-5.267-9.531-17.372-10.472-12.106-.943-29.962 5.934-26.809 8.261"
      fill="#7E9E6E"
    />
    <path
      d="M132.312 111.177s18.423-8.596 44.182 2.211c0 0 3.03 1.349 5.533 4.692"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M190.167 56.394s5.045-.604-7.265 10.783c-11.624 10.753-10.146 10.837-10.146 10.837s4.411-16.09 17.411-21.62"
      fill="#000"
    />
    <path
      d="M164.3 83.46s3.767 1.453 10.563-4.783c6.795-6.238 9.328-20.135 15.304-22.283 0 0-14.991 4.843-25.867 27.066"
      fill="#7E9E6E"
    />
    <path
      d="M189.007 131.213c-7.665-15.44-18.306-32.98-32.776-49.754 0 0-3.555-4.656-15.906-16.725-12.35-12.068-17.896-13.384-17.896-13.384"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M188.398 57.134s-14.266 6.225-24.097 26.326c-2.382 4.87.468 8.724 2.796 11.649"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M318.484 223.769s-11.924 8.799-39.397 2.341c0 0 .073-4.406 9.933-8.644s31.022 2.223 29.464 6.303"
      fill="#7E9E6E"
    />
    <path
      d="M317.631 222.967s-18.816-6.288-38.421 2.667c0 0-6.35 3.586-9.975 9.281"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M278.727 204.413s5.435 1.651 7.799-9.684c2.247-10.767 3.557-21.391-3.265-35.356 0 0-14.076 15.914-12.222 26.891 1.855 10.978 7.688 18.149 7.688 18.149"
      fill="#7E9E6E"
    />
    <path
      d="M319.139 208.661s4.194 2.868-12.57 3.327c-15.828.434-14.771 1.471-14.771 1.471s13.916-9.202 27.341-4.798"
      fill="#000"
    />
    <path
      d="M281.849 211.985s1.877 3.576 11.099 3.36 20.281-9.004 26.193-6.685c0 0-14.47-6.231-37.292 3.325"
      fill="#7E9E6E"
    />
    <path
      d="M257.687 260.153c8.001-15.268 15.954-34.18 21.041-55.741 0 0 1.683-5.611 4.203-22.694s.331-22.345.331-22.345"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M317.32 208.052s-14.834-4.715-35.472 3.934c-4.999 2.095-6.418 5.404-8.531 11.538"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M128.473 122.044a16.48 16.48 0 0 1-10.558-3.827l-8.221-6.86c-2.959-2.468-6.473-3.791-10.073-3.791h-40.8a6.704 6.704 0 0 0-6.704 6.704v147.707c0 6.389 5.18 11.568 11.568 11.568h203.387a8.996 8.996 0 0 0 8.996-8.997V131.041a8.996 8.996 0 0 0-8.996-8.998z"
      fill="#9E5A2D"
    />
    <path
      d="M276.067 265.302s-13.842-25.965-15.226-28.946l-37.83-77.067a5.8 5.8 0 0 0-5.261-3.358H77.176c-5.11 0-12.502-2.679-16.426-5.953l-5.673-4.733c-2.958-2.469-6.472-3.791-10.072-3.791H6.177c-4.133 0-6.94 4.198-5.361 8.016l46.951 113.558a17.02 17.02 0 0 0 15.729 10.517h210.032c1.37 0 2.634-1.172 2.633-2.542z"
      fill="#FE6700"
    />
    <path
      d="m218.888 163.258 55.51 107.906m-78.381-89.31h-47.816a3.83 3.83 0 0 1-3.218-1.739c-1.615-2.501.208-5.772 3.218-5.772h47.816c1.303 0 2.517.655 3.217 1.739 1.614 2.5-.209 5.772-3.217 5.772Z"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <path
      d="m106.57 218.941 37.23 22.18m-6.791-23.249-23.393 23.383m23.199-29.329c13.68 6.76 19.356 20.267 12.678 30.168s-23.181 12.446-36.861 5.685-19.355-20.266-12.677-30.167c6.678-9.9 23.181-12.447 36.86-5.686"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M317.456 122.117c.893-3.859-3.213-8.67-7.101-7.912-3.165.618-4.74 1.376-5.249 1.544-.993.328-8.413-.155-9.262 1.081-.616.894 3.255 1.929 5.731 2.175.509.05.66.717.224.986-2.433 1.509-7.275 4.574-7.688 5.332-.552 1.015-3.067 4.962-2.358 5.401.708.437 2.852-1.534 4.245-2.934s5.464-2.589 5.464-2.589-7.078 7.104-8.012 9.073c-1.389 2.933 2.642 1.647 2.642 1.647-.63 1.657 2.675 1.294 3.457.669 1.544-1.235 6.315-6.994 6.329-6.407.015.587-4.929 6.86-4.429 8.327.501 1.464 2.5-.374 3.657-1.92 1.157-1.547 4.554-5.866 4.554-5.866s3.859-2.818 7.081-7.683c.027-.041.705-.879.715-.924"
      fill="#DB9C7F"
    />
    <path
      d="m296.269 135.76 6.753-8.048"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="m266.689 99.288 24.293-15.156q.226-.149.429-.326l8.011-8.243a3.836 3.836 0 0 1 5.72.757l3.926 5.89a3.83 3.83 0 0 1 .074 4.14l-5.639 9.148a3.8 3.8 0 0 1-1.748 1.51c-4.536 1.944-21.868 9.263-32.205 12.183-2.567.726-5.062-1.364-4.837-4.023l.253-2.993a3.83 3.83 0 0 1 1.723-2.887"
      fill="#373B55"
    />
    <path
      d="m322.637 250.726-1.285 8.727s-24.071 9.247-21.839 9.38 36.826-1.879 36.826-1.879l-2.036-15.843z"
      fill="#000"
    />
    <path
      d="M329.343 109.499s1.918 18.463 3.357 19.422 3.357 1.439 3.357 1.439l-3.117-21.1z"
      stroke="#424242"
      strokeMiterlimit={10}
    />
    <path
      d="M367.355 96.81s9.757 19.867 6.924 30.937l-54.086-7.439 1.13-20.64z"
      fill="#FE6700"
    />
    <path
      d="M368.84 100.834s-7.645 14.157-22.274 15.289"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M356.604 113.103s2.962 9.176 11.565 9.366"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M320.194 120.307s-14.49 51.588-14.866 68.195l10.588 63.981s10.099 5.022 20.618.964c.432-.167-5.331-33.184-5.737-67.179l30.238-63.27z"
      fill="#FE6700"
    />
    <path
      d="m374.518 125.583-2.13 62.252 23.818 65.084s-9.967 8.42-22.557.756l-25.857-61.97 2.651-45.091-11.269-6.825-1.679-19.529z"
      fill="#FE6700"
    />
    <path
      d="m332.662 114.205 11.482 8.651 16.46-18.377-39.983 5.712 9.783 12.973z"
      fill="#fff"
    />
    <path
      d="m379.375 249.673-2.077 7.53s-15.186 16.47-12.948 16.47 27.174-10.196 27.174-10.196l-3.196-15.687z"
      fill="#000"
    />
    <path
      d="M365.411 105.615s6.568 16.145 1.835 83.873c0 0 16.589 36.509 25.191 65.387M341.27 141.402l-16.556 46.116 7.1 67.356"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M269.067 108.392s.322 1.615-3.254 2.849c-3.576 1.233-5.68.841-5.68.841s-8.022-9.461-8.378-9.795c-.356-.335.25-3.973 2.623-5.43 1.441-.885 7.03.558 11.199 1.827 2.905.886 4.698 3.795 4.21 6.793-.192 1.179-.432 2.272-.72 2.915"
      fill="#DB9C7F"
    />
    <path
      d="M262.488 105.881s-6.559-.952-9.33-3.237c0 0-4.142-.835-5.021-.312-.881.523-1.602 1.264-3.507 1.586-1.904.321-1.33-.947.671-2.746.572-.514 2.101-2.362 3.427-2.612 1.787-.336 5.651-1.703 5.651-1.703l7.751 9.269"
      fill="#DB9C7F"
    />
    <path
      d="M260.134 112.082s-2.659 1.668-3.594 1.623c-.937-.045-1.819-.603-2.048-2.14 0 0-4.952.909-3.383-2.838 0 0-2.992-.485-3.072-2.439 0 0-2.657-1.323-2.077-3.446.582-2.123 8.919-1.93 8.919-1.93l8.846 4.491z"
      fill="#DB9C7F"
    />
    <path
      d="M258.255 112.437s2.527-2.24 2.385-3.139c-.139-.898-.614-1.392-1.188-1.22-.769.231-3.698 2.464-4.961 3.487m-3.384-2.839s6.729-1.05 6.791-2.047-.408-2.312-1.814-2.032c-1.67.333-6.46 1.48-8.048 1.448"
      stroke="#1A1C30"
      strokeWidth={0.78}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M259.452 108.078s1.236-.767.747-1.625-1.157-1.159-2.588-.787m-9.71-2.38s.367-.479.887-.968"
      stroke="#1A1C30"
      strokeWidth={0.78}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m262.494 115.763-.116.065a1.88 1.88 0 0 1-2.563-.719l-17.493-31.104 3.398-1.91 17.491 31.104c.51.906.189 2.054-.717 2.564"
      fill="#000"
    />
    <path
      d="m250.969 89.005 1.467 2.61c.399.709.219 1.565-.402 1.915l-3.171 1.784c-.621.348-1.448.057-1.845-.65l-1.532-2.725"
      fill="#333969"
    />
    <path
      d="M244.181 63.458c-6.068-11.257-13.673-19.005-18.508-19.368l.16-1.069-2.527 1.647c-4.341 2.956-2.033 16.162 5.154 29.497 7.187 13.334 16.533 21.746 20.874 18.79l2.376-1.583-.684-.916c1.671-5.163-.936-16.034-6.845-26.998"
      fill="#333969"
    />
    <path
      d="M246.708 61.812c7.188 13.334 9.495 26.54 5.154 29.497-4.342 2.956-13.687-5.456-20.875-18.79-7.187-13.335-9.495-26.54-5.153-29.497 4.341-2.956 13.687 5.456 20.874 18.79"
      fill="#000"
    />
    <path
      d="M244.308 63.446c5.85 10.851 8.146 21.314 5.13 23.368s-10.205-5.078-16.053-15.93c-5.85-10.85-8.146-21.313-5.13-23.368s10.203 5.078 16.053 15.93"
      fill="#D3DDF7"
    />
    <path
      d="M262.488 105.881s-6.559-.952-9.33-3.237c0 0-4.142-.835-5.021-.312-.881.523-1.602 1.264-3.507 1.586-1.904.321-1.33-.947.671-2.746.572-.514 2.101-2.362 3.427-2.612 1.787-.336 5.651-1.703 5.651-1.703l7.751 9.269"
      fill="#DB9C7F"
    />
    <path
      d="M262.488 105.881s-6.56-.952-9.331-3.237c0 0-4.142-.835-5.021-.312-.881.523-1.602 1.264-3.507 1.586"
      stroke="#1A1C30"
      strokeWidth={0.78}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M244.309 63.446c-5.85-10.852-13.037-17.984-16.053-15.93a2.3 2.3 0 0 0-.613.646c3.486.212 9.587 6.834 14.675 16.273 5.388 9.995 7.757 19.654 5.742 22.723.522.032.986-.076 1.379-.344 3.016-2.054.72-12.518-5.13-23.368"
      fill="#333969"
    />
    <path
      d="M240.236 59.193c3.111 5.773 4.333 11.338 2.728 12.43s-5.428-2.7-8.539-8.473-4.333-11.338-2.728-12.43 5.427 2.7 8.539 8.473"
      fill="#fff"
    />
    <path
      d="m356.59 77.773-4.826-17.22c-1.771-6.32-4.876-12.169-8.979-17.294-7.267-9.078-26.356-8.252-26.356-8.252l-14.687 10.568-.134.074c-8.647 4.773-10.236 12.133-10.009 23.479l.534 15.32 9.944-9.496 2.509 3.441q-.017-.147-.029-.28l1.189 1.87-1.16-1.59c.352 3.161 2 11.21 9.663 14.972l4.365 6.869-1.158 9.494c40.973 14.899 51.175-14.356 51.175-14.356z"
      fill="#373B55"
    />
    <path
      d="m299.975 73.373 5.364 6.561s-.734 10.112 9.455 13.354"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="m340.689 86.726-31.184 27.17s6.175 8.645 8.954 8.954l37.359-23.774"
      fill="#373B55"
    />
    <path
      d="M318.77 105.56s16.981-19.143 22.848-21.921"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M332.625 66.078s.81 4.284 6.251 19.336"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M322.782 120.38s34.889-22.23 35.198-28.714-8.799-36.586-8.799-36.586m-22.579 33.807s8.397.79 10.999-6.83m-45.003 1.155s2.316-2.315 4.631.31"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="m265.123 98.497 4.94-2.316s6.021 6.561 3.859 12.196l-6.019 1.775s2.777-6.175-2.78-11.655m46.35 11.926s6.483 2.779 11.115 10.767l-4.979 3.822s-6.136-8.8-10.421-10.19zm-5.439-76.416 20.454 1.505s.776 11.251-11.576 21.173l-5.547-5.39-3.022 6.563s-4.694-2.64-5.327-10.22l.997-9.202z"
      fill="#fff"
    />
    <path
      d="M316.429 35.007a2.75 2.75 0 0 1 .927 2.581c-.656 3.662-2.642 12.525-6.624 13.639-5.034 1.408-8.175-8.535-8.175-8.535l2.51-17.368z"
      fill="#DB9C7F"
    />
    <path
      d="m300.479 39.125-.063 2.53c-.046.026-.907 7.49 1.603 7.779 10.485-1.897 11.145-11.838 12.042-21.704 0 0-14.601 9.426-13.582 11.395"
      fill="#000"
    />
    <path
      d="M283.023 20.504s-2.555 24.352 12.572 28.542c15.129 4.191 18.754-20.481 18.232-29.013s-4.325-11.706-14.928-12.089c-15.687-.565-15.876 12.56-15.876 12.56"
      fill="#DB9C7F"
    />
    <path
      d="M296.614 32.27c.044.877-.373 1.61-.93 1.639s-1.044-.66-1.087-1.536c-.043-.877.373-1.61.931-1.639.556-.028 1.043.66 1.086 1.536"
      fill="#000"
    />
    <path
      d="M298.276 27.784s-4.998 2.176-5.489-1.603m-6.064 1.613s-1.651 2.866-3.55 1.593m.892 3.498s.944-.305 2.33.426m12.485 8.658s1.363-1.303 2.488-1.6m-12.714-4.678s-.869 1.975 1.343 2.922"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M282.845 25.739s.256-7.13 3.416-11.16c0 0 4.344 9.874 23.065 11.888l5.45-1.145s2.231-11.434-5.589-15.936c-7.819-4.503-19.787-2.014-23.637 2.034-6.528 6.864-2.705 14.319-2.705 14.319"
      fill="#000"
    />
    <path d="m305.692 25.164 3.239 6.398 2.291-7.187z" fill="#000" />
    <path
      d="M317.011 30.897c-.934 3.403-3.611 5.653-5.981 5.025s-3.535-3.897-2.602-7.3 3.611-5.653 5.98-5.024c2.371.627 3.535 3.896 2.603 7.299"
      fill="#DB9C7F"
    />
    <path
      d="M314.634 27.934s-2.666-2.293-4.121 1.9c0 0 1.557 1.653.575 2.95 0 0 1.534.348 2.292-1.006"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M313.479 29.538s-.148-.931-1.184-1.994"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M314.776 35.808a2.31 2.31 0 1 1-4.62 0 2.31 2.31 0 0 1 4.62 0"
      fill="#fff"
    />
    <path
      d="M308.298 12.368s2.843-14.85 9.479-12.006c6.634 2.843 9.636 17.061 3.317 18.167s-11.216-1.896-11.216-1.896z"
      fill="#000"
    />
    <path
      d="M308.377 16.633s10.98 9.162 14.534.711"
      stroke="#000"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="m349.871 146.261-12.71-7.172"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M61.385 52.067c13.733.374 25.096 11.374 25.886 25.09.464 8.064-2.661 15.403-7.92 20.58a3.33 3.33 0 0 0-.957 2.931l1.975 11.689-12.153-7.081a3.28 3.28 0 0 0-2.294-.393 26.9 26.9 0 0 1-9.363.218c-12.006-1.819-21.373-11.759-22.48-23.851-1.477-16.119 11.397-29.617 27.306-29.183"
      fill="#000"
    />
    <path
      d="M60.461 92.744a.4.4 0 0 1-.068.12l-.165-.135-.002-.002-.005-.004-.27-.222-.023-.02.024-.018.11-.083.03.012a.74.74 0 0 1 .285.212l.001.001.001.001a.6.6 0 0 1 .082.138Zm-.51-.389c.009 0 .048.004.107.024l-.106.08-.027.02-.115-.094a.5.5 0 0 1 .142-.03q-.005 0 0 0Zm-.028.125-.324.243-.124-.09a.7.7 0 0 1 .334-.248zm-.315.243.318-.24.026.02.266.22.007.005.002.002.165.136a.65.65 0 0 1-.315.205l-.125-.091-.017-.012-.002-.001-.33-.24zm.344.264.119.085a.5.5 0 0 1-.112.019l-.008-.001a.5.5 0 0 1-.12-.037l-.002-.001a.8.8 0 0 1-.32-.255l.088-.066.332.24h.002zm.013.104h-.003zm-.373-.363-.086.064a.6.6 0 0 1-.062-.104.2.2 0 0 1 .026-.048l.115.083z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={3}
    />
  </svg>
);
export default EmptyCartIcon;
