import Image from '@/components/Image';
import { modifyMenuTreeData } from '@/helpers/category';
import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import Link from 'next/link';

const CategoryList = ({ children_data, parentUrl, currentCat, setOpen }) => {
  const isShowBanners = currentCat?.category_banners?.length > 0;

  return (
    <section className="absolute -top-px start-56 w-[920px]">
      <div className="hidden grid-cols-7 gap-5 py-5 mx-auto bg-white border ms-8 rounded-b-md px-7 group-hover:grid">
      <div
        className={`grid gap-2 self-start ${
          isShowBanners ? 'col-span-5 grid-cols-3' : 'col-span-7 grid-cols-4'
        } `}
      >
        {children_data?.map((cat) => {
          const childUrl = `${parentUrl}/${cat?.url_key}`;
          const modifiedData = modifyMenuTreeData(cat?.children_data);

          return (
            <div key={cat?.id} className={modifiedData?.length > 0?'mb-7':''}>
              <Link
                className='block mb-2 text-sm font-semibold'
                href={childUrl}
                onClick={() => setOpen(false)}
              >
                {cat?.name}
              </Link>
              <ul className='flex flex-col gap-2'>
                {modifiedData?.length > 0 &&
                  modifiedData.map((subCat) => {
                    const subChildUrl = `${childUrl}/${subCat?.url_key}`;

                    return (
                      <li
                        key={subCat?.id}
                        className='cursor-pointer text-[13px] text-[#858585] transition-all hover:font-bold hover:text-primary'
                      >
                        <Link onClick={() => setOpen(false)} href={subChildUrl}>{subCat?.name}</Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          );
        })}
      </div>
      <div className='flex flex-col col-span-2 gap-5'>
        {currentCat?.category_banners?.map((banner, i) => {
          return (
            <Link
              onClick={() => setOpen(false)}
              href={handleLinkByType(banner?.url, banner?.type)}
              key={i}
              className='relative'
            >
              <Image
                loading='eager'
                src={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/${banner?.banner_image}`}
                className={`h-60 w-full rounded-xl object-cover`}
                alt={'banner'}
              />
              <div className='absolute top-0 z-50 flex flex-col justify-center w-full h-full gap-2 text-white bg-black bg-opacity-50 start-0 rounded-xl ps-5'>
                <p className='text-xs'>{banner?.title}</p>
                <p className='font-medium uppercase'>{banner?.subtitle}</p>
                <p className='font-medium uppercase'>{banner?.description}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
    </section>
  );
};

export default CategoryList;
