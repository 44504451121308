import { useTranslation } from 'next-i18next';
import { useRef } from 'react';

import Otp from '../forms/auth/Otp';
import CloseIcon from '../icons/CloseIcon';
import UserSignin from '../forms/auth/Signin';
import UserSignUp from '../forms/auth/Signup';
import useBodyScrollLock from '@/hooks/useBodyScrollLock';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import ForgotPassword from '../forms/auth/ForgotPassword';
import ChangePassword from '../forms/auth/ChangePassword';
import SuccessChangePassword from '../forms/auth/SuccessChangePassword';

const AuthModal = ({ auth, setAuth }) => {
  const ref = useRef();
  useOnClickOutside([ref], () => setAuth(false));
  useBodyScrollLock();
  const { t } = useTranslation('common');
  return (
    <section
      ref={ref}
      className='max-h-[90vh] w-80 overflow-y-auto rounded-md bg-white p-3 sm:w-[550px] sm:p-4'
    >
      <div className='flex items-center justify-between pb-3 border-b'>
        <h2 className='font-bold sm:text-xl'>{t(auth)}</h2>
        <CloseIcon
          className='cursor-pointer size-5'
          onClick={() => setAuth(false)}
        />
      </div>
      {(auth === 'Login' || auth === 'Register') && (
        <div className='grid grid-cols-2 my-4'>
          <button
            onClick={() => setAuth('Login')}
            className={`rounded-s-lg border ${auth === 'Login' ? 'border-primary bg-primary text-white' : 'text-[#005471]'} p-2 text-sm font-bold sm:text-lg`}
          >
            {t('Login')}
          </button>
          <button
            onClick={() => setAuth('Register')}
            className={`rounded-e-lg border p-2 text-sm font-bold ${auth === 'Register' ? 'border-primary bg-primary text-white' : 'text-[#005471]'} sm:text-lg`}
          >
            {t('Register')}
          </button>
        </div>
      )}
      {auth === 'Login' ? (
        <UserSignin setAuth={setAuth} t={t} />
      ) : auth === 'Register' ? (
        <UserSignUp t={t} setAuth={setAuth} />
      ) : auth === 'Forgot Password' ? (
        <ForgotPassword t={t} setAuth={setAuth} />
      ) : auth === 'Otp' ? (
        <Otp t={t} setAuth={setAuth} />
      ) : auth === 'Change Password' ? (
        <ChangePassword t={t} setAuth={setAuth} />
      ) : auth === 'Change Password Success' ? (
        <SuccessChangePassword t={t} setAuth={setAuth} />
      ) : null}
    </section>
  );
};

export default AuthModal;
