import { modifyMenuTreeData } from "@/helpers/category";

export const appReducer = (state, action) => {
  switch (action.type) {
    case 'GET_CAT_TREE':
      return {
        ...state,
        categoryData: action?.payload,
        menuTreeData: modifyMenuTreeData(action?.payload?.children_data),
      };
    case 'ADD_TO_WISHLIST':
      return {
        ...state,
        wishlist: { ...state.whisList, [action.payload?.sku]: action.payload },
      };
    case 'REMOVE_FROM_WISHLIST':
      const { wishlist } = state;
      delete wishlist[action.payload];
      return {
        ...state,
        wishlist,
      };
    case 'SET_REGION':
      return {
        ...state,
        regionId: action?.payload,
      };
    case 'RESET_APP':
      return {
        categoryData: [],
        whisList: {},
        regionId: null,
      };
    default:
      return state;
  }
};
