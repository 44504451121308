const EmailIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_1_1095)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.54523 12.183H13.4549C13.5105 12.183 13.5556 12.1362 13.5556 12.0787V3.92206C13.5556 3.86411 13.5101 3.817 13.4541 3.817H2.54523C2.49043 3.817 2.44449 3.86365 2.44449 3.92206V12.0787C2.44448 12.0923 2.44706 12.1065 2.45209 12.1191C2.45711 12.1316 2.46448 12.1431 2.47377 12.1527C2.48307 12.1623 2.4941 12.1699 2.50625 12.1751C2.51839 12.1803 2.53209 12.183 2.54523 12.183ZM2.54523 13.3333H13.4549C14.1237 13.3333 14.6667 12.772 14.6667 12.0787V3.92206C14.6667 3.2288 14.1237 2.66667 13.4541 2.66667H2.54523C1.87634 2.66667 1.33337 3.2288 1.33337 3.92206V12.0787C1.33328 12.2435 1.36456 12.4067 1.42542 12.5589C1.48629 12.7112 1.57555 12.8496 1.6881 12.9661C1.80065 13.0826 1.93428 13.175 2.08136 13.238C2.22843 13.301 2.38606 13.3334 2.54523 13.3333Z'
        fill='currentColor'
      />
      <path
        d='M2.07935 4.05L7.20868 7.71867C7.43468 7.88039 7.70556 7.96746 7.98347 7.9677C8.26137 7.96794 8.5324 7.88134 8.75868 7.72L13.9173 4.04067'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1_1095'>
        <rect width={16} height={16} fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default EmailIcon;
