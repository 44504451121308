const getAttributes = (options, configData) => {
  // get current options but with fully config data
  const result = {};

  for (const key in options) {
    const optionId = options[key];
    const attribute = configData[key].find(
      (attr) => attr.option_id === optionId
    );

    if (attribute) {
      result[key] = attribute;
    }
  }

  return result;
};

export const handleConfigurableProduct = (chosenConfigurable, product) => {
  return {
    // original properties
    ...product,
    prices_with_tax:
      chosenConfigurable?.prices_with_tax || product?.prices_with_tax,
    short_description:
      chosenConfigurable?.short_description || product?.short_description,
    _media_:
      chosenConfigurable?._media_?.length > 0
        ? chosenConfigurable?._media_
        : product?._media_,
    stock: {
      qty: chosenConfigurable?.qty || 0,
      is_in_stock: chosenConfigurable?.stock,
    },

    // new properties added to simplify the process
    current_option: getAttributes(
      chosenConfigurable?.options,
      product?.option_details
    ),
    configurable_options: Object.entries(product?.option_details).map(
      (option) => ({
        label: option[0],
        value: option[1],
      })
    ),
  };
};

// Put It temporarily

export const productPriceHandler = (price, from_data, to_date) => {
  const today = new Date();
  const from = new Date(from_data);
  const to = new Date(to_date);

  const withinPeriod =
    (today >= from && today <= to) || (today >= from && to_date === '');

  return price > 0 && withinPeriod ? price : 0;
};

export const productRatingHandler = (productReviews) => {
  const ratingScore = productReviews?.length
    ? (
        productReviews
          ?.map((r) => r?.ratings?.[0]?.value || 5)
          ?.reduce((a, b) => a + b) / productReviews?.length
      )?.toFixed(2)
    : 0;

  return { ratingScore: ratingScore, reviews_count: productReviews?.length };
};

/**
 * Format Number
 * @param {number} number
 * @returns {string} formatted number or null
 * @example
 * formatPrice(100) // "100"
 * formatPrice(100.50) // "100.50"
 * formatPrice(0) // "0"
 * formatPrice(null) // null
 * formatPrice(NaN) // null
 **/
function intOrFixedNumber(number) {
  if (isNaN(number)) return null;
  const fixedNumber = number?.toFixed(2);
  const [IntPart, floatPart] = String(fixedNumber)?.split('.');
  return floatPart === '00' ? IntPart : fixedNumber;
}

/**
 * Validates and computes special pricing information based on provided prices object.
 * @param {object} prices - The object containing pricing information.
 * @param {number} prices.original_price - The original price of the product.
 * @param {string} prices.discounted_price_from - The start date of the discounted price period (ISO format).
 * @param {string} prices.discounted_price_to - The end date of the discounted price period (ISO format).
 * @param {number} prices.discounted_price - The discounted price of the product.
 * @returns {object} An object containing validated pricing details.
 */

export const validatePrice = (prices) => {
  const now = new Date();
  const startDate = new Date(prices.discounted_price_from);
  const endDate = new Date(prices.discounted_price_to);

  let originalPrice = prices.original_price;
  let specialPrice = null;
  let discount = null;

  const withinPeriod =
    ((now >= startDate && now <= endDate) ||
      (now >= startDate && prices.discounted_price_to === '')) &&
    prices.discounted_price < prices.original_price;

  if (withinPeriod) {
    specialPrice = prices.discounted_price;
    discount = ((originalPrice - specialPrice) / originalPrice) * 100;
  }

  return {
    originalPrice,
    specialPrice,
    discount: intOrFixedNumber(discount),
  };
};

/**
 * format price object to use as params in validatePrice function
 * @param {object} item //product item with prices
 * @returns {object} // { price, original_price, discounted_price_from, discount_price_to } formated price object to use as params in validatePrice function
 */
export const priceFormattedObject = (item) => {
  return {
    discounted_price:
      item?.extension_attributes?.special_price ||
      item?.prices_with_tax?.discounted_price ||
      0,
    original_price:
      item?.extension_attributes?.original_price ||
      item?.prices_with_tax?.original_price ||
      0,
    discounted_price_from:
      item?.extension_attributes?.special_price_from ||
      item?.prices_with_tax?.discounted_price_from,
    discounted_price_to:
      item?.extension_attributes?.special_price_to ||
      item?.prices_with_tax?.discounted_price_to ||
      '',
  };
};
