import Brands from './Brands';

const CategoryOptions = ({
  isModal,
  filterList,
  selectedFilterKeys,
  handleFilter,
  handleResetFilter,
  // fromSearch = null,
  t,
}) => {
  return (
    <div
      className={
        !isModal
          ? 'col-span-full *:mb-4 max-md:hidden md:col-span-3'
          : 'flex flex-col gap-4 border-b px-3 py-3'
      }
    >
      {!isModal && selectedFilterKeys?.length ? (
        <button
          disabled={
            selectedFilterKeys?.length == 0
          }
          onClick={handleResetFilter}
          className={`mb-3 rounded bg-primary px-4 py-2 font-medium text-white ${
            selectedFilterKeys?.length == 0
              ? 'cursor-not-allowed opacity-50'
              : null
          }`}
        >
          {t('Reset Filter')}
        </button>
      ) : null}
      {filterList?.map((filterSection, key) => {
        const title = filterSection?.label || '';
        const options = filterSection?.filterable_values || [];
        const code = filterSection?.filter_key || '';
        return options?.length ? (
          <div key={key} className='p-3 border rounded-md'>
            <h2 className='mb-2 font-semibold sm:text-lg'>{title}</h2>
            <Brands
              {...{ options, selectedFilterKeys, code, handleFilter, t }}
            />
          </div>
        ) : null;
      })}
      {/* <Brands locale={locale} /> */}
    </div>
  );
};

export default CategoryOptions;
