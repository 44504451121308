const CloseIcon = (props) => (
  <svg
    width={32}
    height={32}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M25.7081 24.2926C25.801 24.3855 25.8747 24.4958 25.9249 24.6172C25.9752 24.7386 26.0011 24.8687 26.0011 25.0001C26.0011 25.1315 25.9752 25.2616 25.9249 25.383C25.8747 25.5044 25.801 25.6147 25.7081 25.7076C25.6151 25.8005 25.5048 25.8742 25.3835 25.9245C25.2621 25.9747 25.132 26.0006 25.0006 26.0006C24.8692 26.0006 24.7391 25.9747 24.6177 25.9245C24.4963 25.8742 24.386 25.8005 24.2931 25.7076L16.0006 17.4138L7.70806 25.7076C7.52042 25.8952 7.26592 26.0006 7.00056 26.0006C6.73519 26.0006 6.4807 25.8952 6.29306 25.7076C6.10542 25.5199 6 25.2654 6 25.0001C6 24.7347 6.10542 24.4802 6.29306 24.2926L14.5868 16.0001L6.29306 7.70757C6.10542 7.51993 6 7.26543 6 7.00007C6 6.7347 6.10542 6.48021 6.29306 6.29257C6.4807 6.10493 6.73519 5.99951 7.00056 5.99951C7.26592 5.99951 7.52042 6.10493 7.70806 6.29257L16.0006 14.5863L24.2931 6.29257C24.4807 6.10493 24.7352 5.99951 25.0006 5.99951C25.2659 5.99951 25.5204 6.10493 25.7081 6.29257C25.8957 6.48021 26.0011 6.7347 26.0011 7.00007C26.0011 7.26543 25.8957 7.51993 25.7081 7.70757L17.4143 16.0001L25.7081 24.2926Z'
      fill='currentColor'
    />
  </svg>
);
export default CloseIcon;
