import useBodyScrollLock from '@/hooks/useBodyScrollLock';
import useHandleOutSide from '@/hooks/useHandleOutSide';
import ArrowIcon from '../icons/ArrowIcon';
import CloseIcon from '../icons/CloseIcon';
import SortByIcon from '../icons/SortBy';

import { sortKeys } from '@/constants/Category';
import { useRef } from 'react';

const SortByModal = ({ setOpen, t, handleSorting }) => {
  const ref = useRef();
  useHandleOutSide(ref, () => setOpen(false));
  useBodyScrollLock();
  return (
    <div ref={ref} className='fixed bottom-0 w-screen bg-white'>
      <div className='flex items-center justify-between p-3 border-b'>
        <div className='flex items-center gap-2'>
          <SortByIcon />
          <span className='text-sm font-bold'>{t('Sort By')}</span>
        </div>
        <CloseIcon
          onClick={() => setOpen(false)}
          className='cursor-pointer size-4'
        />
      </div>
      <ul className='flex flex-col gap-4 px-3 py-3 border-b'>
        {sortKeys?.map((item, key) => (
          <li
            key={key}
            onClick={() => {
              handleSorting({ keys: item?.key.slice(7, item?.key.length) });
              setOpen(false);
            }}
            className='flex items-center justify-between cursor-pointer'
          >
            <span className='text-sm'>{t(item?.label)}</span>
            <ArrowIcon />
          </li>
        ))}
      </ul>
      <div className='grid grid-cols-2 gap-4 p-3'>
        <button onClick={() => setOpen(false)} className='rounded-md border border-primary bg-primary py-1.5 text-[13px] font-bold text-white'>
          {t('Show Results')}
        </button>
        <button className='rounded-md border border-primary py-1.5 text-[13px] font-bold text-primary'>
          {t('Reset Filter')}
        </button>
      </div>
    </div>
  );
};

export default SortByModal;
