import UserDashboardIcon from '@/components/icons/UserDashboardIcon';
import AddressDasboardIcon from '@/components/icons/AddressDashboardIcon';
import OrderIcon from '@/components/icons/OrderIcon';
import Heart from '@/components/icons/Heart';
import LogoutIcon from '@/components/icons/LogoutIcon';
import { signOut } from 'next-auth/react';
import { resetSession } from '@/helpers/common/resetSession';
import { useRouter } from 'next/router';

const UserDropDown = () => {
  const { locale, push } = useRouter();
  return (
    <div className='absolute start-0 top-full z-[200] hidden w-40 rounded-md bg-white p-2 shadow group-hover:block'>
      <div
        onClick={() => {
          push('/user');
        }}
        className='flex cursor-pointer items-center gap-2 border-b pb-2.5'
      >
        <UserDashboardIcon className='size-[18px]' />
        <span className='text-[15px] font-semibold'>
          {locale === 'en' ? 'Dashboard' : 'الحساب'}
        </span>
      </div>
      <div
        onClick={() => {
          push('/user/addresses');
        }}
        className='flex cursor-pointer items-center gap-2 border-b py-2.5'
      >
        <AddressDasboardIcon className='size-[18px]' />
        <span className='text-[15px] font-semibold'>
          {locale === 'en' ? 'Addresses' : 'العناوين'}
        </span>
      </div>
      <div
        onClick={() => {
          push('/user/orders');
        }}
        className='flex cursor-pointer items-center gap-2 border-b py-2.5'
      >
        <OrderIcon className='size-[18px]' />
        <span className='text-[15px] font-semibold'>
          {locale === 'en' ? 'Orders' : 'الطلبات'}
        </span>
      </div>
      <div
        onClick={() => {
          push('/user/wishlist');
        }}
        className='flex cursor-pointer items-center gap-2 border-b py-2.5'
      >
        <Heart className='size-[18px]' />
        <span className='text-[15px] font-semibold'>
          {locale === 'en' ? 'Favourites' : 'المفضلة'}
        </span>
      </div>
      <button
        className='flex cursor-pointer items-center gap-2 pt-2 text-red-600'
        onClick={() => {
          resetSession();
          signOut({ callbackUrl: '/' });
        }}
      >
        <LogoutIcon className='size-[18px]' />
        <span className='text-[15px] font-semibold'>
          {locale === 'en' ? 'Logout' : 'تسجيل الخروج'}
        </span>
      </button>
    </div>
  );
};

export default UserDropDown;
