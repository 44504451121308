const Eyelock = (props) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.46992 15.28C9.27992 15.28 9.08992 15.21 8.93992 15.06C8.11992 14.24 7.66992 13.15 7.66992 12C7.66992 9.61004 9.60992 7.67004 11.9999 7.67004C13.1499 7.67004 14.2399 8.12004 15.0599 8.94004C15.1999 9.08004 15.2799 9.27004 15.2799 9.47004C15.2799 9.67004 15.1999 9.86004 15.0599 10L9.99992 15.06C9.84992 15.21 9.65992 15.28 9.46992 15.28ZM11.9999 9.17004C10.4399 9.17004 9.16992 10.44 9.16992 12C9.16992 12.5 9.29992 12.98 9.53992 13.4L13.3999 9.54004C12.9799 9.30004 12.4999 9.17004 11.9999 9.17004Z'
      fill='currentColor'
    />
    <path
      d='M5.59984 18.51C5.42984 18.51 5.24984 18.45 5.10984 18.33C4.03984 17.42 3.07984 16.3 2.25984 15C1.19984 13.35 1.19984 10.66 2.25984 8.99998C4.69984 5.17998 8.24984 2.97998 11.9998 2.97998C14.1998 2.97998 16.3698 3.73998 18.2698 5.16998C18.5998 5.41998 18.6698 5.88998 18.4198 6.21998C18.1698 6.54998 17.6998 6.61998 17.3698 6.36998C15.7298 5.12998 13.8698 4.47998 11.9998 4.47998C8.76984 4.47998 5.67984 6.41998 3.51984 9.80998C2.76984 10.98 2.76984 13.02 3.51984 14.19C4.26984 15.36 5.12984 16.37 6.07984 17.19C6.38984 17.46 6.42984 17.93 6.15984 18.25C6.01984 18.42 5.80984 18.51 5.59984 18.51Z'
      fill='currentColor'
    />
    <path
      d='M11.9996 21.02C10.6696 21.02 9.36957 20.75 8.11957 20.22C7.73957 20.06 7.55957 19.62 7.71957 19.24C7.87957 18.86 8.31957 18.68 8.69957 18.84C9.75957 19.29 10.8696 19.52 11.9896 19.52C15.2196 19.52 18.3096 17.58 20.4696 14.19C21.2196 13.02 21.2196 10.98 20.4696 9.81C20.1596 9.32 19.8196 8.85 19.4596 8.41C19.1996 8.09 19.2496 7.62 19.5696 7.35C19.8896 7.09 20.3596 7.13 20.6296 7.46C21.0196 7.94 21.3996 8.46 21.7396 9C22.7996 10.65 22.7996 13.34 21.7396 15C19.2996 18.82 15.7496 21.02 11.9996 21.02Z'
      fill='currentColor'
    />
    <path
      d='M12.6896 16.27C12.3396 16.27 12.0196 16.02 11.9496 15.66C11.8696 15.25 12.1396 14.86 12.5496 14.79C13.6496 14.59 14.5696 13.67 14.7696 12.57C14.8496 12.16 15.2396 11.9 15.6496 11.97C16.0596 12.05 16.3296 12.44 16.2496 12.85C15.9296 14.58 14.5496 15.95 12.8296 16.27C12.7796 16.26 12.7396 16.27 12.6896 16.27Z'
      fill='currentColor'
    />
    <path
      d='M2.00043 22.75C1.81043 22.75 1.62043 22.68 1.47043 22.53C1.18043 22.24 1.18043 21.76 1.47043 21.47L8.94043 14C9.23043 13.71 9.71043 13.71 10.0004 14C10.2904 14.29 10.2904 14.77 10.0004 15.06L2.53043 22.53C2.38043 22.68 2.19043 22.75 2.00043 22.75Z'
      fill='currentColor'
    />
    <path
      d='M14.5297 10.2199C14.3397 10.2199 14.1497 10.1499 13.9997 9.99994C13.7097 9.70994 13.7097 9.22994 13.9997 8.93994L21.4697 1.46994C21.7597 1.17994 22.2397 1.17994 22.5297 1.46994C22.8197 1.75994 22.8197 2.23994 22.5297 2.52994L15.0597 9.99994C14.9097 10.1499 14.7197 10.2199 14.5297 10.2199Z'
      fill='currentColor'
    />
  </svg>
);
export default Eyelock;
