import { useRouter } from 'next/router';

const ExtraAttributes = ({ product }) => {
  const { locale } = useRouter();
  return (
    <>
      <div className='flex flex-col gap-2 text-sm max-sm:mt-4'>
        <p className='flex items-center gap-2'>
          <span className='max-sm:self-start'>
            {locale === 'en' ? 'Code' : 'الكود'}:
          </span>
          <span>{product?.sku}</span>
        </p>
        <p className='flex items-center gap-2'>
          <span className='max-sm:self-start'>
            {locale === 'en' ? 'Category' : 'التصنيف'}:
          </span>
          <span className='text-[#479622]'>
            {product?._category_?.main_category?.name}
          </span>
        </p>
        {product?.keywords?.length > 0 && (
          <p className='flex items-center gap-2'>
            <span className='max-sm:self-start'>
              {locale === 'en' ? 'Keywords' : 'الكلمات المفتاحية'}:
            </span>
            <span className='text-[#479622]'>
              {product?.keywords?.join(', ')}
            </span>
          </p>
        )}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: product?.short_description?.replace(/\n/g, '<br/>'),
        }}
        className='text-sm'
      ></div>
    </>
  );
};

export default ExtraAttributes;
