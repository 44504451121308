const FooterFaceBook = (props) => (
  <svg
    width={35}
    height={36}
    viewBox='0 0 35 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      opacity={0.25}
      d='M33.9412 18.106C33.9412 27.0232 26.6886 34.2575 17.7353 34.2575C8.78205 34.2575 1.52942 27.0232 1.52942 18.106C1.52942 9.18882 8.78205 1.95459 17.7353 1.95459C26.6886 1.95459 33.9412 9.18882 33.9412 18.106Z'
      stroke='currentColor'
      strokeWidth={2}
    />
    <path
      d='M16.358 23.3603L16.3424 18.5602H14.2941V16.503H16.3424V15.1316C16.3424 13.2807 17.4836 12.3887 19.1277 12.3887C19.9152 12.3887 20.592 12.4476 20.7892 12.4739V14.4082L19.649 14.4087C18.7549 14.4087 18.5818 14.8354 18.5818 15.4616V16.503H21.1218L20.439 18.5602H18.5818V23.3603H16.358Z'
      fill='currentColor'
    />
  </svg>
);
export default FooterFaceBook;
