import SuccessChangePasswordIcon from '@/components/icons/SuccessChangePasswordIcon';

const SuccessChangePassword = ({ t, setAuth }) => {
  return (
    <section className='mt-3 sm:mt-5'>
      <SuccessChangePasswordIcon className='mx-auto my-3 size-44 sm:size-72' />
      <p className='my-1 text-xl font-bold text-center text-primary max-sm:text-sm'>
        {t('The password has been changed successfully')}
      </p>
      <p className='text-lg text-center text-secondary max-sm:text-sm'>
        {t('Please log in to enjoy Our features')}
      </p>
      <button
        onClick={() => setAuth('Login')}
        className='w-full p-2 mt-3 font-semibold text-white rounded-md bg-primary max-sm:text-sm'
      >
        {t('Back to Login')}
      </button>
    </section>
  );
};

export default SuccessChangePassword;
