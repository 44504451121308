import axios from "@/utils/axios-config";

 async function subscribeToNewsletter (handshake, mage, email){
  let config = {
    method:"get",
    url:"/mstore/newsletterSubscription/" + email,
    maxBodyLength : Infinity,
    headers:{
      Authorization: "Bearer " + handshake,
      mage: "Bearer " + mage
    }
  }
    try{
      const req = await axios(config)
      return req
    }catch(error){
      return error
    }
  }  
  const sendContactData = async (values, handshake) => {
    const config = {
      method: 'post',
      url: '/mstore/contactUs',
      headers: {
        Authorization: `Bearer ${handshake}`,
      },
      data: JSON.stringify(values),
    };
    try {
      const req = await axios(config);
      return req;
    } catch (error) {
      return error;
    }
  };

  export {subscribeToNewsletter, sendContactData}