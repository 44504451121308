export const handleLinkByType = (link, type = '') => {
  switch (type.toLowerCase()) {
    case 'url':
      return `${link}`;
    case 'product':
      return `/product/${link}`;
    case 'category':
      return `/${link}`;
    default:
      return ``;
  }
};
