import Link from 'next/link';
import { useRouter } from 'next/router';

const BreadCrumb = ({ data = [] }) => {
  const { locale, push } = useRouter();
  return (
    <section>
      <div className='mx-auto flex items-center gap-2'>
        <Link
          href='/'
          className='text-[11px] font-medium text-secondary sm:text-sm'
        >
          {locale === 'en' ? 'Home' : 'الرئيسية'}
        </Link>
        <span> / </span>
        {data?.map((info, i) => (
          <div
            className={`flex items-center gap-2 text-secondary last:text-black ${info?.url ? 'cursor-pointer' : ''}`}
            key={i}
            onClick={() => {
              if (i !== data?.length - 1 && info?.url) {
                push(info?.url);
              }
            }}
          >
            <span
              className={`${i === data?.length - 1 ? 'line-clamp-1' : 'whitespace-nowrap'} flex-1 text-[11px] font-medium capitalize sm:text-sm`}
            >
              {typeof info === 'object'
                ? info?.name?.toLowerCase() || ''
                : info?.toLowerCase() || ''}
            </span>
            <span className={i === data?.length - 1 && 'hidden'}> / </span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BreadCrumb;
