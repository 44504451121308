import ArrowIcon from '@/components/icons/ArrowIcon';
import SignInUpIcon from '@/components/icons/SignInUpIcon';
import TrackIcon from '@/components/icons/TrackIcon';
import { useSession } from 'next-auth/react';
import UserDropDown from '../UserDropDown';

import { useGlobalData } from '@/context/global';
import CategoriesDropdown from './CategoriesDropdown';
import Link from 'next/link';

const CategoriesBar = ({ t, setAuth }) => {
  const { data: session } = useSession();
  const {
    appData: { menuTreeData },
  } = useGlobalData();

  return (
    <section className=' mb-5 hidden py-3 shadow-categories md:block '>
      <div className='container mx-auto flex-wrap items-center gap-11 md:flex'>
        <CategoriesDropdown t={t} menuTreeData={menuTreeData} />
        <div className='flex flex-1 flex-wrap items-center justify-between gap-y-4'>
          <div className='flex cursor-pointer items-center gap-3'>
            <Link href={'/'}>
            <span className='font-[500]'>{t('Home')}</span>
            </Link>
          </div>
          <div className='flex cursor-pointer items-center gap-3'>
            <span className='font-[500]'>{t('New Products')}</span>
            <ArrowIcon />
          </div>
          <div className='flex cursor-pointer items-center gap-2'>
            <span className='font-[500]'>{t('Promotions')}</span>
            <span className='rounded-full bg-[#58B18E] px-2 text-center text-[13px] text-white'>
              Hot
            </span>
          </div>
          <div className='flex cursor-pointer items-center gap-3'>
            <TrackIcon className='text-primary' />
            <span className='font-[500]'>{t('Track your order')}</span>
          </div>
          {session == null ? (
            <div
              onClick={() => setAuth('Login')}
              className='flex cursor-pointer items-center gap-3'
            >
              <SignInUpIcon className='text-primary' />
              <span className='font-[500]'>{t('Sign In / Register')}</span>
            </div>
          ) : (
            <div className='group relative flex cursor-pointer items-center gap-3'>
              <SignInUpIcon className='text-primary' />

              <span className='font-[500]'>
                {session?.user?.firstname} {session?.user?.lastname}
              </span>
              <UserDropDown />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CategoriesBar;
