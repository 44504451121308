import selectedAtteributes from '@/constants/selectedAttrubites';
import axiosRequest from '@/utils/axios-config';

/*
===============================|> All Category APis <|===============================
01 |--> get category tree
02 |--> get products by category id
===============================|> All Category APis <|===============================
*/

// 01- =============> get category tree
const getCategoryTree = async (handshake) => {
  const config = {
    method: 'get',
    url: '/mstore/category/tree',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};
// 02- =============> get products by category id
// * sortBy can be position | price
// * sortDir can be ASC | DESC
const getProductsByCategoryId = async (
  handshake,
  categoryId,
  sortBy = 'position',
  sortDir = 'ASC',
  pageSize = 30,
  pageNo = 0,
  getFullResponse = true
) => {
  const url = `/search/products/facets/category/${categoryId}?pageSize=${pageSize}&pageNo=${pageNo}&sortBy=${sortBy}&sortDir=${sortDir}`
  console.log('url///////////', url)
  const config = {
    method: 'get',
    url,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (!getFullResponse) {
    config.url += `&${selectedAtteributes}`;
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

export { getCategoryTree, getProductsByCategoryId };
