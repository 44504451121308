import axiosRequest from '@/utils/axios-config';

const getProductBySku = async (handshake, sku) => {
  const config = {
    method: 'get',
    url: `/search/product/${sku}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    console.log('getProductBySku ==================>>', req);
    return req;
  } catch (error) {
    console.log('getProductBySku ==================>>', error);
    return error;
  }
};

const getProductsBySkus = async (handshake, skuBulk, selectedAtteributes) => {
  const transformSKus = skuBulk
    ? skuBulk.map((sku) => 'sku=' + sku).join('&')
    : '';

  const config = {
    method: 'get',
    url: '/search/product?' + transformSKus,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (selectedAtteributes) {
    const atterbiutes = selectedAtteributes
      .map((select) => 'select=' + select)
      .join('&');
    config.url += `&${atterbiutes}`;
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const notifyWhenAvailableProduct = async ({ handshake, userId, entity_id }) => {
  const config = {
    method: "get",
    url: `/mstore/notify-me/customer/${userId}/product/${entity_id}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};
const getBranches = async (handshake) => {
  const config = {
    method: "get",
    url: "/mstore/storelocator",
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const getProductsReviews = async (handshake, sku, selectedAtteributes) => {
  const config = {
    method: 'get',
    url: '/product/reviews/' + sku,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (selectedAtteributes) {
    const atterbiutes = selectedAtteributes
      .map((select) => 'select=' + select)
      .join('&');
    config.url += `&${atterbiutes}`;
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

export { getProductBySku, getProductsBySkus, notifyWhenAvailableProduct, getBranches, getProductsReviews };
