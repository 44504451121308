import { useGlobalData } from '@/context/global';
import { useState } from 'react';

import Link from 'next/link';
import ArrowIcon from '@/components/icons/ArrowIcon';

const Categories = ({ setSide }) => {
  const {
    appData,
  } = useGlobalData();
  const menuTreeData = appData?.menuTreeData 
  const [active, setActive] = useState(menuTreeData.map(() => false));
  return (
    <div className='p-3 border-b'>
      {menuTreeData.map((tree, i) => (
        <div key={i} className='mb-3 last:mb-0'>
          <div className='flex items-center justify-between'>
            <Link
              href={`/${tree?.url_key || '/'}`}
              className={`text-[13px] uppercase ${active[i] ? 'font-bold' : 'font-normal'}`}
              onClick={() => setSide(false)}
            >
              {tree?.name || ''}
            </Link>
            {tree?.children_data?.length > 0 ? (
              <span onClick={() =>
                setActive((prev) => [
                  ...prev.slice(0, i),
                  !prev[i],
                  ...prev.slice(i + 1, prev.length),
                ])
              }
              className='flex justify-end flex-1'>
                <span>
                {!active[i] ? (
                  <ArrowIcon className='size-2.5 text-[#858585] text-end' />
                ) : (
                  <ArrowIcon className='size-2.5 text-[#858585] rotate-90 text-end' />
                )}
              </span>
              </span>
            ) : null}
          </div>
          {active[i] && (
            <div className='py-1 ps-2'>
              {tree?.children_data.map((sub, j) => (
                <Link
                  href={`/${sub.url_key || '/'}`}
                  key={j}
                  onClick={() => setSide(false)}
                  className='mb-2 flex items-center justify-between text-[#5A5757] first:mt-2 last:mb-0'
                >
                  <span className='text-xs uppercase'>{sub?.name || ''}</span>
                </Link>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Categories;
