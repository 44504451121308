const LocationIcon = (props) => (
  <svg
    width={24}
    height={25}
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_11_3754)'>
      <path
        d="M5.12695 10.2029V10.0314C5.12695 9.13171 5.30473 8.2408 5.65013 7.40957C5.99553 6.57833 6.50179 5.82306 7.14001 5.18686C7.77823 4.55066 8.5359 4.046 9.36977 3.7017C10.2036 3.35739 11.0974 3.18018 12 3.18018C12.9025 3.18018 13.7963 3.35739 14.6301 3.7017C15.464 4.046 16.2217 4.55066 16.8599 5.18686C17.4981 5.82306 18.0044 6.57833 18.3498 7.40957C18.6952 8.2408 18.873 9.13171 18.873 10.0314V10.2029C18.873 13.6529 14.491 18.8464 12.725 20.8002C12.6337 20.9019 12.5219 20.9832 12.397 21.0389C12.2721 21.0945 12.1368 21.1233 12 21.1233C11.8631 21.1233 11.7278 21.0945 11.6029 21.0389C11.478 20.9832 11.3662 20.9019 11.275 20.8002C9.50895 18.8464 5.12695 13.6529 5.12695 10.2029V10.2029Z"
        stroke="currentColor"
        strokeWidth={1.447}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d="M10 10.1131C10 10.6418 10.2107 11.1489 10.5858 11.5228C10.9609 11.8967 11.4696 12.1067 12 12.1067C12.5304 12.1067 13.0391 11.8967 13.4142 11.5228C13.7893 11.1489 14 10.6418 14 10.1131V10.0762C14 9.54744 13.7893 9.04034 13.4142 8.66645C13.0391 8.29257 12.5304 8.08252 12 8.08252C11.4696 8.08252 10.9609 8.29257 10.5858 8.66645C10.2107 9.04034 10 9.54744 10 10.0762"
        stroke="currentColor"
        strokeWidth={1.447}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_11_3754'>
        <rect
          width={24}
          height={23.9241}
          fill='white'
          transform='translate(0 0.189697)'
        />
      </clipPath>
    </defs>
  </svg>
);
export default LocationIcon;
