import { Form, Formik } from 'formik';
import { forgotValidation } from '@/utils/valitation/auth';

import FormField from '../FormField';

const ForgotPassword = ({ t, setAuth }) => {
  const handleLogin = async () => {
    setAuth('Otp');
  };
  return (
    <section className='mt-3 sm:mt-5'>
      <p className='text-center text-sm *:ms-1 sm:text-lg'>
        <span className='font-bold text-primary'>
          {t('Enter your email address')}
        </span>
        <span className='text-[#787272]'>
          ,{' '}
          {t('and we will send you a verification code to your email address.')}
        </span>
      </p>
      <Formik
        onSubmit={handleLogin}
        initialValues={{
          email: '',
        }}
        validationSchema={forgotValidation}
      >
        {({errors, touched}) => (
          <Form>
            <div className='my-2 sm:my-4'>
              <FormField
                inputClassName='w-full rounded-md border p-1.5 max-sm:placeholder:text-sm max-sm:text-sm sm:p-2 outline-none'
                placeholder={t('Email Address')}
                name='email'
                errorClassName='text-red-600 text-sm mt-1 max-sm:text-xs'
                t={t}
                errors={errors}
                touched={touched}
              />
            </div>
            <button className='w-full p-2 font-semibold text-white rounded-md bg-primary max-sm:text-sm'>
              {t('Send')}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default ForgotPassword;
