import React, { useState } from 'react';
import Image from '@/components/Image';

const ConfigurableItem = ({
  t,
  title,
  options,
  onChangeCurrentOption,
  defaultValue = '',
}) => {
  const [active, setActive] = useState(defaultValue);

  return (
    <div className='flex flex-col gap-3'>
      <p className='text-sm font-[500]'>{t(title)}:</p>
      <div className='flex flex-wrap items-center gap-4'>
        {options?.map((option, i) => (
          <button
            disabled={option?.isDisabled}
            onClick={() => {
              setActive(option?.option_id);
              option?.option_id !== defaultValue &&
                onChangeCurrentOption({ [title]: option });
            }}
            className={`overflow-hidden ${option?.type === '1' ? 'rounded-full' : 'rounded-md'} border bg-white disabled:cursor-not-allowed disabled:opacity-50 ${active === option?.option_id ? 'border-primary' : ''} `}
            key={i}
          >
            {configTypeHandler(option)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ConfigurableItem;

const configTypeHandler = (option) => {
  switch (option?.type) {
    case '0':
    case '3':
      return (
        <span className='inline-block  px-3 py-2 text-center text-sm text-primary'>
          {option?.label}
        </span>
      );
    // color text
    case '1':
      return (
        <span
          className={`inline-block size-8`}
          style={{ backgroundColor: option?.value }}
        ></span>
      );
    case '2':
      return (
        <Image
          src={`${process.env.NEXT_PUBLIC_HOME_IMG_BASEURL}/${option?.value}`}
          alt={option?.label}
          className='size-8'
        ></Image>
      );
  }
};
