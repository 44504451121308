import FilterIcon from '../icons/Filter';
import SortByIcon from '../icons/SortBy';

const ResponsiveButtons = ({ setOpen, t }) => {
  return (
    <div className='fixed bottom-20 left-1/2 z-[100] grid w-11/12 -translate-x-1/2 grid-cols-2 gap-4 md:hidden'>
      <button
        onClick={() => setOpen('filter')}
        className='flex items-center justify-center gap-2 py-3 text-xs font-semibold text-white shadow rounded-s-lg bg-primary'
      >
        <FilterIcon />
        <span>{t('All Filters')}</span>
      </button>
      <button
        onClick={() => setOpen('sort')}
        className='flex items-center justify-center gap-2 py-3 text-xs font-semibold text-white shadow rounded-e-lg bg-primary'
      >
        <SortByIcon />
        <span>{t('Sort By')}</span>
      </button>
    </div>
  );
};

export default ResponsiveButtons;
