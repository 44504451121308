import { countDownHandler } from '@/utils/global';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const SpecialOffer = ({ product, fromPromo = false }) => {
  const { locale } = useRouter();
  const [countDown, setCountDown] = useState(
    countDownHandler(product?.prices_with_tax?.discounted_price_to)
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountDown(
        countDownHandler(product?.prices_with_tax?.discounted_price_to)
      );
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <div className='flex flex-col gap-1'>
      {!fromPromo && (
        <p className='text-sm font-[500]'>
          {locale === 'en' ? 'Special Offer' : 'عرض خاص'}:
        </p>
      )}
      <>
        <div
          className={`mb-5 mt-2.5 ${fromPromo ? '' : 'w-1/2 md:w-full lg:w-1/2'} `}
        >
          <div className='mb-1 flex items-center justify-between text-xs'>
            <span className='text-secondary'>
              {locale === 'en' ? 'In Stock' : 'المتاح'} : {product?.stock?.qty}
            </span>
          </div>
          <div className='relative h-2 w-full rounded-full bg-[#F5F5F5]'>
            <span
              className='absolute start-0 top-0 z-50 h-full rounded-full bg-[#479622]'
              style={{ width: `${(product?.stock?.qty / 1000) * 100}%` }}
            ></span>
          </div>
        </div>
        <div className='flex items-center gap-1 sm:gap-2'>
          <div className='flex flex-col items-center justify-center gap-2'>
            <div className='grid size-10 place-items-center rounded-[4px] bg-[#F5F5F5] text-sm font-[500]'>
              {countDown.days || 0}
            </div>
            <span className='text-xs text-secondary'>
              {locale === 'en' ? 'Day' : 'يوم'}
            </span>
          </div>
          <span className='-mt-5'> : </span>
          <div className='flex flex-col items-center justify-center gap-2'>
            <div className='grid size-10 place-items-center rounded-[4px] bg-[#F5F5F5] text-sm font-[500]'>
              {countDown.hours || 0}
            </div>
            <span className='text-xs text-secondary'>
              {locale === 'en' ? 'Hour' : 'ساعة'}
            </span>
          </div>
          <span className='-mt-5'> : </span>
          <div className='flex flex-col items-center justify-center gap-2'>
            <div className='grid size-10 place-items-center rounded-[4px] bg-[#F5F5F5] text-sm font-[500]'>
              {countDown.minutes || 0}
            </div>
            <span className='text-xs text-secondary'>
              {locale === 'en' ? 'Min' : 'دقيقة'}
            </span>
          </div>
          <span className='-mt-5'> : </span>
          <div className='flex flex-col items-center justify-center gap-2'>
            <div className='grid size-10 place-items-center rounded-[4px] bg-[#F5F5F5] text-sm font-[500]'>
              {countDown.seconds || 0}
            </div>
            <span className='text-xs text-secondary'>
              {locale === 'en' ? 'Sec' : 'ثانية'}
            </span>
          </div>
        </div>
      </>
    </div>
  );
};

export default SpecialOffer;
