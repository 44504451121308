// components/ErrorBoundary.js
import React, { useState, useEffect } from 'react';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleErrors = (error, errorInfo) => {
      // You can log the error to an error tracking service here
      console.error('Error caught by ErrorBoundary:', error, errorInfo);
      setHasError(true);
      setError(error);
    };

    // Attach the error event listener
    window.addEventListener('error', handleErrors);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('error', handleErrors);
    };
  }, []);

  if (hasError) {
    // Custom error message UI with Tailwind CSS
    return (
      <div className='flex h-[70vh] items-center justify-center bg-white'>
        <div className='text-center'>
          <h1 className='mb-4 text-3xl font-semibold text-red-700'>
            Something went wrong!
          </h1>
          <p className='text-gray-600'>{error && error.message}</p>
        </div>
      </div>
    );
  }

  // Render the children components if no error
  return <>{children}</>;
};

export default ErrorBoundary;
