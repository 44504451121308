import { ADD_PRODUCT_FEES, addItemToCart, addMultiItemsToCart, createCart } from "@/services/cart"
import { getCookies, setCookie } from "cookies-next"
import toast from "react-hot-toast"

const addICartItem = async (handshake, mage, quote, product, qty,  dispatch, configurations, Context, locale, t) =>{
  const sku = Array.isArray(product?.sku) ? product?.sku?.at(0) : product?.sku

  const { data, error, status } = await addItemToCart(handshake, mage, quote, sku, qty, configurations)
  if (status == 200 && !error) {
      {product?.product_fees.length > 0 &&
        product?.product_fees?.forEach(
          (fee) => {
            Object.values(fee?.options)?.forEach(
              async (option) => {
                const { error } = await ADD_PRODUCT_FEES(data?.quoteId, handshake, {
                  id: option?.fee_id,
                  options: option?.fee_option_id,
                  itemId: data?.data?.item_id,
                })
        
                if (error === null) {
                  
                  toast.success(`${locale === "ar" ? (option?.title_ar || "") + " اضيف بنجاح" : (option?.title || "") + " added successfully"}`);
                } else {
                  toast.error(`${locale === "ar" ? "اضافة " + (option?.title_ar || "") + " فشلت" : "adding " + (option?.title || "") + " has faild"}`);
                }
              }
            )
          }
        )
      }
  }

  if(error) return  toast.error(t(error?.error?.message) || "The product that was requested doesn't exist. Verify the product and try again.")
  if(quote != data?.quoteId){
    dispatch({type:'clear'})
    setCookie('quote', data?.quoteId)
  }
  dispatch({type:'add_item', payload:{...product, ...data?.data, qty: data?.data?.qty }})
  data?.message && toast.success(locale == "en" ? data?.message : "تمت إضافة المنتج للسلة بنجاح!")
  Context?.dispatch({type: 'cartModal'})
  
}

export const handleAddItemToCart = async (product, qty, setLoading, dispatchCart, configurations, Context, locale, t) =>{
  setLoading(true)
  const {handshake, quote, mage} = getCookies()
  await addICartItem(handshake, mage, quote, product, qty, dispatchCart, configurations, Context, locale, t)

  return setLoading(false)
}

// add items to cart

// eslint-disable-next-line no-unused-vars
const addCartItems = async (handshake, mage, quote, products, qty,  dispatch, configurations) =>{
  
  const skus          = products?.map(item => item.sku)
  const {data, error} = await addMultiItemsToCart(handshake, mage, quote, skus)

  console.log('===========================> data', data)

  if(error) return  toast.error(error?.error?.message || "The product that was requested doesn't exist. Verify the product and try again.")
  console.log('quote =====>', quote, data?.quoteId)
  
  if(quote !== data?.quoteId){
    dispatch({type:'clear'})
    setCookie('quote', data?.quoteId)
  }
  
  dispatch({type:'add_bundle', payload:data.data})
  data?.message && toast.success(data?.message)
  
  console.log({skus, data, error})
  
}

export const handleAddItemsToCart = async (products, qty, setLoading, dispatchCart, configurations, context) =>{
  setLoading(true)
  const {handshake, quote, mage} = getCookies()
  // 1 check if user has a cart. If not generate one and save it to cookies
  if(!quote){
    const {data: newCart} = await createCart(handshake, mage)
    // 2 .5 Set the cookie with the id of the new cart in users browser and add item to cart
    if(newCart?.cart_id){
      setCookie("quote", newCart?.cart_id)
      await addCartItems(handshake, mage, newCart?.cart_id, products, qty, dispatchCart, configurations, context)
      return setLoading(false)
    }
    return setLoading(false)
  }
  //If user already has a cart just add the item to their existing cart
  await addCartItems(handshake, mage, quote, products, qty, dispatchCart, configurations, context)
  return setLoading(false)
}