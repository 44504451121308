const PlusIcon = (props) => (
  <svg
    width={14}
    height={14}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 0C7.45256 -2.30244e-08 7.81944 0.366873 7.81944 0.819435L7.81944 13.1806C7.81944 13.6331 7.45256 14 7 14C6.54744 14 6.18056 13.6331 6.18056 13.1806V0.819435C6.18056 0.366873 6.54744 -2.30244e-08 7 0Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 7C14 7.45256 13.6331 7.81944 13.1806 7.81944H0.819435C0.366873 7.81944 -1.6117e-07 7.45256 0 7C-5.98634e-07 6.54744 0.366874 6.18056 0.819435 6.18056H13.1806C13.6331 6.18056 14 6.54744 14 7Z'
      fill='currentColor'
    />
  </svg>
);
export default PlusIcon;
