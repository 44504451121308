import FooterPayments from './FooterPayments';

const CopyRight = ({ t }) => {
  return (
    <section className='container mx-auto flex w-11/12 flex-wrap items-center justify-center gap-4 pb-20 pt-4 md:justify-between lg:pb-4'>
      <div className='flex items-center justify-center gap-3 py-3 text-center text-white max-sm:flex-col sm:py-5'>
        <span className='text-sm'>
          {t('© 2024 MafroshatEl-Omar.All right reserved')}
        </span>
        <span className='max-sm:hidden'> - </span>
        <div>
          <span>{t('Powered by :')}</span>
          <a
            href='https://v4tech.com/'
            target='_blank'
            className='hover:underline'
          >
            V4tech
          </a>
        </div>
      </div>
      <FooterPayments />
    </section>
  );
};

export default CopyRight;
