import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'next-i18next';

const Seo = ({ title, description, image = null }) => {
  const { locale, asPath } = useRouter();
  const { t } = useTranslation('common');
  const img = image || `${process.env.NEXT_PUBLIC_WEBSITE_URL}/alomarlogo.jpg`;
  const url = `${process.env.NEXT_PUBLIC_WEBSITE_URL}${locale === 'ar' ? '' : '/en'}${asPath === '/' ? '' : asPath}`;
  return (
    <Head>
      <title>{title || t('alomar')}</title>
      <link
        rel='canonical'
        href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}${
          locale === 'en' ? '/en' : ''
        }${asPath}`}
      />
      <meta name='title' content={title || t('alomar')} />
      <meta name='description' content={description || t('alomar_desc')} />

      <meta property='og:type' content='website' />
      <meta
        property='og:url'
        content={url || process.env.NEXT_PUBLIC_WEBSITE_URL}
      />
      <meta property='og:title' content={title || t('alomar')} />
      <meta
        property='og:description'
        content={description || t('alomar_desc')}
      />
      <meta property='og:image' content={img} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta
        property='twitter:url'
        content={url || process.env.NEXT_PUBLIC_WEBSITE_URL}
      />
      <meta property='twitter:title' content={title || t('alomar')} />
      <meta
        property='twitter:description'
        content={description || t('alomar_desc')}
      />
      <meta property='twitter:image' content={img} />
    </Head>
  );
};

export default Seo;
