const FooterTiktok = (props) => (
  <svg
    width={35}
    height={36}
    viewBox='0 0 35 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      opacity={0.25}
      d='M33.9412 18.106C33.9412 27.0232 26.6886 34.2575 17.7353 34.2575C8.78205 34.2575 1.52942 27.0232 1.52942 18.106C1.52942 9.18882 8.78205 1.95459 17.7353 1.95459C26.6886 1.95459 33.9412 9.18882 33.9412 18.106Z'
      stroke='currentColor'
      strokeWidth={2}
    />
    <path
      d='M10.88 0.0146484H8.13363V11.1148C8.13363 12.4374 7.07736 13.5238 5.76287 13.5238C4.44838 13.5238 3.39209 12.4374 3.39209 11.1148C3.39209 9.81587 4.42491 8.75306 5.69246 8.70584V5.919C2.89916 5.96622 0.645752 8.25711 0.645752 11.1148C0.645752 13.9962 2.9461 16.3106 5.78635 16.3106C8.62656 16.3106 10.9269 13.9725 10.9269 11.1148V5.42302C11.9597 6.17879 13.2273 6.62752 14.5652 6.65114V3.86428C12.4996 3.79343 10.88 2.09298 10.88 0.0146484Z'
      transform='translate(12, 12) scale(0.75, 0.75)'
      fill='currentColor'
    />
  </svg>
);
export default FooterTiktok;
