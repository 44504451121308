import React, { useMemo, useState } from 'react';
import ConfigurableItem from './ConfigurableItem';

const ConfigurablesComp = ({ t, product, onChangeConfigurableProduct }) => {
  const [ConfigurationOptions, setConfigurationOptions] = useState(
    product?.configurable_options
  );

  const colorConfig = useMemo(
    () => ConfigurationOptions?.find((option) => option?.label === 'color'),
    [ConfigurationOptions]
  );

  const remainingConfigs = useMemo(
    () => ConfigurationOptions?.filter((option) => option?.label !== 'color'),
    [ConfigurationOptions]
  );

  const changeOptionHandler = (option) => {
    // Extract configurable options from the product
    const productConfigurables =
      product?._configurables?.map((item) => item?.options) || [];
    const optionKey = Object.keys(option)?.[0];

    // Group configurable options by the selected attribute
    const groupByAttribute = Object.groupBy(
      productConfigurables,
      (op) => op?.[optionKey]
    );

    // Find the chosen configurable based on the selected option
    const chosenConfigurable =
      groupByAttribute[option[optionKey]?.option_id] || [];

    // Enable or disable options based on the chosen configurable
    const enableDisableOptions =
      product?.configurable_options?.map((item) => {
        if (item?.label === optionKey) {
          return {
            ...item,
            value: item.value.map((el) => ({ ...el, isDisabled: false })),
          };
        } else {
          return {
            ...item,
            value: item.value.map((el) => {
              const isAvailable = chosenConfigurable.some(
                (config) => config?.[item.label] === el?.option_id
              );
              return { ...el, isDisabled: !isAvailable };
            }),
          };
        }
      }) || [];

    // Update the configuration options state
    setConfigurationOptions(enableDisableOptions);

    // Trigger the change event for the configurable product to update the UI with the new product data
    onChangeConfigurableProduct({ ...product?.current_option, ...option });
  };

  return (
    <>
      {colorConfig && (
        <ConfigurableItem
          title={'color'}
          options={colorConfig?.value}
          t={t}
          onChangeCurrentOption={changeOptionHandler}
          defaultValue={product?.current_option?.color?.option_id}
        />
      )}
      {remainingConfigs?.length > 0 && (
        <>
          {remainingConfigs?.map((config, i) => (
            <ConfigurableItem
              key={i}
              t={t}
              title={config?.label}
              options={config?.value}
              onChangeCurrentOption={changeOptionHandler}
              defaultValue={product?.current_option?.[config?.label]?.option_id}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ConfigurablesComp;
