const { useReducer, useEffect, createContext } = require('react');
import browserStorage from 'store';
import { cartReducer } from './reducers';
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const savedSData = browserStorage.get('cart') || { cart: {}, cartItems: [] };
  const [cart, dispatch] = useReducer(cartReducer, {
    ...savedSData,
    cartInfo: [],
  });
  useEffect(() => {
    browserStorage.set('cart', cart);
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};
