import { loginValidation } from '@/utils/valitation/auth';
import { Form, Formik } from 'formik';
import { signIn, getSession } from 'next-auth/react';
import { getCookie, setCookie } from 'cookies-next';
import FormField from '../FormField';
import EyeIcon from '@/components/icons/EyeIcon';
import { useState } from 'react';
import Eyelock from '@/components/icons/Eyelock';

const UserSignin = ({ t, setAuth }) => {
  const handshake = getCookie('handshake');
  const [show, setShow] = useState(false);
  const handleLogin = async (
    { username, password },
    { setSubmitting, setFieldError }
  ) => {
    const res = await signIn('credentials', {
      username,
      password,
      handshake,
      callbackUrl: '/',
      redirect: false,
    });
    if (!res.ok && res.status !== 200) {
      setFieldError('password', t('WrongEmailPassword'));
    } else {
      const session = await getSession();
      if (session?.mage) {
        setCookie('mage', session?.mage, { maxAge: 60 * 60 });
        console.log({ session });
      }
      setAuth('');
      // reset cart and checkout global state
      // merge guest cart if exist
    }
    setSubmitting(false);
  };
  return (
    <section className='mt-3 sm:mt-5'>
      <p className='text-center text-sm *:ms-1 sm:text-lg'>
        <span className='font-bold text-primary'>
          {t('If you have an account')}
        </span>
        <span className='text-[#787272]'>
          , {t('Log in using your email.')}
        </span>
      </p>
      <Formik
        onSubmit={handleLogin}
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={loginValidation}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <div className='my-2 sm:my-4'>
              <FormField
                inputClassName='w-full rounded-md border p-1.5 max-sm:placeholder:text-sm max-sm:text-sm sm:p-2 outline-none'
                placeholder={t('Email Address')}
                name='username'
                errorClassName='text-red-600 text-sm mt-1 max-sm:text-xs'
                t={t}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className='my-2 sm:my-4'>
              <FormField
                inputClassName='w-full rounded-md border p-1.5 max-sm:placeholder:text-sm max-sm:text-sm sm:p-2 outline-none'
                placeholder={t('Password')}
                name='password'
                type={show ? 'text' : 'password'}
                icon={
                  show ? (
                    <EyeIcon
                      onClick={() => setShow(false)}
                      className='absolute end-2 top-1/2 -translate-y-1/2 cursor-pointer text-secondary'
                    />
                  ) : (
                    <Eyelock
                      onClick={() => setShow(true)}
                      className='absolute end-2 top-1/2 size-[18px] -translate-y-1/2 cursor-pointer text-secondary'
                    />
                  )
                }
                errorClassName='text-red-600 text-sm mt-1 max-sm:text-xs'
                t={t}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className='mb-2 flex items-center justify-between max-sm:text-sm sm:mb-4'>
              <div className='flex items-center gap-1.5'>
                <input
                  type='checkbox'
                  id='remember-me'
                  className='size-4 appearance-none rounded-md border checked:bg-primary sm:size-[23px]'
                />
                <label
                  htmlFor='remember-me'
                  className='cursor-pointer text-[#525050]'
                >
                  {t('Remember Me ?')}
                </label>
              </div>
              <button
                type='button'
                onClick={() => setAuth('Forgot Password')}
                className='font-semibold underline'
              >
                {t('Forgot Password?')}
              </button>
            </div>
            <button
              disabled={isSubmitting}
              className='flex w-full justify-center rounded-md bg-primary p-2 font-semibold text-white max-sm:text-sm'
            >
              {isSubmitting ? (
                <span className='size-4 animate-spin rounded-full border-r border-t border-white' />
              ) : (
                t('Login')
              )}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default UserSignin;
