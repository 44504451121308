import Heart from '@/components/icons/HeartIcon';
import useClient from '@/hooks/global/useClient';
import CartIcon from '@/components/icons/CartIcon';
import HomeIcon from '@/components/icons/HomeIcon';
import BurgerMenu from '@/components/icons/BurgerMenu';
import CategoryIcon from '@/components/icons/CategoryIcon';

import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useCart } from '@/context/cart';
import { useWishlist } from '@/context/wishlist';

const FixedBox = ({ setAuth, session }) => {
  const { push } = useRouter();
  const pathname = usePathname();
  const cart = useCart();
  const { isClient } = useClient();
  const { wishlist } = useWishlist();
  const wishlistCount = Object.keys(wishlist)?.length || 0;
  return (
    <section className='fixed bottom-0 left-0 z-[200] flex w-full items-center justify-between bg-white px-3 py-2.5 lg:hidden'>
      <button
        className={`${pathname === '/' ? 'text-primary' : 'text-secondary'}`}
        onClick={() => push('/')}
      >
        <HomeIcon className='size-6 sm:size-7' />
      </button>
      <button
        onClick={() => push('/categories')}
        className={`${
          pathname === '/categories' ? 'text-primary' : 'text-secondary'
        }`}
      >
        <CategoryIcon className='size-6 sm:size-7' />
      </button>
      {isClient && (
        <button
          onClick={() => push('/cart')}
          className={`grid size-12 place-items-center rounded-full border ${
            pathname === '/cart'
              ? 'border-primary text-primary'
              : 'border-zinc-600 text-secondary'
          }`}
        >
          <span className='relative'>
            <CartIcon className='size-6' />
            {cart?.cart?.cartItems?.length > 0 && (
              <span className='absolute -right-1.5 -top-1.5 z-40 grid size-4 place-items-center rounded-full bg-primary text-xs text-white'>
                {cart?.cart?.cartItems?.length}
              </span>
            )}
          </span>
        </button>
      )}
      <button
        className={`${
          pathname === '/user/wishlist' ? 'text-primary' : 'text-secondary'
        } relative`}
        onClick={() => push('/user/wishlist')}
      >
        <Heart className='size-7' />
        {isClient && wishlistCount > 0 && (
          <span className='absolute -right-1 -top-1 z-40 grid size-4 place-items-center rounded-full bg-primary text-xs text-white'>
            {wishlistCount}
          </span>
        )}
      </button>
      <button
        onClick={() => (!session?.user ? setAuth('Login') : push('/user'))}
        className='text-secondary'
      >
        <BurgerMenu className='size-6' />
      </button>
    </section>
  );
};

export default FixedBox;
