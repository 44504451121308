import ExtraAttributes from './ExtraAttributes';
import SpecialOffer from './SpecialOffer';
import PriceRating from './PriceRating';
import QtyWishlist from './QtyWishlist';
import Configurables from './Configurables';
import Share from './Share';
import { countDownHandler } from '@/utils/global';
import InstallmentsWidgets from '@/components/global/InstallmentsWidgets';

const Index = ({
  t,
  fromModal,
  onCloseQuickView,
  product,
  onChangeConfigurableProduct,
  productReviews,
}) => {
  return (
    <div className='col-span-full *:mb-3 md:col-span-1'>
      <h1 className={`${!fromModal ? 'text-2xl' : 'text-[20px]'} font-medium`}>
        {product?.name?.[0]}
      </h1>
      <PriceRating product={product} productReviews={productReviews} />
      <ExtraAttributes product={product} />
      {!fromModal && product?.configurable_options?.length > 0 && (
        <Configurables
          t={t}
          product={product}
          onChangeConfigurableProduct={onChangeConfigurableProduct}
        />
      )}
      <QtyWishlist product={product} onCloseQuickView={onCloseQuickView} />
      {!fromModal && (
        <>
          <InstallmentsWidgets {...{ t, product, fromPDP: true }} />
          <Share t={t} product={product} />
          {product?.prices_with_tax &&
            countDownHandler(
              product?.prices_with_tax?.discounted_price_to,
              product?.prices_with_tax?.discounted_price_from
            )?.isCountDown && (
              <div className='mt-6'>
                <SpecialOffer product={product} />
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default Index;
