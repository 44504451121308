import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import FixedBox from './FixedBox';

import Categories from './categories-bar/CategoriesBar';
import QuickView from '@/components/modals/QuickView';
import CartModal from '@/components/modals/CartModal';

import { useTranslation } from 'next-i18next';
import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { globalModalReducer, initialState } from '@/context/Modal.reducer';
import { useCategoryTree } from '@/hooks/category/useCategoryTree';
import useSearchBox from '@/hooks/search/useSearchBox';
import useClient from '@/hooks/global/useClient';
import Body from '@/components/category/Body';
import EmptyComp from '@/components/global/EmptyComp';
import { mergeGuestCart } from '@/utils/MergeGuestCart';
import { useSession } from 'next-auth/react';
import { useCart } from '@/context/cart';

export const GlobalModalContext = createContext();

const Layout = ({ children }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('common');
  const ourSearchContainer = useRef(null);
  const ourSearchInput = useRef(null);
  const ourSearchButton = useRef(null);
  const { isClient } = useClient();
  const [state, dispatch] = useReducer(globalModalReducer, initialState);
  const [isChangeLang, setIsChangeLang] = useState(false);
  const [open, setOpen] = useState(false);
  const [auth, setAuth] = useState(false);
  const [side, setSide] = useState(false);
  const { data: session } = useSession();
  const { dispatch: dispatchCart } = useCart();

  // merge guest cart to user cart
  useEffect(() => {
    mergeGuestCart(session, dispatchCart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useCategoryTree(isChangeLang, setIsChangeLang);
  const {
    inputValue,
    setInputValue,
    loadingSearch,
    allProducts,
    handleSorting,
    totalProducts,
    selectedFilterKeys,
    setSelectedFilterKeys,
    handleFilter,
    handleResetFilter,
    allFilters,
  } = useSearchBox(ourSearchContainer, ourSearchInput, ourSearchButton);
  const BreadCrumbItems = [
    {
      name: t('Search'),
      url: '/search',
      active: true,
      key: 'search',
    },
  ];

  return (
    <GlobalModalContext.Provider value={{ state, dispatch }}>
      <Header t={t} />
      <Navbar
        {...{
          inputValue,
          setInputValue,
          ourSearchInput,
          ourSearchButton,
          language,
          t,
          isChangeLang,
          setIsChangeLang,
          auth,
          setAuth,
          side,
          setSide,
        }}
      />
      <Categories {...{ t, setAuth }} />
      <div className='flex-grow'>
        {isClient && (
          <div className='mx-auto max-w-[1920px]' ref={ourSearchContainer}>
            {inputValue ? (
              loadingSearch ? (
                <div
                  className={`mt-auto flex h-screen w-full items-center justify-center`}
                >
                  <span className='size-4 animate-spin rounded-full border-r border-t border-black' />
                </div>
              ) : allProducts?.length ? (
                <Body
                  {...{
                    fromCategory: false,
                    language,
                    t,
                    allProducts,
                    handleSorting,
                    totalProducts,
                    selectedFilterKeys,
                    setSelectedFilterKeys,
                    handleFilter,
                    handleResetFilter,
                    allFilters,
                    open,
                    setOpen,
                    BreadCrumbItems,
                  }}
                />
              ) : (
                <EmptyComp
                  {...{
                    title: t('empty search results!'),
                    description: t('try another keyword!'),
                  }}
                />
              )
            ) : (
              <main className='container'>{children}</main>
            )}
          </div>
        )}
      </div>
      <Footer setAuth={setAuth} t={t} />
      <FixedBox {...{ setAuth, session }} />
      {state?.isView ? (
        <QuickView {...{ state, dispatch, t, language }} />
      ) : state?.cartModal ? (
        <CartModal {...{ state, dispatch, t, language }} />
      ) : null}
    </GlobalModalContext.Provider>
  );
};

export default Layout;
