import LocaleSwitcher from '@/components/global/buttons/LocaleSwitcher';
import ArrowIcon from '@/components/icons/ArrowIcon';
import CallIcon from '@/components/icons/CallIcon';
import EmailIcon from '@/components/icons/EmailIcon';
import InfoCircle from '@/components/icons/InfoCircle';
import LocationIcon from '@/components/icons/LocationIcon';
import WhatsAppIcon from '@/components/icons/WhatsAppIcon';
import Categories from '@/components/modals/sideMenu/Categories';
import useBodyScrollLock from '@/hooks/useBodyScrollLock';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import Link from 'next/link';

import { useRef } from 'react';
import { useRouter } from 'next/router';

const SideMenu = ({ setSide, t, language }) => {
  useBodyScrollLock();
  const ref = useRef();
  useOnClickOutside([ref], () => setSide(false));
  const { push } = useRouter();
  return (
    <div
      ref={ref}
      className='max-h-screen min-h-screen w-11/12 overflow-y-auto bg-white'
    >
      <div className='flex items-center justify-between border-b p-3'>
        <p className='text-sm font-[500]'>{t('My Account')}</p>
        <ArrowIcon
          className={`${language === 'en' ? '-rotate-90' : 'rotate-90'} text-[#858585]`}
        />
      </div>
      <Categories locale={language} setSide={setSide} />
      <section className='p-3 *:mb-3.5 last:*:mb-0'>
        <div className='flex items-center gap-3'>
          <InfoCircle className='size-4' />
          <span className='text-sm'>{t('Help')}</span>
        </div>
        <Link
          href='/branches'
          className='flex cursor-pointer items-center gap-3'
        >
          <LocationIcon className='-ms-1 size-5' />
          <span className='text-sm'>{t('Our Branches')}</span>
        </Link>
        <LocaleSwitcher fromSideMenu t={t} language={language} />
        <div className='flex flex-col gap-2'>
          <div className='flex items-center gap-3'>
            <CallIcon className='size-5' />
            <span className='text-sm'>{t('Need Help ?')}</span>
          </div>
          <p className='text-[11px] text-secondary'>{t('NeedHelpInfo')}</p>
          <div className='grid grid-cols-3 gap-2'>
            <div className='flex items-center justify-center gap-1 rounded-sm border p-1 text-xs'>
              <CallIcon className='size-4' />
              <span>{t('Call Us')}</span>
            </div>
            <div className='flex items-center justify-center gap-1 rounded-sm border p-1 text-xs'>
              <WhatsAppIcon />
              <span>{t('Whatsapp')}</span>
            </div>
            <div className='flex items-center justify-center gap-1 rounded-sm border p-1 text-xs'>
              <EmailIcon />
              <span>{t('Email')}</span>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-between'>
          <p className='text-sm font-[500]'>{t('Shipping Policy')}</p>
          <ArrowIcon
            className={`${language === 'en' ? '-rotate-90' : 'rotate-90'} text-[#858585]`}
          />
        </div>
        <div className='flex items-center justify-between'>
          <p className='text-sm font-[500]'>{t('Return & Refund')}</p>
          <ArrowIcon
            className={`${language === 'en' ? '-rotate-90' : 'rotate-90'} text-[#858585]`}
          />
        </div>
        <div
          onClick={() => {
            push('/static/faq');
            setSide(false);
          }}
          className='flex items-center justify-between'
        >
          <p className='text-sm font-[500]'>
            {t('Frequently Asked Questions')}
          </p>
          <ArrowIcon
            className={`${language === 'en' ? '-rotate-90' : 'rotate-90'} text-[#858585]`}
          />
        </div>
      </section>
    </div>
  );
};

export default SideMenu;
