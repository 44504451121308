import CartIcon from '@/components/icons/CartIcon';
import toast from 'react-hot-toast';

import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { useSession } from 'next-auth/react';
import { getCookies } from 'cookies-next';
import { notifyWhenAvailableProduct } from '@/services/product';
import { useCart } from '@/context/cart';
import { GlobalModalContext } from '@/layout';
import { handleAddItemToCart } from '@/utils/product/addToCartFunc';
import { useTranslation } from 'react-i18next';

const AddToCart = ({
  product,
  qty = 1,
  configurations,
  fromPDP = false,
  fromPromo = false,
  onCloseQuickView,
  // fastBuy = false,
}) => {
  const { locale } = useRouter();
  const [loading, setLoading] = useState(false);
  const { handshake } = getCookies();
  const { dispatch } = useCart();
  const Context = useContext(GlobalModalContext);

  const { data: session } = useSession();

  const { t } = useTranslation('common');

  const handleNotifyMe = async (entity_id) => {
    // setLoading(true);
    // setTimeout(() => {
    //   toast.success(
    //     locale === "en" ? "Item Added to Cart" : "تم إضافة المنتج للعربة"
    //   );
    //   Context?.dispatch({ type: "cartModal" });
    //   setLoading(false);
    // }, 1500);

    if (!session?.user) {
      toast.error(
        locale === 'en'
          ? 'Please Login and try again'
          : 'برجاء تسجيل الدخول والمحاولة مره أخرى'
      );
      return;
    }
    setLoading(true);
    setLoading(true);

    const res = await notifyWhenAvailableProduct({
      handshake,
      userId: session?.user?.id,
      entity_id,
    });
    const { error, message } = res?.data?.data?.[0];

    if (error) {
      toast.error(
        message ||
          (locale === 'en'
            ? 'Please Login and try again'
            : 'برجاء تسجيل الدخول والمحاولة مره أخرى')
      );
    } else {
      toast.success(
        message ||
          (locale === 'en'
            ? 'Alert subscription has been saved for this product.'
            : 'تم تسجيل الاشتراك في هذا المنتج.')
      );
    }
    setLoading(false);
    setLoading(false);
  };
  if (fromPromo || fromPDP) {
    return (
      <button
        className={`flex flex-1 items-center justify-center gap-3 rounded-lg border border-primary bg-primary p-2 text-sm font-medium uppercase text-white ${
          fromPDP
            ? 'max-sm:min-w-[200px] max-sm:max-w-[200px] max-sm:flex-initial'
            : ''
        } disabled:cursor-not-allowed disabled:opacity-75`}
        disabled={loading || !product?.stock?.is_in_stock}
        onClick={async () => {
          if (product?.stock?.is_in_stock) {
            await handleAddItemToCart(
              product,
              qty,
              setLoading,
              dispatch,
              configurations,
              Context,
              locale,
              t
            );
            onCloseQuickView && onCloseQuickView();
          } else if (fromPDP) {
            handleNotifyMe(product?.entity_id);
          }
        }}
      >
        {loading && (
          <span className='size-3.5 animate-spin rounded-full border-r border-t border-white' />
        )}
        {product?.stock?.is_in_stock
          ? locale === 'en'
            ? 'Add To Cart'
            : 'أضف للعربة'
          : locale === 'en'
            ? 'Out of Stock'
            : 'غير متوفر'}
      </button>
    );
  }
  return (
    <button
      onClick={() =>
        product?.stock?.is_in_stock
          ? handleAddItemToCart(
              product,
              qty,
              setLoading,
              dispatch,
              configurations,
              Context,
              locale,
              t
            )
          : fromPDP
            ? handleNotifyMe(product?.entity_id)
            : null
      }
      disabled={loading}
      className='flex flex-1 items-center justify-center gap-2 rounded-md border p-1.5 disabled:cursor-not-allowed max-sm:min-w-24 sm:p-1.5'
    >
      <>
        {!loading ? (
          <CartIcon className='size-3 sm:size-4' />
        ) : (
          <span className='size-2.5 animate-spin rounded-full border-r border-t border-primary sm:size-3.5' />
        )}
        <span className='text-[10px] sm:text-sm'>
          {product?.stock?.is_in_stock
            ? locale === 'en'
              ? 'Add To Cart'
              : 'أضف للعربة'
            : locale === 'en'
              ? 'Out of Stock'
              : 'غير متوفر'}
        </span>
      </>
    </button>
  );
};

export default AddToCart;
