import { useEffect, useMemo, useState } from 'react';
import Image from '../Image';
import { Swiper, SwiperSlide } from 'swiper/react';

const Album = ({ fromModal, product }) => {
  const images = useMemo(
    () =>
      product?._media_?.image
        ?.toSorted((a, b) => +a.position - b.position)
        ?.map((img, i) => ({
          ...img,
          id: i + 1,
        })),
    [product]
  );
  const [currentImg, setCurrentImg] = useState(images?.[0]);

  useEffect(() => {
    setCurrentImg(images?.[0]);
  }, [images]);

  return (
    <div className='col-span-full md:col-span-1'>
      <Image
        src={currentImg?.image}
        className='h-auto w-full rounded-md border object-contain sm:max-h-[400px] sm:min-h-[400px]'
        alt='Main Img!'
        loading='eager'
      />
      <div className='mt-3'>
        <Swiper
          spaceBetween={12}
          slidesPerView={4}
          loop={true}
          className='px-0.5'
        >
          {images?.map((item, i) => (
            <SwiperSlide key={i}>
              <Image
                src={item?.image}
                alt={'Swiper Image'}
                loading='eager'
                className={`border ${
                  !fromModal
                    ? 'sm:max-h-[120px] sm:min-h-[120px]'
                    : 'sm:max-h-24 sm:min-h-24'
                } ${item?.image === currentImg?.image && 'border border-primary'} rounded-md object-contain`}
                onClick={() => setCurrentImg(item)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Album;
