const FooterEmail = (props) => (
  <svg
    width={24}
    height={25}
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.81778 19.0648H20.1822C20.2657 19.0648 20.3333 18.9948 20.3333 18.9088V6.71259C20.3333 6.62593 20.2651 6.55549 20.1811 6.55549H3.81778C3.73558 6.55549 3.66667 6.62524 3.66667 6.71259V18.9088C3.66666 18.9292 3.67053 18.9504 3.67807 18.9692C3.68561 18.988 3.69666 19.0051 3.7106 19.0194C3.72454 19.0338 3.74109 19.0452 3.75931 19.053C3.77752 19.0608 3.79807 19.0648 3.81778 19.0648ZM3.81778 20.7848H20.1822C21.1856 20.7848 22 19.9454 22 18.9088V6.71259C22 5.67598 21.1856 4.83545 20.1811 4.83545H3.81778C2.81445 4.83545 2 5.67598 2 6.71259V18.9088C1.99985 19.1552 2.04677 19.3992 2.13807 19.6269C2.22937 19.8546 2.36326 20.0615 2.53209 20.2357C2.70092 20.4099 2.90136 20.5481 3.12197 20.6423C3.34258 20.7365 3.57902 20.785 3.81778 20.7848Z'
      fill='#D6D6D6'
    />
    <path
      d='M3.11902 6.90409L10.813 12.3897C11.152 12.6315 11.5583 12.7617 11.9752 12.762C12.3921 12.7624 12.7986 12.6329 13.138 12.3917L20.876 6.89014'
      stroke='#D6D6D6'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default FooterEmail;
