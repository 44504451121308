import selectedAtteributes from '@/constants/selectedAttrubites';
import axiosRequest from "@/utils/axios-config";

/*
===============================|> All Search api APis <|===============================
01 |--> search Api
02 |--> save search term
===============================|> All Search api APis <|===============================
*/
// 01- =============> get products by category id
// * sortBy can be position | price
// * sortDir can be ASC | DESC
const productsSearch = async (handshake, word, pageSize = 30, pageNo = 0, allSortingKeys = 'sortBy=position&sortDir=ASC', allFilterKeys = "", getFullResponse = true) => {
  const config = {
    method: 'get',
    url: `/search/products/${word}?pageNo=${pageNo}&pageSize=${pageSize}&${allSortingKeys}&${allFilterKeys}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (!getFullResponse) {
    config.url += `&${selectedAtteributes}`;
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};
// 02- =============> save search term
const saveSearchTerm = async (handshake, searchWord) => {
  const config = {
    method: 'get',
    url: `/search/terms/save?q=${encodeURIComponent(searchWord)}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

export { productsSearch, saveSearchTerm };
