import EmailIcon from '@/components/icons/EmailIcon';
import FaceBookIcon from '@/components/icons/FaceBook';
import LinkedInIcon from '@/components/icons/LinkedInIcon';
import XIcon from '@/components/icons/XIcon';
import { useEffect, useState } from 'react';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

const Share = ({ t }) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  return (
    <div className='flex items-center gap-10'>
      <p className='text-sm'>{t('Share')}:</p>
      <div className='flex items-center gap-4 text-[#858585]'>
        <FacebookShareButton url={url}>
          <FaceBookIcon className='size-6 cursor-pointer' />
        </FacebookShareButton>
        <LinkedinShareButton url={url}>
          <LinkedInIcon className='size-6 cursor-pointer' />
        </LinkedinShareButton>
        <TwitterShareButton url={url}>
          <XIcon className='size-5 cursor-pointer' />
        </TwitterShareButton>
        <EmailShareButton url={url}>
          <EmailIcon className='size-6 cursor-pointer' />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default Share;
