import { useRouter } from 'next/router';
import TamaraWidget from '../product/installments/Tamara';
import TabbyWidget from '../product/installments/Tabby';

const InstallmentsWidgets = ({
  fromPDP = false,
  product,
  t,
  cartTotal = 0,
}) => {
  const { locale } = useRouter();
  const total = fromPDP ? product?.prices_with_tax?.price : cartTotal;

  return (
    <div
      className={`grid ${!fromPDP ? 'grid-cols-2' : 'grid-cols-1 md:grid-cols-2'} gap-2 sm:grid-cols-1`}
    >
      {fromPDP && (
        // product?.eligible_for_tabby &&
        <TabbyWidget t={t} locale={locale} total={total} />
      )}
      {fromPDP && (
        //product?.eligible_for_tamara &&
        <TamaraWidget t={t} locale={locale} total={total} />
      )}

      {!fromPDP && (
        <TabbyWidget t={t} locale={locale} total={total} />
      )}
      {!fromPDP && <TamaraWidget t={t} locale={locale} total={total} />}
    </div>
  );
};

export default InstallmentsWidgets;
