import Image from '@/components/Image';
import TabbyModal from '@/components/modals/TabbyModal';
import { useState } from 'react';
const TabbyWidget = ({ total, locale, t }) => {
  const minvalue = process.env.NEXT_PUBLIC_TABBY_MIN_VALUE;
  const maxvalue = process.env.NEXT_PUBLIC_TABBY_MAX_VALUE;
  const numberOfInstallments = process.env.NEXT_PUBLIC_TABBY_INSTALLMENTS;
  const [show, setShow] = useState(false);

  return (
    total >= minvalue &&
    total <= maxvalue && 
    (
      <>
        <div className='flex gap-2 rounded-md border p-2 sm:items-center sm:justify-center'>
          <Image
            loading='lazy'
            src='/imgs/cart/tabby.png'
            className='h-7 w-10 object-contain sm:w-16'
            alt=''
          />
          <p className='text-xs leading-5 sm:text-[15px]'>
          {`${
              locale === "en" ? "Pay in" : "ادفع على"
            } ${numberOfInstallments} ${
              locale === "en"
                ? "interest-free installments"
                : "أقساط بدون فوائد"
            } `}
            <span className='font-bold'>
              {(total / numberOfInstallments).toFixed(2)} {t('SR')}.
            </span>{' '}
            <button
              onClick={() => setShow(true)}
              className='cursor-pointer font-semibold text-zinc-500 underline'
            >
              {locale === 'en' ? 'Find out' : 'إكتشف'}
            </button>
          </p>
        </div>
        <TabbyModal {...{ total, locale, show, setShow, numberOfInstallments }} />
      </>
    )
  );
};

export default TabbyWidget;
