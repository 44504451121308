import { useGlobalData } from '@/context/global';
import CopyRight from './CopyRight';
import FooterLinks from './FooterLinks';
import FooterPayments from './FooterPayments';
import NewsLetter from './NewsLetter';
import FooterCategories from './FooterCategories';
import UserAccountLinks from './UserAccountLinks';
import useClient from '@/hooks/global/useClient';

const Footer = ({ setAuth, t }) => {
  const {
    appData: { menuTreeData },
  } = useGlobalData();

  const topLevelCategories = menuTreeData.filter(
    (category) => category.product_count > 0
  );

  const {isClient} = useClient()

  return (
    <footer className='bg-black text-white'>
      <section className='container border-b border-white border-opacity-30 pb-4'>
        <div className='mx-auto grid w-11/12 grid-cols-1 gap-6 pb-3 pt-6 sm:grid-cols-2 sm:py-12 lg:grid-cols-9'>
          <NewsLetter t={t} />
          <UserAccountLinks title={t('My Account')} setAuth={setAuth} t={t} />
          <FooterCategories title={t('Categories')} data={topLevelCategories} isClient={isClient} />
          <FooterLinks
            title={t('Quick Links')}
            data={[
              {
                url_key: '/static/about',
                name: t('About Us'),
              },
              {
                url_key: '/static/faq',
                name: t('FAQ'),
              },
              {
                url_key: '/static/terms',
                name: t('Terms and Conditions'),
              },
              {
                url_key: '/static/shipping-term',
                name: t('Shipping & Returns'),
              },
              {
                url_key: '/static/contact-us',
                name: t('Contact Us'),
              },
            ]}
          />
          <FooterPayments isResponsive />
        </div>
      </section>
      <CopyRight t={t} />
    </footer>
  );
};

export default Footer;
