const HomeIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z'
      fill='currentColor'
    />
    <path
      d='M17.6009 22.56H6.40094C4.58094 22.56 2.92094 21.16 2.62094 19.37L1.29094 11.4C1.07094 10.16 1.68094 8.57001 2.67094 7.78001L9.60094 2.23001C10.9409 1.15001 13.0509 1.16001 14.4009 2.24001L21.3309 7.78001C22.3109 8.57001 22.9109 10.16 22.7109 11.4L21.3809 19.36C21.0809 21.13 19.3809 22.56 17.6009 22.56ZM11.9909 2.93001C11.4609 2.93001 10.9309 3.09001 10.5409 3.40001L3.61094 8.96001C3.05094 9.41001 2.65094 10.45 2.77094 11.16L4.10094 19.12C4.28094 20.17 5.33094 21.06 6.40094 21.06H17.6009C18.6709 21.06 19.7209 20.17 19.9009 19.11L21.2309 11.15C21.3409 10.45 20.9409 9.39001 20.3909 8.95001L13.4609 3.41001C13.0609 3.09001 12.5209 2.93001 11.9909 2.93001Z'
      fill='currentColor'
    />
  </svg>
);
export default HomeIcon;
