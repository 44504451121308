const MinusIcon = (props) => (
  <svg
    width={15}
    height={2}
    viewBox='0 0 15 2'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 0.75C0 0.335786 0.335786 0 0.75 0H14.25C14.6642 0 15 0.335786 15 0.75C15 1.16421 14.6642 1.5 14.25 1.5H0.75C0.335786 1.5 0 1.16421 0 0.75Z'
      fill='currentColor'
    />
  </svg>
);
export default MinusIcon;
