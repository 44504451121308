import { EmailValidation } from '@/utils/valitation/subscribe';
import { getCookies } from 'cookies-next';
import { Field, Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { subscribeToNewsletter } from '@/services/newsletter';
import NewsLetterIcon from '@/components/icons/NewsLetterIcon';

const EmailSubscribe = ({ t }) => {
  const { handshake, mage } = getCookies();

  return (
    <>
      <h3 className='mb-4 font-bold'>{t('Sign up to Newsletter')}</h3>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={EmailValidation}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const res = await subscribeToNewsletter(
            handshake,
            mage,
            values.email
          );
          if (res.status == 200) {
            !res.data.data.at(0).error
              ? toast.success(res.data.data.at(0).message)
              : toast.error(res.data.data.at(0).message);
          } else {
            toast.error(res.error.error.at(0).message);
          }

          setSubmitting(false);
          resetForm();
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form className='flex items-center gap-3 border-b border-white border-opacity-30 pb-3 sm:w-5/6'>
            <div className='w-full'>
              <Field
                name='email'
                placeholder={t('Email Address')}
                className='w-full bg-[#D6D6D6] py-1 px-2 text-slate-700 outline-none max-sm:text-sm max-sm:placeholder:text-sm sm:py-3'
              />
              {errors.email && touched.email ? (
                <p className='text-sm text-red-600 flex-1'>{t(errors.email)}</p>
              ) : null}
            </div>
            <button type='submit' disabled={isSubmitting} className='hidden'>
              {isSubmitting ? t('Loading...') : t('Subscribe')}
            </button>
            <NewsLetterIcon />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmailSubscribe;
