export function decodeJwt(token, fromServer = true) {
  if (!token) {
    return;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  if (!fromServer) {
    return JSON.parse(window.atob(base64));
  } else {
    return JSON.parse(Buffer.from(base64, 'base64').toString());
  }
}

export const countDownHandler = (toDate, fromDate) => {
  let timeLeft = { isCountDown: false };

  if (fromDate && new Date() <= new Date(fromDate)) {
    return timeLeft;
  }

  const difference = new Date(toDate) - new Date();

  if (difference > 0) {
    timeLeft = {
      isCountDown: true,
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const preventLettersInInput = (event) => {
  const input = event.key;
  // Allow control keys (e.g., backspace, delete)
  if (
    event.ctrlKey ||
    event.altKey ||
    event.metaKey ||
    input === 'Backspace' ||
    input === 'Delete'
  ) {
    return;
  }

  // Check if the input is not a number
  if (isNaN(Number(input))) {
    event.preventDefault();
  }
};

export function compareObjects(obj1, obj2) {
  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate over the keys and compare the values
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // If all keys and values are equal, return true
  return true;
}

export function findObjectByIdV2(data, targetKey, searchValue) {
  if (data?.length) {
    for (const item of data) {
      if (item[targetKey] == searchValue) {
        // prettyConole(searchValue, item)
        return item;
      }

      if (item?.children_data && item?.children_data.length > 0) {
        const result = findObjectByIdV2(
          item?.children_data,
          targetKey,
          searchValue
        );
        if (result) {
          // prettyConole(searchValue, result)
          return result;
        }
      }
    }
  }
  // Target object not found
  return null;
}
