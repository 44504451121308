const NewsLetterIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M3.77783 2L18.0001 2V16.2222' stroke='white' strokeWidth={3} />
    <path d='M18 2L2 18' stroke='white' strokeWidth={3} />
  </svg>
);
export default NewsLetterIcon;
