import { object, ref, string } from 'yup';

export const loginValidation = () =>
  object().shape({
    username: string().email('invalidEmail').required('emailRequired'),
    password: string().required('PasswordRequired'),
  });

export const registerValidation = () =>
  object().shape({
    firstname: string().required('FirstNameRequired'),
    lastname: string().required('LastNameRequired'),
    email: string().email('invalidEmail').required('emailRequired'),
    password: string()
      .min(8, 'HasAtLeast8Characters')
      .matches(/[A-Z]/, 'ContainsOneUppercaseLetter')
      .matches(/[a-z]/, 'ContainsOneLowercaseLetter')
      .matches(/[0-9]/, 'ContainsOneNumber')
      .required('PasswordRequired'),
    confirmPassword: string()
      .oneOf([ref('password'), null], 'PasswordsMustMatch')
      .required('ConfirmPasswordRequired'),
    terms: string().required('YouMustAcceptTerms'),
    mobile: string()
      .label('Phone number')
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, 'telephoneError1')
      .matches(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, 'telephoneError2')
      .min(10)
      .max(10)
      .required('TelephoneRequired'),
  });

export const forgotValidation = () =>
  object().shape({
    email: string().email('invalidEmail').required('emailRequired'),
  });

export const otpValidation = () =>
  object().shape({
    otp: string().required('otpRequired'),
  });

export const changePasswordValidation = () =>
  object().shape({
    password: string()
      .min(8, 'HasAtLeast8Characters')
      .matches(/[A-Z]/, 'ContainsOneUppercaseLetter')
      .matches(/[a-z]/, 'ContainsOneLowercaseLetter')
      .matches(/[0-9]/, 'ContainsOneNumber')
      .required('PasswordRequired'),
    confirmPassword: string()
      .oneOf([ref('password'), null], 'PasswordsMustMatch')
      .required('ConfirmPasswordRequired'),
  });

  export const editProfileValidation = () =>
    object().shape({
      firstname: string().required('FirstNameRequired'),
      lastname: string().required('LastNameRequired'),
      password: string()
        .min(8, 'HasAtLeast8Characters')
        .matches(/[A-Z]/, 'ContainsOneUppercaseLetter')
        .matches(/[a-z]/, 'ContainsOneLowercaseLetter')
        .matches(/[0-9]/, 'ContainsOneNumber')
        .required('PasswordRequired'),
    });
  
  export const changePhoneUserValidation = () =>
    object().shape({
      phone: string()
        .label('Phone number')
        .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, 'telephoneError1')
        .matches(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, 'telephoneError2')
        .min(10)
        .max(10)
        .required('TelephoneRequired'),
      password: string()
        .min(8, 'HasAtLeast8Characters')
        .matches(/[A-Z]/, 'ContainsOneUppercaseLetter')
        .matches(/[a-z]/, 'ContainsOneLowercaseLetter')
        .matches(/[0-9]/, 'ContainsOneNumber')
        .required('PasswordRequired'),
    });

  export const changePasswordUserValidation = () =>
    object().shape({
      password: string()
        .min(8, 'HasAtLeast8Characters')
        .matches(/[A-Z]/, 'ContainsOneUppercaseLetter')
        .matches(/[a-z]/, 'ContainsOneLowercaseLetter')
        .matches(/[0-9]/, 'ContainsOneNumber')
        .required('PasswordRequired'),
      newPassword: string()
        .min(8, 'HasAtLeast8Characters')
        .matches(/[A-Z]/, 'ContainsOneUppercaseLetter')
        .matches(/[a-z]/, 'ContainsOneLowercaseLetter')
        .matches(/[0-9]/, 'ContainsOneNumber')
        .required('NewPasswordRequired'),
      confirmPassword: string()
        .oneOf([ref('newPassword'), null], 'PasswordsMustMatch')
        .required('ConfirmNewPasswordRequired'),
  });
