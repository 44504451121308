const ArrowIcon = (props) => (
  <svg
    width={10}
    height={6}
    viewBox='0 0 10 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.231232 0.231231C0.53954 -0.0770772 1.03941 -0.0770772 1.34772 0.231231L5 3.88352L8.65228 0.231231C8.96059 -0.0770772 9.46046 -0.0770772 9.76877 0.231231C10.0771 0.53954 10.0771 1.03941 9.76877 1.34772L5.55824 5.55824C5.24993 5.86655 4.75007 5.86655 4.44176 5.55824L0.231232 1.34772C-0.0770772 1.03941 -0.0770772 0.53954 0.231232 0.231231Z'
      fill='currentColor'
    />
  </svg>
);
export default ArrowIcon;
