import { priceFormattedObject, validatePrice } from './product';

export const calcCartTotalHandler = (items = []) => {
  return items?.reduce((cur, acc) => {
    const price = validatePrice(priceFormattedObject(acc));
    return (
      cur + ((price?.specialPrice || price?.originalPrice) * acc?.qty || 0)
    );
  }, 0);
};
