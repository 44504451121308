import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

const UserAccountLinks = ({ title, setAuth, t }) => {
  const { push } = useRouter();
  const { data: session } = useSession();
  
  return (
    <div className='lg:col-span-2'>
      <h3 className='font-bold uppercase'>{title}</h3>
      <ul className='mt-4 flex flex-col gap-4'>
          <li className="cursor-pointer opacity-70 max-sm:text-sm" onClick={() => !session ? setAuth("Login") : push("/user")}>{!session? t("Login") : t("My Account")}</li>
          <li className="cursor-pointer opacity-70 max-sm:text-sm" onClick={() => !session ? setAuth("Login") : push("/user/orders") }>{t("My Order")}</li>
          <li className="cursor-pointer opacity-70 max-sm:text-sm" onClick={() => !session ? setAuth("Login") : push("/user/addresses") }>{t("Addresses")}</li>
          <li className="cursor-pointer opacity-70 max-sm:text-sm" onClick={() => !session ? setAuth("Login") : push("/user/wishlist") }>{t('Favourites')}</li>
          <li className="cursor-pointer opacity-70 max-sm:text-sm" onClick={() => push("/cart") }>{t("My Cart")}</li>
      </ul>
    </div>
  );
};

export default UserAccountLinks;