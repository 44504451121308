const InfoCircle = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.77716 19.0538C4.38381 19.0538 0 14.7822 0 9.52688C0 4.27159 4.38381 0 9.77716 0C15.1705 0 19.5543 4.27159 19.5543 9.52688C19.5543 14.7822 15.1705 19.0538 9.77716 19.0538ZM9.77716 1.32933C5.13869 1.32933 1.36426 5.00715 1.36426 9.52688C1.36426 14.0466 5.13869 17.7244 9.77716 17.7244C14.4156 17.7244 18.1901 14.0466 18.1901 9.52688C18.1901 5.00715 14.4156 1.32933 9.77716 1.32933Z"
      fill="currentColor"
    />
    <path
      d="M9.77978 11.0778C9.40689 11.0778 9.09766 10.7765 9.09766 10.4132V5.98205C9.09766 5.6187 9.40689 5.31738 9.77978 5.31738C10.1527 5.31738 10.4619 5.6187 10.4619 5.98205V10.4132C10.4619 10.7765 10.1527 11.0778 9.77978 11.0778Z"
      fill="currentColor"
    />
    <path
      d="M9.77669 13.9575C9.65846 13.9575 9.54022 13.9309 9.43108 13.8866C9.32194 13.8423 9.22189 13.7802 9.13094 13.7005C9.04909 13.6118 8.98542 13.5232 8.93995 13.408C8.89447 13.3017 8.86719 13.1865 8.86719 13.0712C8.86719 12.956 8.89447 12.8408 8.93995 12.7345C8.98542 12.6281 9.04909 12.5306 9.13094 12.442C9.22189 12.3623 9.32194 12.3002 9.43108 12.2559C9.64936 12.1673 9.90402 12.1673 10.1223 12.2559C10.2314 12.3002 10.3315 12.3623 10.4224 12.442C10.5043 12.5306 10.568 12.6281 10.6134 12.7345C10.6589 12.8408 10.6862 12.956 10.6862 13.0712C10.6862 13.1865 10.6589 13.3017 10.6134 13.408C10.568 13.5232 10.5043 13.6118 10.4224 13.7005C10.3315 13.7802 10.2314 13.8423 10.1223 13.8866C10.0132 13.9309 9.89493 13.9575 9.77669 13.9575Z"
      fill="currentColor"
    />
  </svg>
);
export default InfoCircle;
