import SearchIcon from '@/components/icons/SearchIcon';
import { useState } from 'react';

const Search = ({
  inputValue,
  setInputValue,
  ourSearchInput,
  ourSearchButton,
  t,
}) => {
  const [open, setOpen] = useState(false);
  // const [search, setSearch] = useState(false);
  // const [focused, setFocused] = useState(false);
  return (
    <>
      <SearchIcon
        onClick={() => setOpen(!open)}
        className='hidden max-sm:block'
      />
      <div
        // className={`${
        //   focused ? "border border-black" : "border"
        // } p-2.5 max-sm:p-1.5 rounded-xl ${
        //   search ? "flex" : "hidden"
        //     } max-lg:order-3 max-lg:w-full lg:flex items-center gap-2 flex-1`}

        className={`items-center sm:flex-1 ${
          open ? 'flex w-full' : 'hidden'
        } gap-3 sm:flex`}
      >
        <input
          ref={ourSearchInput}
          value={inputValue}
          placeholder={t('What are you looking for?')}
          // onBlur={() => setFocused(false)}
          // onFocus={() => setFocused(true)}
          className='flex-1 rounded-md border p-1.5 outline-none max-sm:text-sm max-sm:placeholder:text-sm sm:h-11 sm:p-2.5'
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button
          ref={ourSearchButton}
          className='rounded-md bg-primary px-6 py-2 text-sm text-white max-sm:hidden sm:py-3'
        >
          {t('Search')}
        </button>
      </div>
    </>
  );
};

export default Search;
