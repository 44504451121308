import Modal from '.';
import Image from '../Image';
import Remove from '../icons/Remove';
import PlusIcon from '../icons/PlusIcon';
import CloseIcon from '../icons/CloseIcon';
import ProductCard from '../cards/ProductCard';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import useBodyScrollLock from '@/hooks/useBodyScrollLock';

import { useEffect, useRef, useState } from 'react';
import { useCart } from '@/context/cart';
import { priceFormattedObject, validatePrice } from '@/helpers/product';
import { getCookies, setCookie } from 'cookies-next';
import { deleteCartItem, updateCartItem } from '@/services/cart';
import toast from 'react-hot-toast';
import MinusIcon from '../icons/MinusIcon';
import { getProductsBySkus } from '@/services/product';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { handleLinkByType } from '@/helpers/common/handleLinkByType';

const CartModal = ({ state, dispatch, t, language: locale }) => {
  const cart = useCart();
  const modalRef = useRef();
  const { push } = useRouter();
  const [delLoading, setDelLoading] = useState(false);
  const [updLoading, setUpdLoading] = useState(false);
  const { handshake, mage, quote } = getCookies();
  const [relatedProducts, setRelatedProducts] = useState([]);
  useOnClickOutside([modalRef], () => dispatch({ type: 'closeCartModal' }));
  useBodyScrollLock();
  const handleDeleteCartItem = async (itemData) => {
    setDelLoading(true);
    const res = await deleteCartItem(handshake, mage, quote, itemData?.item_id);

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie('quote', res?.data?.quoteId);
      cart?.dispatch({ type: 'clear' });
    }

    if (res?.status === 200) {
      toast.success(
        locale === 'en' ? 'Item Deleted From Cart!' : 'تم مسح المنتج من العربة'
      );
      cart?.dispatch({
        type: 'remove',
        payload: { item_id: itemData?.item_id || itemData?.data?.item_id },
      });
      setDelLoading(false);
    } else {
      toast.error(
        locale === 'en'
          ? 'Error Occured, please try again'
          : 'حدث خطأ, برجاء المحاولة مره اخرى'
      );
      setDelLoading(false);
    }
  };
  const handleUpdateItem = async (qty, itemData) => {
    setUpdLoading(true);
    const res = await updateCartItem(
      handshake,
      mage,
      quote,
      itemData?.item_id,
      qty
    );

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie('quote', res?.data?.quoteId);
      cart?.dispatch({ type: 'clear' });
    }

    if (res?.status === 200) {
      toast.success(
        locale === 'en'
          ? 'Item Updated Successfully'
          : 'تم تعديل كمية المنتج بنجاح'
      );

      // handle related products
      // await getRelatedProducts(dispatchCart, itemData);

      cart?.dispatch({
        type: 'update',
        payload: { item_id: itemData?.item_id || itemData?.data?.item_id, qty },
      });
      setUpdLoading(false);
      // fromCartPage && refetchCart();
    } else {
      toast.error(
        locale === 'en'
          ? 'Error Occured, please try again'
          : 'حدث خطأ, برجاء المحاولة مره اخرى'
      );
      setUpdLoading(false);
    }
  };
  const handleFetchRelatedProducts = async () => {
    const res = await getProductsBySkus(
      handshake,
      cart?.cart?.cartItems?.at(-1)?.custom_related_products
    );
    if (res?.status === 200) {
      setRelatedProducts(res?.data?.data?.products || []);
    }
  };
  useEffect(() => {
    if (
      Array.isArray(cart?.cart?.cartItems?.at(-1)?.custom_related_products) &&
      cart?.cart?.cartItems?.at(-1)?.custom_related_products?.length > 0
    ) {
      handleFetchRelatedProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.cart?.cartItems]);
  return (
    <Modal show={state.cartModal}>
      <div
        ref={modalRef}
        className='gray-scroll max-h-screen w-full overflow-y-auto bg-white p-4 max-sm:absolute max-sm:bottom-0 max-sm:max-h-[70vh] max-sm:rounded-t-xl sm:min-h-screen sm:w-[623px]'
      >
        <div className='mb-3 flex items-center justify-between border-b pb-2'>
          <p className='text-[13px] font-bold text-[#039A12] sm:text-xl'>
            {t('Successfuly added to your Cart')}
          </p>
          <button onClick={() => dispatch({ type: 'closeCartModal' })}>
            <CloseIcon className='size-5' />
          </button>
        </div>
        <section className='gray-scroll my-3 max-h-[25vh] overflow-y-auto sm:max-h-[400px]'>
          {cart?.cart?.cartItems?.toReversed().map((item, i) => {
            const price = validatePrice(priceFormattedObject(item));

            return (
              <section
                key={i}
                className='mb-3 flex gap-3 rounded-md border p-2 last:mb-0 sm:p-3'
              >
                <Image
                  loading='lazy'
                  src={
                    item?._media_?.image?.[0]?.image ||
                    `${process.env.NEXT_PUBLIC_IMG_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`
                  }
                  className='h-auto w-20 rounded-md border object-cover sm:size-24'
                  alt='Cart Modal Img!'
                />
                <div className='flex flex-1 flex-col gap-1 sm:gap-2'>
                  <Link href={handleLinkByType(item?.rewrite_url, 'product')} className='line-clamp-2 text-[10px] font-semibold sm:text-[17px] sm:font-[500]'>
                    {item?.name || ''}
                  </Link>
                  <p className='text-[9px] text-secondary sm:text-sm'>
                    {item?._category_?.main_category?.name || ''}
                  </p>
                  <div className='mt-1.5 flex items-center justify-between'>
                    <div className='flex items-center max-sm:order-last'>
                      <button
                        disabled={delLoading || updLoading}
                        onClick={() =>
                          item?.qty === 1
                            ? handleDeleteCartItem(item)
                            : handleUpdateItem(item?.qty - 1, item)
                        }
                        className='grid size-5 place-items-center rounded-sm border disabled:cursor-not-allowed sm:size-6'
                      >
                        {item?.qty === 1 ? (
                          <Remove className='size-3 sm:size-4' />
                        ) : (
                          <MinusIcon className='size-2 sm:size-3' />
                        )}
                      </button>
                      <span className='grid h-5 w-9 place-items-center max-sm:text-xs sm:h-6'>
                        {item?.qty}
                      </span>
                      <button
                        disabled={updLoading}
                        onClick={() =>
                          handleUpdateItem(item?.qty ? item?.qty + 1 : 2, item)
                        }
                        className='grid size-5 place-items-center rounded-sm border disabled:cursor-not-allowed sm:size-6 '
                      >
                        <PlusIcon className='size-2 sm:size-3' />
                      </button>
                    </div>
                    <div className='flex items-center gap-1 sm:gap-3'>
                      <p className='font-bold max-sm:text-xs'>
                        {price?.specialPrice || price?.originalPrice}{' '}
                        {locale === 'en' ? 'SAR' : 'ر.س'}
                      </p>
                      {!!price?.specialPrice && (
                        <p className='text-xs text-[#B2B2B2] line-through max-sm:text-[8px]'>
                          {price?.originalPrice}{' '}
                          {locale === 'en' ? 'SAR' : 'ر.س'}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
        </section>
        <Image
          loading='lazy'
          src='/imgs/tamara-cart-modal.png'
          className={`block h-auto w-full rounded-md object-contain ${relatedProducts?.length === 0 ? 'mb-10' : 'mb-0'}`}
          alt='Cart Modal Img!'
        />
        {relatedProducts?.length > 0 && (
          <>
            <h4 className='my-3 font-bold max-sm:text-sm'>
              {t('People who bought this also bought')}
            </h4>
            <section className='mb-10'>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  320: { slidesPerView: 2.2 },
                  640: { slidesPerView: 3 },
                }}
              >
                {relatedProducts.map((product, i) => (
                  <SwiperSlide key={i}>
                    <ProductCard product={product} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          </>
        )}
        <div className='fixed bottom-0 start-1 z-50 grid w-[97%] grid-cols-2 gap-2 bg-white py-2 sm:start-2 sm:w-[593px]'>
          <button
            onClick={() => {
              push('/cart');
              dispatch({ type: 'closeCartModal' });
            }}
            className='rounded-md border border-primary bg-primary p-1.5 font-semibold text-white max-sm:text-xs'
          >
            {t('Go to cart page')}
          </button>
          <button
            onClick={() => dispatch({ type: 'closeCartModal' })}
            className='rounded-md border border-primary bg-white p-1.5 font-semibold text-primary max-sm:text-xs'
          >
            {t('Continue Shopping')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CartModal;
