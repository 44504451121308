import useBodyScrollLock from '@/hooks/useBodyScrollLock';
import useHandleOutSide from '@/hooks/useHandleOutSide';
import CategoryOptions from './CategoryOptions';
import CloseIcon from '../icons/CloseIcon';
import FilterIcon from '../icons/Filter';

import { useRef } from 'react';

const FilterModal = ({
  filterList: allFilters,
  t,
  selectedFilterKeys,
  setOpen,
  handleFilter,
  handleResetFilter,
}) => {
  const ref = useRef();
  useHandleOutSide(ref, () => setOpen(false));
  useBodyScrollLock();
  return (
    <div
      ref={ref}
      className='fixed bottom-0 w-screen max-h-screen overflow-y-auto bg-white'
    >
      <div className='flex items-center justify-between p-3 border-b'>
        <div className='flex items-center gap-2'>
          <FilterIcon />
          <span className='text-sm font-bold'>{t('All Filters')}</span>
        </div>
        <CloseIcon
          onClick={() => setOpen(false)}
          className='cursor-pointer size-4'
        />
      </div>
      <CategoryOptions
        {...{
          filterList: allFilters,
          isModal: true,
          t,
          selectedFilterKeys,
          handleFilter,
        }}
        isModal
      />
      <div className='grid grid-cols-2 gap-4 p-3'>
        <button
          onClick={() => setOpen(false)}
          className='rounded-md border border-primary bg-primary py-1.5 text-[13px] font-bold text-white'
        >
          {t('Show Results')}
        </button>
        <button
          onClick={handleResetFilter}
          className='rounded-md border border-primary py-1.5 text-[13px] font-bold text-primary'
        >
          {t('Reset Filter')}
        </button>
      </div>
    </div>
  );
};

export default FilterModal;
