import Modal from '.';
import Album from '../product/Album';
import Details from '../product/Details';
import CloseIcon from '../icons/CloseIcon';
import useHandleOutSide from '@/hooks/useHandleOutSide';
import useBodyScrollLock from '@/hooks/useBodyScrollLock';

import { useRef } from 'react';

const QuickView = ({ state, dispatch, t }) => {
  const modalRef = useRef();
  const onCloseQuickView = () => {
    dispatch({ type: 'view', payload: undefined });
  };
  useHandleOutSide(modalRef, () => onCloseQuickView());
  useBodyScrollLock();

  return (
    <Modal isCenter show={state.isView}>
      <div ref={modalRef} className='w-[991px] rounded-md bg-white p-4'>
        <div className='mb-3 flex justify-end'>
          <button onClick={() => onCloseQuickView()}>
            <CloseIcon className='size-5' />
          </button>
        </div>
        <div className='grid grid-cols-2 gap-6'>
          <Album product={state?.payload || null} fromModal />
          <Details
            t={t}
            fromModal
            onCloseQuickView={onCloseQuickView}
            product={state?.payload || null}
          />
        </div>
      </div>
    </Modal>
  );
};

export default QuickView;
