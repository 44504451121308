// components
import Modal from '@/components/modals';
import ProductCard from '@/components/cards/ProductCard';
import FilterModal from '@/components/category/FilterModal';
import SortByModal from '@/components/category/SortByModal';
import BreadCrumb from '@/components/global/buttons/BreadCrumb';
import CategoryOptions from '@/components/category/CategoryOptions';
import ResponsiveButtons from '@/components/category/ResponsiveButtons';
import Seo from '@/components/seo';

import {
  // useContext,
  // useState
  useMemo,
} from 'react';
// import CompareContext from "@/context/compare/compare-context";
import { useGlobalData } from '@/context/global';
import { findObjectByIdV2 } from '@/utils/global';

const Body = ({
  handleFilter,
  handleResetFilter,
  allFilters,
  selectedFilterKeys,
  allProducts,
  open,
  setOpen,
  handleSorting,
  categoryID,
  BreadCrumbItems,
  t,
}) => {
  const {
    appData: { categoryData },
  } = useGlobalData();
  const currentCategory = useMemo(() => {
    return categoryID
      ? findObjectByIdV2(categoryData?.children_data, 'id', categoryID)
      : null;
  }, [categoryData?.children_data, categoryID]);
  return (
    <main className='container p-0'>
      <Seo
        title={currentCategory?.meta_title || currentCategory?.name}
        description={currentCategory?.meta_description}
      />
      <BreadCrumb data={BreadCrumbItems} />
      <section className=' mx-auto my-3 sm:my-5'>
        <h1 className='mb-4 text-sm font-bold sm:text-lg md:hidden'>
          {t('Category')}
        </h1>
        <div className='grid grid-cols-12 gap-5'>
          <CategoryOptions
            {...{
              filterList: allFilters,
              t,
              selectedFilterKeys,
              handleFilter,
              handleResetFilter,
            }}
          />
          <div className='col-span-full grid grid-cols-2 gap-4 min-[420px]:grid-cols-3 sm:gap-5 md:col-span-9 lg:grid-cols-4'>
            {allProducts?.map((product, i) => (
              <ProductCard key={i} product={product} />
            ))}
            <div id='afterProductsSection' className='w-full'></div>
          </div>
          <ResponsiveButtons t={t} setOpen={setOpen} />
        </div>
      </section>
      <Modal show={open}>
        {open === 'sort' ? (
          <SortByModal {...{ handleSorting, setOpen, t }} />
        ) : (
          <FilterModal
            {...{
              filterList: allFilters,
              t,
              selectedFilterKeys,
              setOpen,
              handleFilter,
              handleResetFilter,
            }}
          />
        )}
      </Modal>
    </main>
  );
};

export default Body;
