import StarsRateIcon from '@/components/icons/StarRateIcon';
import { validatePrice } from '@/helpers/product';
import { useRouter } from 'next/router';

const PriceRating = ({ product, productReviews }) => {
  const { locale } = useRouter();

  const ratingScore = productReviews?.length
    ? productReviews
        ?.map(
          (r) =>
            r?.ratings?.find((el) => el.rating_name === 'Value')?.value || 5
        )
        ?.reduce((a, b) => a + b) / productReviews?.length
    : 0;

  const price = validatePrice(product?.prices_with_tax);

  return (
    <>
      <div className='flex items-center gap-3'>
        <StarsRateIcon width={25} height={25} rate={ratingScore || 0} />
        <span className='text-xs text-secondary'>
          ({productReviews?.length || 0} {locale === 'en' ? 'rates' : 'تقييم'})
        </span>
      </div>
      <div className='flex items-center gap-3'>
        <p className='text-xl font-medium'>
          {price?.specialPrice || price?.originalPrice}{' '}
          {locale === 'en' ? 'SAR' : 'ر.س'}
        </p>
        {!!price?.specialPrice && (
          <p className='font-medium text-zinc-500 line-through'>
            {price?.originalPrice} {locale === 'en' ? 'SAR' : 'ر.س'}
          </p>
        )}
      </div>
    </>
  );
};

export default PriceRating;
