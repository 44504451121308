import EmptyCartIcon from "../icons/EmptyCartIcon";

const EmptyComp = ({ title, description, icon = <EmptyCartIcon className="w-80 h-auto"/>, fromCart }) => {
  return (
    <div
      style={{ height: !fromCart ?  "calc(100vh - 237px)" : "100%" }}
      className="flex items-center flex-col gap-2 p-4 justify-center"
    >
      <div className="w-72 md:w-auto flex justify-start items-center">
        {icon || ""}
      </div>
      {title && <p className="font-bold text-xl sm:text-2xl mt-9 mb-2">{title}</p>}
      {description && (
        <p className="text-zinc-500 max-w-[642px] text-center">{description}</p>
      )}
    </div>
  );
};

export default EmptyComp;
