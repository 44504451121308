import { getProductsByCategoryId } from "@/services/category";
import { productsSearch } from "@/services/search";
import { getCookie } from "cookies-next";
import { useCallback, useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

const useInfiniteProducts = ({
  products,
  categoryID,
  fromCategory = true,
  word = "",
}) => {
  const [allProducts, setAllProducts] = useState(products || []);

  const [pageIndex, setPageIndex] = useState(1);
  const [allFilterKeys, setAllFilterKeys] = useState("");
  const [allSortingKeys, setAllSortingKeys] = useState(
    "sortBy=position&sortDir=ASC"
  );
  const [moreProductsIsLoading, setMoreProductsIsLoading] = useState(false);
  const handshake = getCookie("handshake");
  const { cache, mutate } = useSWRConfig();

  const res = useSWRMutation(
    fromCategory
      ? `/api/category/pages/getMoreProducts?pageIdx=${pageIndex}&categoryID=${categoryID}&${allSortingKeys}&${allFilterKeys}`
      : `/api/search?word=${word}&pageNo=${pageIndex}&pageSize=30&${allSortingKeys}&${allFilterKeys}`,
    async (_, { arg }) => {
      const { pageNo,
        pageSize,
        categoryID,
        filterKeys,
        sortKeys, } = arg;
      const { data: productsData, error: productsError } = fromCategory
        ? await getProductsByCategoryId(
            handshake,
            categoryID,
            sortKeys,
            filterKeys,
            pageSize,
            pageNo
          )
        : await productsSearch(
            handshake,
            word,
            pageSize,
            pageNo,
            sortKeys,
            filterKeys
          );

      let handleProducts = {
        products: productsData?.data?.products,
        aggregate: productsData?.data?.aggregate,
        pages: productsData?.data?.pages,
        pageParam: +pageNo,
        error: productsError,
      };
      return handleProducts;
    },
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  useEffect(() => {
    setAllProducts(products);
    setMoreProductsIsLoading(false);
    setPageIndex(1);
    setAllFilterKeys("");
    setAllSortingKeys("sortBy=position&sortDir=ASC");
  }, [products]);

  const handleGettingProductsAndCaching = useCallback(
    async (url_cach) => {
      setMoreProductsIsLoading(true);
      setPageIndex((prev) => prev + 1);

      let result = await res?.trigger(
        fromCategory
          ? {
              pageNo: pageIndex,
              pageSize: 30,
              categoryID,
              filterKeys: allFilterKeys,
              sortKeys: allSortingKeys,
            }
          : {
              pageNo: pageIndex,
              pageSize: 30,
              word,
              filterKeys: allFilterKeys,
              sortKeys: allSortingKeys,
            }
      );

      mutate({
        [url_cach]: result,
      });

      result?.products?.length &&
        result?.products != undefined &&
        setAllProducts((prev) => [...prev, ...result?.products]);

      if (result?.products != undefined && Array.isArray(result?.products)) {
        if (result?.products?.length < 30) {
          setMoreProductsIsLoading(true);
        } else {
          setMoreProductsIsLoading(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [allFilterKeys, allSortingKeys, categoryID, fromCategory, mutate, pageIndex, res, word]
  );

  useEffect(() => {
    if (!moreProductsIsLoading && !res?.isMutating) {
      const target = document.getElementById("afterProductsSection");
      const url_cach = fromCategory
        ? `/api/category/pages/getMoreProducts?pageIdx=${pageIndex}&categoryID=${categoryID}&${allSortingKeys}&${allFilterKeys}`
        : `/api/search?word=${word}&pageNo=${pageIndex}&pageSize=30&${allSortingKeys}&${allFilterKeys}`;
      const observer = new IntersectionObserver(
        async (entries) => {
          if (entries[0].isIntersecting) {
            if (!(pageIndex > res?.data?.pages) && !cache.get(url_cach)) {
              // getting products for this category if this category not have data in the cach and page index stil smaller than all pages in the backend
              handleGettingProductsAndCaching(url_cach);
            } else {
              // if there are data in the cach
              if (
                cache &&
                cache.get(url_cach)?.data?.products &&
                cache.get(url_cach)?.data?.products?.length
              ) {
                // get products from the cach
                setPageIndex((prev) => prev + 1);
                setAllProducts((prev) => [
                  ...prev,
                  ...(cache.get(url_cach)?.data?.products || []),
                ]);
              } else {
                // if this category not have data in the cach
                handleGettingProductsAndCaching(url_cach);
              }
            }
          }
        },
        { threshold: 1.0 }
      );
      target && observer.observe(target);

      return () => observer.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageIndex,
    moreProductsIsLoading,
    cache,
    mutate,
    res,
    categoryID,
    handleGettingProductsAndCaching,
    allSortingKeys,
    allFilterKeys,
    word,
  ]);

  return {
    allProducts,
    setAllProducts,
    setAllFilterKeys,
    allFilterKeys,
    allSortingKeys,
    setAllSortingKeys,
    setPageIndex,
  };
};

export default useInfiniteProducts;
