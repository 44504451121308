import ArrowIcon from '@/components/icons/ArrowIcon';
import BurgerList from '@/components/icons/BurgerList';
import { modifyMenuTreeData } from '@/helpers/category';
import Link from 'next/link';
import React, { useState } from 'react';
import CategoryList from './CategoryList';
import { useRouter } from 'next/router';

const CategoriesDropdown = ({ t, menuTreeData }) => {
  const [open, setOpen] = useState(false);
  const { locale } = useRouter();

  return (
    <div className='relative'>
      <button
        onClick={() => setOpen((prev) => !prev)}
        className='flex w-60 cursor-pointer items-center gap-12'
      >
        <div className='flex items-center gap-3'>
          <BurgerList />
          <span className='font-[500]'>{t('Categories')}</span>
        </div>
        <ArrowIcon className='mt-2 text-zinc-500' />
      </button>

      {open && (
        <>
          <div
            onClick={() => setOpen(false)}
            className='fixed left-0 top-0 z-[9000] h-screen w-screen bg-black bg-opacity-60'
          ></div>
          <ul className='absolute right-0 top-8 z-[10000] flex w-64 flex-col gap-2 rounded-b-md  border bg-white p-2 shadow-categories'>
            {menuTreeData?.map((cat, i) => {
              const parentUrl = `/${cat?.url_key}`;
              const modifiedData = modifyMenuTreeData(cat?.children_data);

              return (
                <li
                  key={i}
                  className='group border border-transparent text-sm font-medium hover:rounded-md hover:border-primary '
                >
                  <Link
                    className='flex items-center justify-between px-4 py-2'
                    href={parentUrl}
                    onClick={() => setOpen(false)}
                  >
                    <span>{cat?.name}</span>
                    <ArrowIcon
                      className={locale === 'en' ? '-rotate-90' : 'rotate-90'}
                    />
                  </Link>

                  {modifiedData?.length > 0 && (
                    <CategoryList
                      children_data={modifiedData}
                      parentUrl={parentUrl}
                      currentCat={cat}
                      setOpen={setOpen}
                    />
                  )}
                </li>
              );
            })}
            <li className='group'>
              <Link
                className={`block rounded-md border border-primary bg-primary p-2 text-center text-sm font-medium text-white`}
                href='/categories'
                onClick={() => setOpen(false)}
              >
                {t('All Categories')}
              </Link>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default CategoriesDropdown;
