import BurgerMenu from '@/components/icons/BurgerMenu';
import HeartIcon from '@/components/icons/HeartIcon';
import AuthModal from '@/components/modals/AuthModal';
import useClient from '@/hooks/global/useClient';
import Logo from '@/components/icons/Logo';
import Modal from '@/components/modals';
import CartHeader from './CartHeader';
import SideMenu from './SideMenu';
import Search from './Search';
import Link from 'next/link';

import { useWishlist } from '@/context/wishlist';

const Navbar = ({
  inputValue,
  setInputValue,
  ourSearchInput,
  ourSearchButton,
  language,
  t,
  isChangeLang,
  setIsChangeLang,
  auth,
  setAuth,
  side,
  setSide,
}) => {
  const { isClient } = useClient();
  const { wishlist } = useWishlist();
  const wishlistCount = Object.keys(wishlist)?.length || 0;

  return (
    <navbar>
      <section className='container mx-auto flex w-11/12 items-center justify-between gap-x-8 gap-y-2 py-1.5 max-sm:flex-wrap sm:py-5 md:gap-14'>
        <div className='flex items-center gap-5'>
          <BurgerMenu
            onClick={() => setSide(true)}
            className='hidden max-sm:block'
          />
          <Link href='/'>
            <Logo className='max-md:w-32' />
          </Link>
        </div>
        <Search
          {...{
            inputValue,
            setInputValue,
            ourSearchInput,
            ourSearchButton,
            t,
            isChangeLang,
            setIsChangeLang,
          }}
        />
        <div className='hidden items-center gap-7 sm:flex'>
          <Link href={'/user/wishlist'} className='relative'>
            <HeartIcon className='size-7' />
            {isClient && wishlistCount > 0 && (
              <span className='absolute -right-2 -top-2 z-30 flex size-5 items-center justify-center rounded-full bg-[#58B18E] text-xs text-white'>
                {wishlistCount}
              </span>
            )}
          </Link>
          <CartHeader t={t} locale={language} />
        </div>
      </section>
      <Modal show={side || auth} isCenter={auth}>
        {side && <SideMenu t={t} setSide={setSide} language={language} />}
        {auth && <AuthModal auth={auth} setAuth={setAuth} />}
      </Modal>
    </navbar>
  );
};

export default Navbar;
