import { useRef } from 'react';
import Modal from '@/components/modals';
import CloseIcon from '../icons/CloseIcon';
import Image from '../Image';
import useHandleOutSide from '@/hooks/useHandleOutSide';

const TabbyModal = ({ show, setShow, locale, total, numberOfInstallments }) => {
  const ref = useRef();
  useHandleOutSide(ref, () => setShow(false));

  return (
    <Modal show={show} isCenter>
      <div
        ref={ref}
        className='h-[80vh] gray-scroll fixed left-1/2 top-1/2 z-50 w-[90vw] -translate-x-1/2 -translate-y-1/2 overflow-hidden overflow-y-auto rounded-3xl bg-white shadow-lg md:h-[614px] md:w-8/12'
      >
        <CloseIcon
          onClick={() => setShow(false)}
          className='absolute start-4 top-4'
        />
        <div className='my-2 text-center'>
          <Image
            loading='lazy'
            src='/imgs/cart/tabby.png'
            className='mx-auto mt-11'
            alt=''
          />
          <h2 className='text-xl font-bold'>
            {locale === 'en'
              ? `Split in ${numberOfInstallments} interest-free payments of ${total} SAR.`
              : `أو قسمها على ${numberOfInstallments} دفعات بدون فوائد بقيمة ${total} ر.س.`}
          </h2>
          <p className='text-zinc-600'>
            {locale === 'en'
              ? 'Get what you love, split the cost. No fees.'
              : 'اشترِ ما تحب، وقسّم المبلغ. بدون رسوم.'}
          </p>
        </div>

        <div className='my-4 grid grid-cols-4'>
          <div className=''>
            <div className='relative mx-auto h-[50px] w-[50px] rounded-full border border-zinc-700'>
              <div
                className={`absolute top-0 h-1/2 w-1/2 rounded-tr-full bg-zinc-700 ${
                  locale === 'en' ? 'end-0 start-auto' : 'end-auto start-0'
                }`}
              />
            </div>
            <h5 className='text-center font-semibold'>
              {total / numberOfInstallments} {locale === 'en' ? 'SAR' : 'ر.س'}
            </h5>
            <h6 className='text-center text-sm text-zinc-500'>
              {locale === 'en' ? 'Today' : 'اليوم'}
            </h6>
          </div>
          <div className=''>
            <div className='relative mx-auto h-[50px] w-[50px] rounded-full border border-zinc-700 p-0.5'>
              <div
                className={`absolute top-0 h-full w-1/2 rounded-br-full rounded-tr-full bg-zinc-700 ${
                  locale === 'en' ? 'end-0 start-auto' : 'end-auto start-0'
                }`}
              />
            </div>
            <h5 className='text-center font-semibold'>
              {total / numberOfInstallments} {locale === 'en' ? 'SAR' : 'ر.س'}
            </h5>
            <h6 className='text-center text-sm text-zinc-500'>
              {locale === 'en' ? 'In 1 month' : 'بعد شهر'}
            </h6>
          </div>
          <div className=''>
            <div className='relative mx-auto h-[50px] w-[50px] rounded-full border border-zinc-700 p-0.5'>
              <div
                className={`absolute top-0 h-1/2 w-1/2 rounded-tr-full bg-zinc-700 ${
                  locale === 'en' ? 'end-0 start-auto' : 'end-auto start-0'
                }`}
              />
              <div
                className={`absolute bottom-0 h-1/2 w-full rounded-bl-full rounded-br-full bg-zinc-700 ${
                  locale === 'en' ? 'end-0 start-auto' : 'end-auto start-0'
                }`}
              />
            </div>
            <h5 className='text-center font-semibold'>
              {total / numberOfInstallments} {locale === 'en' ? 'SAR' : 'ر.س'}
            </h5>
            <h6 className='text-center text-sm text-zinc-500'>
              {locale === 'en' ? 'In 2 months' : 'بعد شهرين'}
            </h6>
          </div>
          <div className=''>
            <div className='mx-auto h-[50px] w-[50px] rounded-full border border-zinc-700 bg-white p-0.5'>
              <div className='h-full w-full rounded-full border border-zinc-700 bg-zinc-700 p-0.5'></div>
            </div>
            <h5 className='text-center font-semibold'>
              {total / numberOfInstallments} {locale === 'en' ? 'SAR' : 'ر.س'}
            </h5>
            <h6 className='text-center text-sm text-zinc-500'>
              {locale === 'en' ? 'In 3 months' : 'بعد 3 أشهر'}
            </h6>
          </div>
        </div>

        <div className=' border-t px-8 pb-1'>
          <h2 className='py-2 text-2xl font-semibold'>
            {locale === 'en' ? 'How it works:' : 'طريقة الإستخدام :'}
          </h2>
          <ol>
            <li className='mb-2'>
              {' '}
              <span className='h-2 w-2 rounded-md bg-gray-200 p-1'>1</span>
              <span className='mx-2'>
                {locale === 'en'
                  ? 'Choose tabby at checkout'
                  : 'إختر تابى قبل اتمام عملية الشراء'}
              </span>
            </li>
            <li className='mb-2'>
              {' '}
              <span className='h-2 w-2 rounded-md bg-gray-200 p-1'>2</span>
              <span className='mx-2'>
                {locale === 'en'
                  ? 'Enter your information and add your debit or credit card.'
                  : 'أدخل بياناتك و بيانات البطاقة البنكية أو البطاقة الائتمانية.'}
              </span>
            </li>
            <li className='mb-2'>
              {' '}
              <span className='h-2 w-2 rounded-md bg-gray-200 p-1'>3</span>
              <span className='mx-2'>
                {locale === 'en'
                  ? `Split your purchase in ${numberOfInstallments} monthly payments.`
                  : `قسّم مشترياتك على ${numberOfInstallments} دفعات شهرية.`}
              </span>
            </li>
            <li className='mb-2'>
              {' '}
              <span className='h-2 w-2 rounded-md bg-gray-200 p-1'>4</span>
              <span className='mx-2'>
                {locale === 'en'
                  ? 'We’ll send reminders when your next payment is due.'
                  : 'سنقوم بإرسال رسالة على هاتفك لتذكيرك بموعد الدفعة القادمة.'}
              </span>
            </li>
          </ol>
        </div>

        <div className='border-t'>
          <h5 className='pt-3 text-center text-zinc-500'>
            {locale === 'en'
              ? 'Supported payment methods'
              : 'طرق الدفع المدعومة'}
          </h5>
          <div className='flex items-center justify-center'>
            <Image
              loading='lazy'
              src='/svgs/master-card.svg'
              className='h-10 w-10 object-contain'
              height={50}
              width={50}
              alt='Master Card'
            />
            <Image
              loading='lazy'
              src='/svgs/visa.svg'
              className='mx-1 h-10 w-10 object-contain'
              height={50}
              width={50}
              alt='Visa'
            />
            <Image
              loading='lazy'
              src='/svgs/mada.svg'
              className='h-10 w-10 object-contain'
              height={50}
              width={50}
              alt='Mada'
            />
            <Image
              loading='lazy'
              src='/svgs/american-express.svg'
              className='mx-1 h-10 w-10 object-contain'
              height={50}
              width={50}
              alt='American Express'
            />
            <Image
              loading='lazy'
              src='/svgs/apple-pay.svg'
              className='h-10 w-10 object-contain'
              height={50}
              width={50}
              alt='Apple Pay'
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TabbyModal;
