const EyeIcon = (props) => (
  <svg
    width={17}
    height={14}
    viewBox='0 0 17 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.35085 3.28939C3.13623 4.22806 2.06892 5.51953 1.31855 6.90724L0.76879 6.60997L1.31883 6.90672C1.28796 6.96394 1.28793 7.03494 1.31874 7.09219C2.0691 8.48019 3.13635 9.77175 4.35088 10.7104C5.56891 11.6518 6.8941 12.2083 8.17045 12.2083C9.44679 12.2083 10.772 11.6518 11.99 10.7105C13.2047 9.77186 14.272 8.48039 15.0223 7.09268C15.0529 7.0356 15.0529 6.96492 15.0222 6.90787C14.2719 5.51981 13.2046 4.2282 11.99 3.28947C10.772 2.34807 9.44679 1.79163 8.17045 1.79163C6.8941 1.79163 5.5689 2.34808 4.35085 3.28939ZM3.5865 2.30032C4.94699 1.24893 6.53429 0.541626 8.17045 0.541626C9.8066 0.541626 11.3939 1.24893 12.7544 2.30044C14.1184 3.3546 15.2956 4.78502 16.1219 6.31358L16.1222 6.31403C16.3529 6.74175 16.3529 7.259 16.1222 7.68672L16.1219 7.68724C15.2956 9.21536 14.1183 10.6456 12.7544 11.6996C11.3939 12.751 9.8066 13.4583 8.17045 13.4583C6.53429 13.4583 4.94698 12.751 3.58647 11.6995C2.22254 10.6453 1.04527 9.2149 0.21897 7.68634L0.218728 7.68589C-0.0120369 7.25817 -0.0120369 6.74092 0.218728 6.3132L0.219005 6.31268C1.0453 4.78455 2.22258 3.35436 3.5865 2.30032Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.5173 5.65305C8.77346 4.9092 7.56743 4.9092 6.82358 5.65305C6.07973 6.3969 6.07973 7.60292 6.82358 8.34677C7.56743 9.09062 8.77346 9.09062 9.5173 8.34677C10.2612 7.60292 10.2612 6.3969 9.5173 5.65305ZM10.3591 4.81124C9.15035 3.60247 7.19054 3.60247 5.98177 4.81124C4.773 6.02001 4.773 7.97982 5.98177 9.18858C7.19054 10.3974 9.15035 10.3974 10.3591 9.18858C11.5679 7.97982 11.5679 6.02001 10.3591 4.81124Z'
      fill='currentColor'
    />
  </svg>
);
export default EyeIcon;
