import LocaleSwitcher from '@/components/global/buttons/LocaleSwitcher';
import ArrowIcon from '@/components/icons/ArrowIcon';
import EmailIcon from '@/components/icons/EmailIcon';
import FaceBookIcon from '@/components/icons/FaceBook';
import TwitterIcon from '@/components/icons/TwitterIcon';
import Link from 'next/link';

const Header = ({ t }) => {
  return (
    <header className='hidden bg-primary py-4 text-white sm:block'>
      <div className='container mx-auto flex w-11/12 items-center justify-between'>
        <div className='flex items-center gap-4'>
          <Link
            href='https://ar-ar.facebook.com/Alomar.Furniture.KSA'
            target='_blank'
          >
            <FaceBookIcon />
          </Link>
          <Link href='https://twitter.com/alomar_furnitur' target='_blank'>
            <TwitterIcon />
          </Link>
          <Link href='mailto:order@alomar.com.sa'>
            <EmailIcon />
          </Link>
        </div>
        <div className='flex items-center gap-9 text-sm font-medium'>
          <Link href='/branches'>{t('Branches')}</Link>
          <Link href='/static/contact-us'>{t('Contact')}</Link>
          <Link href='/static/faq'>{t('Frequently asked questions')}</Link>
          <span className='flex items-center gap-2'>
            <LocaleSwitcher />
            <ArrowIcon />
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
