import FillHeart from '@/components/icons/FillHeart';
import HeartIcon from '@/components/icons/HeartIcon';
import { useWishlist } from '@/context/wishlist';
import { handleWishList } from '@/utils/wishlist';
import { useEffect, useState } from 'react';

const AddtoWishlist = ({ fromPromo, fromPDP, product }) => {
  const { wishlist, dispatch } = useWishlist();
  const [IsInWishlist, setIsInWishlist] = useState(false);

  useEffect(() => {
    product?.sku && setIsInWishlist(Object.prototype.hasOwnProperty.call(wishlist, product?.sku));
  }, [wishlist, product]);

  return (
    <div
      className={`flex items-center justify-center rounded-md border ${
        fromPDP
          ? 'h-full w-12 py-1.5'
          : !fromPromo
            ? `p-2 sm:py-1.5`
            : 'h-full w-12 max-sm:py-2'
      }  cursor-pointer`}
      onClick={() => handleWishList(wishlist, product, dispatch)}
    >
      {IsInWishlist ? (
        <FillHeart
          className={
            fromPDP
              ? 'size-[22px] text-primary'
              : !fromPromo
                ? 'size-3 sm:size-5'
                : 'size-5 text-primary'
          }
        />
      ) : (
        <HeartIcon
          className={
            fromPDP
              ? 'size-[22px] text-primary'
              : !fromPromo
                ? 'size-3 sm:size-5'
                : 'size-5 text-primary'
          }
        />
      )}
    </div>
  );
};

export default AddtoWishlist;
