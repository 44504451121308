import { checkoutReducers } from './reducers';
import store from 'store';
const { useReducer, createContext, useEffect } = require('react');
export const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
  console.log('store.get("checkout")', typeof store.get('checkout'));
  const [checkout, dispatch] = useReducer(
    checkoutReducers,
    store.get('checkout')
      ? store.get('checkout')
      : {
          cart: {},
          items: [],
          totals: [],
          shippingAddress: null,
          shippingMethods: [],
          pickupStoreId: null,
          activeShippingMethod: '',
          userAddresses: [],
          payments: null,
          regions: [],
        }
  );

  useEffect(() => {
    store.set('checkout', checkout);
  }, [checkout]);

  return (
    <CheckoutContext.Provider value={{ checkout, dispatch }}>
      {children}
    </CheckoutContext.Provider>
  );
};
