import * as React from 'react';
const CallIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={21}
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      d='M11.634 15.167c-.754 0-1.547-.18-2.367-.527-.8-.34-1.607-.806-2.393-1.373a21 21 0 0 1-2.247-1.913 20 20 0 0 1-1.913-2.24 12.4 12.4 0 0 1-1.36-2.374c-.347-.827-.52-1.626-.52-2.38q.001-.781.273-1.473A3.6 3.6 0 0 1 2 1.594c.514-.507 1.1-.76 1.727-.76.26 0 .527.06.753.166.26.12.48.3.64.54l1.547 2.18q.21.288.32.567.132.302.133.593-.002.381-.213.734c-.1.18-.253.373-.447.566l-.453.474q.01.028.02.046c.08.14.24.38.547.74.326.373.633.713.94 1.027.393.386.72.693 1.026.947.38.32.627.48.774.553L9.3 10l.487-.48a2.5 2.5 0 0 1 .6-.46c.367-.226.833-.267 1.3-.073.173.073.36.173.56.313l2.213 1.573q.372.252.533.634c.1.253.147.486.147.72 0 .32-.073.64-.213.94s-.313.56-.534.8c-.38.42-.793.72-1.273.913-.46.187-.96.287-1.486.287M3.726 1.834c-.367 0-.707.16-1.033.48-.307.286-.52.6-.654.94q-.209.518-.207 1.106c0 .62.147 1.293.44 1.987.3.706.72 1.44 1.254 2.173.533.734 1.14 1.447 1.806 2.12a19 19 0 0 0 2.127 1.813c.72.527 1.46.954 2.193 1.26 1.14.487 2.207.6 3.087.234.34-.14.64-.353.913-.66a2.2 2.2 0 0 0 .374-.56q.12-.252.12-.514a.8.8 0 0 0-.074-.333.5.5 0 0 0-.186-.2l-2.213-1.573a2 2 0 0 0-.367-.207c-.147-.06-.207-.12-.434.02a1.5 1.5 0 0 0-.386.3l-.507.5a.95.95 0 0 1-.966.2l-.18-.08a5.7 5.7 0 0 1-.947-.673c-.32-.274-.667-.594-1.087-1.007a23 23 0 0 1-.993-1.08q-.47-.55-.68-.94l-.08-.2a1.2 1.2 0 0 1-.053-.333c0-.24.086-.453.253-.62l.5-.52a2 2 0 0 0 .3-.373.43.43 0 0 0 .073-.227.6.6 0 0 0-.053-.213 2 2 0 0 0-.213-.354L4.307 2.114a.6.6 0 0 0-.247-.207.8.8 0 0 0-.333-.074M9.3 10.006l-.107.453.18-.467c-.033-.006-.06 0-.073.014M12.333 6.5a.504.504 0 0 1-.5-.5c0-.24-.24-.74-.64-1.167-.393-.42-.826-.667-1.193-.667a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5.647 0 1.327.347 1.92.98.553.594.913 1.32.913 1.854 0 .273-.226.5-.5.5m2.334 0a.504.504 0 0 1-.5-.5c0-2.3-1.867-4.166-4.167-4.166a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5A5.175 5.175 0 0 1 15.167 6c0 .274-.227.5-.5.5'
      fill='currentColor'
    />
  </svg>
);
export default CallIcon;
