import { WishlistProvider } from '@/context/wishlist/Provider';
import { CheckoutProvider } from '@/context/checkout/provider';
import { AppProvider } from '@/context/global/provider';
import { CartProvider } from '@/context/cart/provider';
import { appWithTranslation } from 'next-i18next';
import { SessionProvider } from 'next-auth/react';
import { Suspense, useEffect } from 'react';
import { Work_Sans, Cairo } from 'next/font/google';
import { useRouter } from 'next/router';

import Layout from '@/layout';
import dynamic from 'next/dynamic';
import NextNProgress from 'nextjs-progressbar';
import ErrorBoundary from '@/components/ErrorBoundary';
import SuspenseFallback from '@/components/SuspenseFallback';

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'react-datepicker/dist/react-datepicker.css';
import '@/styles/globals.css';

const workSansFont = Work_Sans({
  weight: ['200', '300', '400', '500', '600', '700', '800', '900'],
  style: ['normal'],
  display: 'swap',
  subsets: ['latin'],
  variable: '--font-work-sans',
});

const cairoFont = Cairo({
  weight: ['200', '300', '400', '500', '600', '700', '800', '900'],
  style: ['normal'],
  display: 'swap',
  subsets: ['arabic'],
  variable: '--font-cairo',
});

const Toaster = dynamic(
  () => import('react-hot-toast').then((c) => c.Toaster),
  { ssr: false }
);

const App = ({ Component, pageProps }) => {
  const { locale } = useRouter();
  useEffect(() => {
    window.onresize = () => {
      const windowSize = window.devicePixelRatio;
      if (windowSize < 1) {
        document.body.style.width = windowSize * 100 + '%';
        document.body.style.margin = 'auto';
      } else {
        document.body.style.width = '100%';
        document.body.style.margin = 'auto';
      }
    };
  });

  // Direction
  useEffect(() => {
    document.body.style.direction = locale === 'en' ? 'ltr' : 'rtl';
  }, [locale]);

  return (
    <SessionProvider session={pageProps?.session}>
      <main
        className={`${workSansFont.variable} ${cairoFont.variable} ${locale === 'en' ? 'font-sans' : 'font-cairo'} `}
      >
        <NextNProgress
          color='#fff'
          startPosition={0.3}
          stopDelayMs={200}
          height={4}
          showOnShallow={false}
        />
        <AppProvider>
          <WishlistProvider>
            <CartProvider>
              <CheckoutProvider>
                <Layout>
                  <Suspense
                    fallback={<SuspenseFallback className='h-[70vh]' />}
                  >
                    <ErrorBoundary>
                      <Component {...pageProps} />
                    </ErrorBoundary>
                  </Suspense>
                </Layout>
              </CheckoutProvider>
            </CartProvider>
          </WishlistProvider>
        </AppProvider>
        <Toaster position='bottom-center' reverseOrder={false} />
      </main>
    </SessionProvider>
  );
};

export default appWithTranslation(App);
