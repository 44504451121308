export const initialState = {
  isView: false,
  payload: null,
  cartModal: false,
  payload: undefined
};

export const globalModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'view':
      return {
        ...state,
        isView: !state.isView,
        payload: action?.payload
      };
    case "cartModal":
      return {
        ...state,
        cartModal: true,
      };
    case "closeCartModal":
      return {
        ...state,
        cartModal: false,
      };
    default:
      return { ...state };
  }
};
