import { changePasswordValidation } from '@/utils/valitation/auth';
import { Form, Formik } from 'formik';
import { useState } from 'react';

import FormField from '../FormField';
import EyeIcon from '@/components/icons/EyeIcon';
import Eyelock from '@/components/icons/Eyelock';

const ChangePassword = ({ t, setAuth }) => {
  const intialValues = {
    password: '',
    confirmPassword: '',
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleLogin = async () => {
    setAuth('Change Password Success');
  };
  return (
    <section className='mt-3 sm:mt-5'>
      <Formik
        onSubmit={handleLogin}
        initialValues={intialValues}
        validationSchema={changePasswordValidation(t)}
      >
        {({errors, touched}) => (
        <Form className='grid grid-cols-2 gap-3 mt-3'>
          <div className='col-span-full'>
            <FormField
              inputClassName='w-full rounded-md border p-1.5 max-sm:placeholder:text-sm max-sm:text-sm sm:p-2 outline-none'
              placeholder={t('Password')}
              name='password'
              type={showPassword ? 'text' : 'password'}
              icon={
                !showPassword ? (
                  <EyeIcon
                    onClick={() => setShowPassword(true)}
                    className='absolute -translate-y-1/2 cursor-pointer end-2 top-1/2 text-secondary'
                  />
                ) : (
                  <Eyelock
                    onClick={() => setShowPassword(false)}
                    className='absolute end-2 top-1/2 size-[18px] -translate-y-1/2 cursor-pointer text-secondary'
                  />
                )
              }
              errorClassName='text-red-600 text-sm mt-1 max-sm:text-xs'
              t={t}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className='col-span-full'>
            <FormField
              inputClassName='w-full rounded-md border p-1.5 max-sm:placeholder:text-sm max-sm:text-sm sm:p-2 outline-none'
              placeholder={t('Confirm Password')}
              name='confirmPassword'
              type={showConfirm ? 'text' : 'password'}
              icon={
                !showConfirm ? (
                  <EyeIcon
                    onClick={() => setShowConfirm(true)}
                    className='absolute -translate-y-1/2 cursor-pointer end-2 top-1/2 text-secondary'
                  />
                ) : (
                  <Eyelock
                    onClick={() => setShowConfirm(false)}
                    className='absolute end-2 top-1/2 size-[18px] -translate-y-1/2 cursor-pointer text-secondary'
                  />
                )
              }
              errorClassName='text-red-600 text-sm mt-1 max-sm:text-xs'
              t={t}
              errors={errors}
              touched={touched}
            />
          </div>
          <button className='w-full p-2 font-semibold text-white rounded-md col-span-full bg-primary max-sm:text-sm'>
            {t('Change Password')}
          </button>
        </Form>
        )}
      </Formik>
    </section>
  );
};

export default ChangePassword;
